import React, { useEffect } from "react";
import { Admin_Player_Details_GET } from "../../../api/ApiConst";
import { callGetApi } from "../../../api/ApiCaller";
import { useState } from "react";
import dateUtilities from "../../../utilities/DateUtilities";
import { Toaster } from "react-hot-toast";
import PlayerDocumentTab from "./PlayerDocumentTab";
import PlayerWithdrawalTransactionTab from "./PlayerWithdrawalTransactionTab";
import PlayerWalletTransactionTab from "./PlayerWalletTransactionTab";
import PlayerGameSession from "./PlayerGameSession";
import PlayerPokerGameSession from "./PlayerPokerGameSession"
import PlayerDepositTransactionTab from "./PlayerDepositTransactionTab";
import moduleUtilities from "../../../utilities/ModuleUtils";
import formatMoneyAmountUtils from "../../../utilities/FormatMoneyAmountUtils";

const allTabs = ["Wallet Transactions", "Withdrawal History", "Deposit History"];
// "Documents", "Ludo Session History", "Poker Session History"

const PlayerDetails = ({ rId, moduleList }) => {
    let playerID = rId;
    const [playerTabs, setPlayerTabs] = useState(allTabs);
    const [activeTab, setActiveTab] = useState(playerTabs[0]);
    const [record, setRecord] = useState({
        recordId: "",
        nickName: "",
        countryCode: "",
        mobileNumber: "",
        profileImageUrl: "",
        referralCode: "",
        walletBalance: 0,
        createdAtTimeStamp: "",
        kycVerificationStatus: "",
    });

    useEffect(() => {
        getPlayerDetails();
    }, []);

    useEffect(() => {
        if (moduleList !== null && moduleList !== undefined && moduleList.length > 0) {
            let tabs = playerTabs;
            if (moduleUtilities.checkModuleIsEnable(moduleList, "LudoModule")) {
                if (!playerTabs.includes("Ludo Session History")) {
                    tabs.push("Ludo Session History");
                }
            }
            if (moduleUtilities.checkModuleIsEnable(moduleList, "PokerModule")) {
                if (!playerTabs.includes("Poker Session History")) {
                    tabs.push("Poker Session History");
                }
            }
            if (moduleUtilities.checkModuleIsEnable(moduleList, "KycModule")) {
                if (!playerTabs.includes("Documents")) {
                    tabs.splice(0, 0, "Documents");
                }
            }
            setPlayerTabs(tabs);
            setActiveTab(tabs[0]);
        }
    }, [moduleList]);
    const getPlayerDetails = () => {
        let url = Admin_Player_Details_GET.replaceAll("{playerID}", playerID);
        callGetApi(
            url,
            {},
            (response) => {
                setRecord({
                    recordId: response.responsePacket.recordId,
                    nickName: response.responsePacket.nickName,
                    countryCode: response.responsePacket.countryCode,
                    mobileNumber: response.responsePacket.mobileNumber,
                    profileImageUrl: response.responsePacket.profileImageUrl,
                    referralCode: response.responsePacket.referralCode,
                    walletBalance: response.responsePacket.walletBalance,
                    kycVerificationStatus: response.responsePacket.kycVerificationStatus,
                    createdAtTimeStamp: response.responsePacket.createdAtTimeStamp,
                });
            },
            (error) => {
            }
        );
    };
    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">Player Details</h4>
                                <div className="page-title-right"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div id="datatable_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="card p-3">
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <div className="form-group mb-3 text-center">
                                                        <img className="playerDetailProfile" src={record?.profileImageUrl ? record?.profileImageUrl : "/assets/images/user.png"} alt="Player" />
                                                        <h5 className="mt-3">
                                                            <b>{record?.nickName}</b>
                                                        </h5>
                                                    </div>
                                                </div>
                                                <div className="form-group mb-2">
                                                    <div className="input-group">
                                                        <div className="input-group-addon">
                                                            <i className="bx bx-phone text-primary"></i>
                                                        </div>
                                                        <div className="form-control">
                                                            {record?.countryCode}
                                                            <span className="ms-1">{record?.mobileNumber}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group mb-2">
                                                    <div className="input-group">
                                                        <div className="input-group-addon">
                                                            <i className="bx bx-calendar text-primary"></i>
                                                        </div>
                                                        <div className="form-control">
                                                            <span>{record?.createdAtTimeStamp && dateUtilities.formattedDate(record?.createdAtTimeStamp, "dd-MM-yyyy")}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group mb-2">
                                                    <div className="d-flex justify-content-between form-control">
                                                        <div className="text-grey">KYC Verification Status:</div>
                                                        <span className="text-primary ms-2 fw-bold">{record?.kycVerificationStatus}</span>
                                                    </div>
                                                </div>
                                                <div className="form-group mb-2">
                                                    <div className="d-flex justify-content-between form-control">
                                                        <div className="text-grey">Wallet Balance:</div>
                                                        <span className="text-primary ms-2 fw-bold">{formatMoneyAmountUtils?.formatAmount(record?.walletBalance)}</span>
                                                    </div>
                                                </div>
                                                <div className="form-group mb-2">
                                                    <div className="d-flex justify-content-between form-control">
                                                        <div className="text-grey">Referral Code:</div>
                                                        <span className="text-primary ms-2 fw-bold">{record?.referralCode}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" col-lg-9">
                                        <div className="card p-3">
                                            <nav>
                                                <div className="nav nav-tabs nav-tabs-custom mb-3" id="nav-tab" role="tablist">
                                                    {playerTabs?.map((tab, i) => (
                                                        <a
                                                            key={i}
                                                            onClick={() => setActiveTab(tab)}
                                                            className={`nav-link ${tab === activeTab ? "active" : ""}`}
                                                            data-bs-toggle="tab"
                                                            data-bs-target="#nav-001"
                                                            type="button"
                                                            role="tab"
                                                            aria-selected={tab === activeTab ? "true" : "false"}
                                                        >
                                                            {tab}
                                                        </a>
                                                    ))}
                                                </div>
                                            </nav>
                                            <div className="tab-content border bg-light" id="nav-tabContent">
                                                <div className="tab-pane fade active show" id="nav-001" role="tabpanel" aria-labelledby="nav-001-tab">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="card mb-0">
                                                                <div className="card-body">
                                                                    <div className="table-rep-plugin custom-table">
                                                                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                                            {activeTab === "Documents" && <PlayerDocumentTab refreshPlayerDetails={getPlayerDetails} playerID={playerID} playerDetails={record} />}
                                                                            {activeTab === "Withdrawal History" && <PlayerWithdrawalTransactionTab playerID={playerID} />}
                                                                            {activeTab === "Deposit History" && <PlayerDepositTransactionTab playerID={playerID} />}
                                                                            {activeTab === "Wallet Transactions" && <PlayerWalletTransactionTab playerID={playerID} />}
                                                                            {activeTab === "Ludo Session History" && <PlayerGameSession playerID={playerID} />}
                                                                            {activeTab === "Poker Session History" && <PlayerPokerGameSession playerID={playerID} />}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster />
        </>
    );
};

export default PlayerDetails;
