import React from 'react'
import VoucherDetails from '../../Common/VoucherDetails'

const AgentVoucherMaster = () => {
    return (
        <React.Fragment>
           <VoucherDetails voucher={"AGENT_VOUCHER"}/>
        </React.Fragment>
    )
}

export default AgentVoucherMaster