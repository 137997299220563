import React, { useState, useEffect, useMemo } from "react";
import {
    addGameTemplete,
    updateTempleteDetails,
    getTempleteDetails,
    deleteTemplete,
    activateTemplete,
    deactivateTemplete,
    startGame,
    getSnakeList,
    getLadderList,
    getBoardList,
    publishTemplete,
    unPublishTemplete,
} from "../../../Services/index.js";
import { toast, ToastContainer } from "react-toastify";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import ConfirmationDialoge from "../../Common/ConfirmationDialoge.jsx";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import Multiselect from "multiselect-react-dropdown";
import PageHeading from "../../Common/PageHeading.jsx";
import PaginationDataTable from "../../Common/PaginationDataTable.jsx";
import { All_Snake_Ladder_Template_List_POST } from "../../../api/ApiConst.js";

const playerCountOptions = [
    {
        value: 2,
        label: 2,
    },
    {
        value: 4,
        label: 4,
    },
    // {
    //     value: 3,
    //     label: 3
    // },
];

const tokenCountOptions = [
    {
        value: 1,
        label: 1,
    },
    // {
    //     value: 2,
    //     label: 2,
    // },
    // {
    //     value: 4,
    //     label: 4,
    // },
];

const limitTypeOptions = [
    {
        value: "NoLimit",
        label: "No Limit",
    },
    {
        value: "TimeLimit",
        label: "Time Limit",
    },
    {
        value: "TurnLimit",
        label: "Turn Limit",
    },
    // {
    //     value: "ScoreLimit",
    //     label: "ScoreLimit",
    // },
];

const SnakeLadderGameTemplate = () => {
    const [show, setShow] = useState({
        addTemplete: false,
        sendClaim: false,
    });
    const [winnerOptions, setWinnerOptions] = useState([]);
    const [winnerCount, setWinnerCount] = useState({
        value: 1,
        label: 1,
    });
    const [id, setId] = useState(null);
    const handleTempleteModal = () => setShow({ ...show, addTemplete: false });
    const [boardImage, setBoardImage] = useState(null);
    const [assetsImages, setAssetsImages] = useState([]);
    const [ladderImages, setLadderImages] = useState([]);

    const [selectedSnake, setSelectedSnake] = useState(null);
    const [selectedLadder, setSelectedLadder] = useState(null);
    const [limitType, setLimitType] = useState(limitTypeOptions[0]);
    const [totalPlayer, setTotalPlayer] = useState({
        value: 4,
        label: 4,
    });
    const [totalTokenCount, setTotalTokenCount] = useState(tokenCountOptions[0]);
    const [edit, setEdit] = useState(false);
    const [assetId, setAssetId] = useState([]);
    const [ladderId, setLadderId] = useState([]);
    const [boardId, setBoardId] = useState([]);
    const [snakeOptions, setSnakeOptions] = useState(null);
    const [ladderOptions, setLadderOptions] = useState(null);
    const tempRow = 0;
    const [winnerInput, setWinnerInput] = useState([tempRow]);
    const [winnersPercentage, setWinnersPercentage] = useState([]);
    const [totalPercentage, setTotalPercentage] = useState([]);
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, isAuth: true, title: "", subTitle: "", severity: "" });
    const [templete, setTemplete] = useState({
        betAmount: 0,
        gameStartWaitingTime: 10,
        gameTitle: "",
        graceTime: 10,
        playerCount: 0,
        resultWaitingTime: 10,
        totalNumberOfWinners: 0,
        turnTime: 10,
        limitType: "TimeLimit",
        limitValue: 120,
        tokenCount: 1,
        fee: 0,
    });
    const [snakeKeyValueList, setSnakeKeyValueList] = useState(null);
    const [ladderKeyValueList, setLadderKeyValueList] = useState(null);
    const [board, setBoard] = useState(null);
    const [boardKeyValueList, setBoardKeyValueList] = useState(null);
    const [drawCount, setDrawCount] = useState(0);
    const handleRefresh = () => setDrawCount((prev) => prev + 1);

    useEffect(() => {
        let temp = [];
        for (let i = 1; i < totalPlayer.value; i++) {
            let dict = {
                value: i,
                label: i,
            };
            temp.push(dict);
        }
        setWinnerOptions(temp);
    }, []);

    const getSnakeDataList = () => {
        let typeGame = "Snake";
        getSnakeList(typeGame).then((res) => {
            if (res.errorCode === 0) {
                var temp = [];
                let data = res.responsePacket;
                data &&
                    data.map((option) => {
                        let dict = {
                            id: option.recordId,
                            name: (
                                <p>
                                    {option.assetsType + " " + option.fromNumber + "-" + option.toNumber}&nbsp;&nbsp;&nbsp;
                                    <img style={{ height: "30px" }} />
                                </p>
                            ),
                            imageURL: option.imageUrl,
                        };
                        temp.push(dict);
                    });
                setSnakeKeyValueList(temp);
                setSnakeOptions(temp);
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            }
        });
    };

    const getLadderDataList = () => {
        let typeGame = "Ladder";
        getLadderList(typeGame).then((res) => {
            if (res.errorCode === 0) {
                var temp = [];
                let data = res.responsePacket;
                data &&
                    data.map((option) => {
                        let dict = {
                            id: option.recordId,
                            name: (
                                <p>
                                    {option.assetsType + " " + option.fromNumber + "-" + option.toNumber}&nbsp;&nbsp;&nbsp;
                                    <img style={{ height: "30px" }} />
                                </p>
                            ),
                            imageURL: option.imageUrl,
                        };
                        temp.push(dict);
                    });
                setLadderKeyValueList(temp);
                setLadderOptions(temp);
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            }
        });
    };

    const getBoardDataList = () => {
        let typeGame = "Board";
        getBoardList(typeGame).then((res) => {
            if (res.errorCode === 0) {
                const options =
                    res.responsePacket &&
                    res.responsePacket.map((d) => ({
                        value: d.recordId,
                        label: d.assetsType,
                        boardImage: d.imageUrl,
                    }));
                setBoardKeyValueList(options);
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            }
        });
    };

    const handleBoard = (e) => {
        setBoard(e);
        // $('#boardImg').attr('src', e.boardImage)
        setBoardImage(e.boardImage);
    };

    const columns = useMemo(
        () => [
            {
                id: "status",
                name: "",
                selector: (row) => {
                    if (row.active) {
                        return <div className="led-green" title="Active"></div>;
                    } else {
                        return <div className="led-red" title="Not Active"></div>;
                    }
                },
                width: "60px",
                sortable: false,
            },
            {
                name: "Title",
                selector: (row) => row.gameTitle,
                sortable: true,
                grow: 2,
            },
            {
                name: "Bet Amount",
                selector: (row) => row.betAmount?.toFixed(2),
                sortable: true,
                right: true,
            },
            {
                name: "Fee",
                selector: (row) => row.fee?.toFixed(2),
                sortable: true,
                right: true,
            },
            {
                name: "Limit Type",
                selector: (row) => row.limitType,
                sortable: true,
            },
            {
                name: "Limit Value",
                selector: (row) => (
                    <>
                        {row.limitType === "TimeLimit" ? (
                            <>{(row.limitValue / 60).toFixed(2)} Min</>
                        ) : row.limitType === "ScoreLimit" ? (
                            <>{row.limitValue} Scores</>
                        ) : row.limitType === "TurnLimit" ? (
                            <>{row.limitValue} Turns</>
                        ) : (
                            ""
                        )}
                    </>
                ),
                sortable: true,
            },
            {
                name: "Player",
                selector: (row) => row.playerCount,
                sortable: true,
                right: true,
            },
            {
                name: "Winner",
                selector: (row) => row.totalNumberOfWinners,
                sortable: true,
                right: true,
            },
            {
                name: "Actions",
                cell: (row) => (
                    <div className="d-flex align-items-center">
                        {row.publish ? (
                            <button
                                className="btn btn-warning font-size-12 me-2"
                                onClick={() =>
                                    setConfirmDialog({
                                        isOpen: true,
                                        title: "Are Your Sure? You want Unpublish Template",
                                        onConfirm: () => handleUnPublishTemplate(row.recordId),
                                    })
                                }
                            >
                                Un-Publish
                            </button>
                        ) : (
                            <button
                                className="btn btn-success font-size-12 me-2"
                                onClick={() =>
                                    setConfirmDialog({
                                        isOpen: true,
                                        title: "Are Your Sure? You want Publish Template",
                                        onConfirm: () => handlePublishTemplate(row.recordId),
                                    })
                                }
                            >
                                Publish
                            </button>
                        )}

                        <div className="dropdown">
                            <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                <KeyboardArrowDownOutlinedIcon />
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                <li onClick={() => handleEditTemplete(row.recordId)}>
                                    <button className="dropdown-item" type="button">
                                        Edit
                                    </button>
                                </li>
                                <li
                                    onClick={() =>
                                        setConfirmDialog({
                                            isOpen: true,
                                            title: "Are Your Sure? Your want to delete this record",
                                            onConfirm: () => handleDeleteTemplete(row.recordId),
                                        })
                                    }
                                >
                                    <button className="dropdown-item" type="button">
                                        Delete
                                    </button>
                                </li>
                                {row.active ? (
                                    <li
                                        onClick={() =>
                                            setConfirmDialog({
                                                isOpen: true,
                                                title: "Are Your Sure? Your want to Deactivate this player",
                                                onConfirm: () => handleTempleteDeactivate(row.recordId),
                                            })
                                        }
                                    >
                                        <button className="dropdown-item" type="button">
                                            Deactivate
                                        </button>
                                    </li>
                                ) : (
                                    <li
                                        onClick={() =>
                                            setConfirmDialog({
                                                isOpen: true,
                                                title: "Are Your Sure? Your want to Activate this player",
                                                onConfirm: () => handleTempleteActivate(row.recordId),
                                            })
                                        }
                                    >
                                        <button className="dropdown-item" type="button">
                                            Activate
                                        </button>
                                    </li>
                                )}
                                <li
                                    onClick={() =>
                                        setConfirmDialog({
                                            isOpen: true,
                                            title: "Are Your Sure? You want create this table",
                                            onConfirm: () => handleCreateTable(row.recordId),
                                        })
                                    }
                                >
                                    <button className="dropdown-item" type="button">
                                        Start New Table
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                ),
                sortable: true,
                right: true,
                width: "280px",
            },
        ],
        [drawCount]
    );

    const handleForceLogout = (data) => {
        toast(data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setConfirmDialog({
            isOpen: true,
            isAuth: true,
            title: "You Are LoggedIn on Another Screen! to Countinue Logout and Login Again!!",
            onConfirm: () => logout(),
        });
    };

    const handleCreateTable = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });

        startGame(id).then((res) => {
            if (res.errorCode === 0) {
                handleSuccessToast(res?.message);
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
                return;
            } else {
                handleFailerToast(res?.message);
            }
        });
    };

    const logout = () => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        const clearCookies = document.cookie.split(";").forEach((cookie) => (document.cookie = cookie.replace(/^ +/, "").replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`)));
        window.location.reload(1);
    };

    const handleCancel = () => {
        setShow({ ...show, addTemplete: false });
        handleClearForm();
    };

    const handleTotalPlayer = (e) => {
        setWinnerCount(null);
        setTotalPlayer(e);
        // let total = Number(e.label);
        let total = 2;
        let temp = [];
        for (let i = 1; i < total; i++) {
            let dict = {
                value: i,
                label: i,
            };
            temp.push(dict);
        }
        setWinnerOptions(temp);
    };

    const handleWinnersCount = (e) => {
        setWinnerCount(e);
        winnerInput.length = 0;
        for (let i = 0; i < e.label; i++) {
            winnerInput.push(0);
        }
    };

    const handleWinnersPercentage = (e, index) => {
        let temp = winnerInput.map((i) => i);
        temp[index] = Number(e.target.value);
        setWinnerInput(temp);
    };

    const handleClearForm = () => {
        setTemplete({
            betAmount: 0,
            gameStartWaitingTime: 10,
            gameTitle: "",
            graceTime: 10,
            playerCount: 0,
            resultWaitingTime: 10,
            turnTime: 10,
            limitType: "TimeLimit",
            limitValue: 120,
            tokenCount: 1,
            fee: 0,
        });
        setWinnerInput([tempRow]);
        setSelectedSnake(null);
        setSelectedLadder(null);
        setBoard(null);
        setBoardImage(null);
        setAssetId([]);
        setAssetsImages([]);
        setLadderId([]);
        setLadderImages([]);

        //setSelectedAssets([])
    };

    const handleDeleteTemplete = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        deleteTemplete(id).then((res) => {
            if (res.errorCode === 0) {
                handleSuccessToast(res?.message);
                handleRefresh();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
                return;
            } else {
                handleFailerToast(res?.message);
            }
        });
    };

    const handleTempleteDeactivate = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        deactivateTemplete(id).then((res) => {
            if (res.errorCode === 0) {
                handleSuccessToast(res?.message);
                handleRefresh();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
                return;
            } else {
                handleFailerToast(res?.message);
            }
        });
    };

    const handleTempleteActivate = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        activateTemplete(id).then((res) => {
            if (res.errorCode === 0) {
                handleSuccessToast(res?.message);
                handleRefresh();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
                return;
            } else {
                handleFailerToast(res?.message);
            }
        });
    };

    const handlePublishTemplate = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        publishTemplete(id).then((res) => {
            if (res.errorCode === 0) {
                handleSuccessToast(res?.message);
                handleRefresh();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
                return;
            } else {
                handleFailerToast(res?.message);
            }
        });
    };

    const handleUnPublishTemplate = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        unPublishTemplete(id).then((res) => {
            if (res.errorCode === 0) {
                handleSuccessToast(res?.message);
                handleRefresh();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
                return;
            } else {
                handleFailerToast(res?.message);
            }
        });
    };

    const handleEditTemplete = (id) => {
        setId(id);
        setShow({
            ...show,
            addTemplete: true,
        });
        setEdit(true);
        getTempleteDetails(id).then((res) => {
            if (res.errorCode === 0) {
                let d = res.responsePacket;
                setTemplete(d);
                let dict = {
                    value: d.playerCount,
                    label: d.playerCount,
                };
                setTotalPlayer(dict);
                let tokenCountTemp = {
                    value: d.tokenCount,
                    label: d.tokenCount,
                };
                setTotalTokenCount(tokenCountTemp);
                let dist = {
                    value: d.totalNumberOfWinners,
                    label: d.totalNumberOfWinners,
                };
                handleWinnersCount(dist);
                let temp = d.everyWinnerWinningPercentage.slice();
                setWinnerInput(temp);
                let B = {
                    value: d.board.uuid,
                    label: d.board.assetsType,
                    boardImage: d.board.imageUrl,
                };
                setBoardImage(res.responsePacket.board.imageUrl);
                setBoard(B);

                let list = [];
                let snackId = [];
                let snackImgUrl = [];
                d.snakeList?.map((sAsssts) => {
                    snackId.push(sAsssts.uuid);
                    setAssetId(snackId);
                    snackImgUrl.push(sAsssts.imageUrl);
                    snakeOptions?.map((assets) => {
                        if (sAsssts.uuid === assets.id) {
                            list.push(assets);
                        }
                    });
                });

                setAssetsImages(snackImgUrl);
                setSelectedSnake(list);

                let ladder_list = [];
                let ladder_Id = [];
                let ladderImgUrl = [];
                d.ladderList?.map((sAsssts) => {
                    ladder_Id.push(sAsssts.uuid);
                    ladderImgUrl.push(sAsssts.imageUrl);
                    //console.log(snackId);
                    setLadderId(ladderId);

                    ladderOptions?.map((assets) => {
                        if (sAsssts.uuid === assets.id) {
                            ladder_list.push(assets);
                        }
                    });
                });
                setLadderImages(ladderImgUrl);
                setSelectedLadder(ladder_list);
                setLimitType({
                    label: d.limitType,
                    value: d.limitType,
                });
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
                return;
            } else {
                handleFailerToast(res?.message);
            }
        });
    };

    const handleAddTemplete = () => {
        if (!limitType) {
            toast("Please Select Limit Type");
            return;
        } else if (limitType.value === "TimeLimit" && templete.limitValue < 120) {
            toast("Limit Value cannot be less than 120 Seconds");
            return;
        } else if (limitType.value === "TurnLimit" && templete.limitValue < 20) {
            toast("Limit Value cannot be less than 20 turns");
            return;
        }

        let d = 0;
        winnerInput &&
            winnerInput.map((data) => {
                d += data;
            });

        let request = {
            boardUuid: board?.value,
            snakeUuidList: assetId,
            ladderUuidList: ladderId,
            everyWinnerWinningPercentage: winnerInput,
            betAmount: Number(templete?.betAmount),
            gameStartWaitingTime: templete?.gameStartWaitingTime,
            gameTitle: templete?.gameTitle,
            graceTime: templete?.graceTime,
            playerCount: totalPlayer?.label,
            resultWaitingTime: templete?.resultWaitingTime,
            totalNumberOfWinners: winnerCount?.label,
            turnTime: templete?.turnTime,
            limitType: limitType?.label,
            limitValue: templete?.limitValue,
            tokenCount: totalTokenCount.value,
            fee: templete.fee,
        };
        if (request.boardUuid === undefined || request.boardUuid === null || request.boardUuid === 0) {
            handleWarningToast("Please Add Board!!");
            return;
        } else if (request.gameTitle === undefined || request.gameTitle === null || request.gameTitle === "") {
            handleWarningToast("Please Add Game Title!!");
            return;
        } else if (request.betAmount === undefined || request.betAmount === null || request.betAmount === 0) {
            handleWarningToast("Please Add Bat Amount!!");
            return;
        } else if (d === 0) {
            handleWarningToast("Please Fill Winner Percentage!");
            return;
        } else if (d > 100) {
            handleWarningToast("Total percentage must not be greater then 100!");
            return;
        } else if (request.snakeUuidList.length === 0 || request.snakeUuidList === null || request.snakeUuidList === 0) {
            handleWarningToast("Please Add Snake!!");
            return;
        } else if (request.ladderUuidList.length === 0 || request.ladderUuidList === null || request.ladderUuidList === 0) {
            handleWarningToast("Please Add Ladder!!");
            return;
        }
        if (edit) {
            updateTempleteDetails(id, request).then((res) => {
                if (res.errorCode === 0) {
                    handleSuccessToast(res?.message);
                    handleTempleteModal();
                    handleClearForm();
                    handleRefresh();
                } else {
                    handleFailerToast(res?.message);
                }
            });
        } else {
            addGameTemplete(request).then((res) => {
                if (res.errorCode === 0) {
                    handleSuccessToast(res?.message);
                    handleRefresh();
                    handleTempleteModal();
                    handleClearForm();
                } else if (res.message === "Unauthorised Access") {
                    handleForceLogout(res.message);
                    return;
                } else {
                    handleFailerToast(res?.message);
                }
            });
        }
    };

    const handleWarningToast = (data) => {
        toast.warn(data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const handleSuccessToast = (data) => {
        toast("👏" + data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const handleFailerToast = (data) => {
        toast("🙁" + data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const handleNewTemplete = () => {
        setEdit(false);
        setShow({ ...show, addTemplete: true });
    };

    const handleAddSelectedSnakeValues = (e) => {
        setAssetId([]);
        setAssetsImages([]);
        var assetIds = [];
        var images = [];
        e.map((data, i) => {
            assetIds.push(data.id);
            images.push(data.imageURL);
        });
        setAssetId(assetIds);
        setAssetsImages(images);
    };
    const handleRemoveSelectedSnakeValues = (e) => {
        setAssetId([]);
        setAssetsImages([]);
        var assetIds = [];
        var images = [];
        e.map((data, i) => {
            assetIds.push(data.id);
            images.push(data.imageURL);
        });
        setAssetId(assetIds);
        setAssetsImages(images);
    };

    const handleAddSelectedLadderValues = (e) => {
        setLadderImages([]);
        setLadderId([]);
        var ladderIds = [];
        var ladder_images = [];
        e.map((data, i) => {
            ladderIds.push(data.id);
            ladder_images.push(data.imageURL);
        });
        setLadderId(ladderIds);
        setLadderImages(ladder_images);
    };

    const handleRemoveSelectedLadderValues = (e) => {
        setLadderImages([]);
        setLadderId([]);
        var ladderIds = [];
        var ladder_images = [];
        e.map((data, i) => {
            ladderIds.push(data.id);
            ladder_images.push(data.imageURL);
        });
        setLadderId(ladderIds);
        setLadderImages(ladder_images);
    };

    const handleRemoveSelectedValues = (e) => {
        assetId.length = 0;
        e &&
            e.map((data, i) => {
                assetId.push(data.id);
            });
    };

    useEffect(() => {
        //snakeLadderList()
        getSnakeDataList();
        getLadderDataList();
        getBoardDataList();
    }, []);

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <PageHeading heading={"Snake & Ladder Game Template"} />
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <a to="/addPlayer" className="btn btn-primary waves-effect waves-light btn-sm" data-toggle="modal" onClick={() => handleNewTemplete()}>
                                            Add Template
                                        </a>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <PaginationDataTable drawCount={drawCount} paginationUrl={All_Snake_Ladder_Template_List_POST} serverSidePagination={true} search={true} columns={columns} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="tablerowmodal" show={show.addTemplete} onHide={() => handleCancel()} size="xl" aria-labelledby="contained-modal-title-vcenter">
                <Modal.Body className="">
                    <div className="modal-header border-bottom-0">
                        <h4>Add / Update New Templete</h4>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleCancel()}></button>
                    </div>
                    <div className="card-body">
                        <form>
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="formrow-password-input" className="form-label">
                                                    Select Board
                                                </label>
                                                <Select options={boardKeyValueList} value={board} onChange={(e) => handleBoard(e)} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="formrow-email-input" className="form-label">
                                                    Game Title:
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="formrow-email-input"
                                                    placeholder="Enter Full Name"
                                                    value={templete.gameTitle}
                                                    onChange={(e) => setTemplete({ ...templete, gameTitle: e.target.value })}
                                                    name="gameTitle"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mb-3">
                                                <label htmlFor="formrow-email-input" className="form-label">
                                                    Bet Amount:
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="formrow-email-input"
                                                    placeholder="Enter Mobile Number"
                                                    value={templete.betAmount}
                                                    onChange={(e) => setTemplete({ ...templete, betAmount: e.target.value })}
                                                    name="betAmount"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mb-3">
                                                <label htmlFor="formrow-email-input" className="form-label">
                                                    Fee:
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="formrow-email-input"
                                                    placeholder="Enter Mobile Number"
                                                    value={templete.fee}
                                                    onChange={(e) => setTemplete({ ...templete, fee: e.target.value })}
                                                    name="fee"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mb-3">
                                                <label htmlFor="formrow-password-input" className="form-label">
                                                    Player Count:
                                                </label>
                                                <Select options={playerCountOptions} value={totalPlayer} onChange={(e) => handleTotalPlayer(e)} />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mb-3">
                                                <label htmlFor="formrow-password-input" className="form-label">
                                                    Total Winner:
                                                </label>
                                                <Select isDisabled={!totalPlayer} options={winnerOptions} value={winnerCount} onChange={(e) => handleWinnersCount(e)} />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <label htmlFor="formrow-email-input" className="form-label">
                                                    Winner Winning %:
                                                </label>
                                                <div className="row">
                                                    {winnerInput &&
                                                        winnerInput.map((row, index) => {
                                                            return (
                                                                <div className="col-md-4" key={index}>
                                                                    <li style={{ listStyle: "none" }}>
                                                                        <input
                                                                            key={index}
                                                                            type="number"
                                                                            className="form-control input-sm"
                                                                            name={"key" + index}
                                                                            onChange={(e) => handleWinnersPercentage(e, index)}
                                                                            value={row}
                                                                        />
                                                                    </li>
                                                                </div>
                                                            );
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 d-none">
                                            <div className="mb-3">
                                                <label htmlFor="formrow-password-input" className="form-label">
                                                    Token Count:
                                                </label>
                                                <Select options={tokenCountOptions} value={totalTokenCount} onChange={(e) => setTotalTokenCount(e)} />
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <label htmlFor="formrow-password-input" className="form-label">
                                                    Select Snake:
                                                </label>
                                                <Multiselect
                                                    className="snakMultiselect"
                                                    options={snakeKeyValueList}
                                                    selectedValues={selectedSnake}
                                                    onSelect={(e) => handleAddSelectedSnakeValues(e)}
                                                    onRemove={(e) => handleRemoveSelectedSnakeValues(e)}
                                                    displayValue="name"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <label htmlFor="formrow-password-input" className="form-label">
                                                    Select Ladder:
                                                </label>
                                                <Multiselect
                                                    className="snakMultiselect"
                                                    options={ladderKeyValueList}
                                                    selectedValues={selectedLadder}
                                                    onSelect={(e) => handleAddSelectedLadderValues(e)}
                                                    onRemove={(e) => handleRemoveSelectedLadderValues(e)}
                                                    displayValue="name"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mb-3">
                                                <label htmlFor="formrow-password-input" className="form-label">
                                                    Turn Time:
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="turnTime"
                                                    placeholder="Enter mobileNumber"
                                                    value={templete.turnTime}
                                                    onChange={(e) => setTemplete({ ...templete, turnTime: e.target.value })}
                                                    name="turnTime"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mb-3">
                                                <label htmlFor="formrow-password-input" className="form-label">
                                                    Grace Time:
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="mobileNumber"
                                                    placeholder="Enter mobileNumber"
                                                    value={templete.graceTime}
                                                    onChange={(e) => setTemplete({ ...templete, graceTime: e.target.value })}
                                                    name="graceTime"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mb-3">
                                                <label htmlFor="formrow-password-input" className="form-label">
                                                    Start Waiting Time:
                                                </label>
                                                <input
                                                    className="form-control ember-text-field zf-date-picker date-picker ember-view"
                                                    type="number"
                                                    name="gameStartWaitingTime"
                                                    value={templete.gameStartWaitingTime}
                                                    onChange={(e) => setTemplete({ ...templete, gameStartWaitingTime: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mb-3">
                                                <label htmlFor="formrow-password-input" className="form-label">
                                                    Result Wait Time:
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="mobileNumber"
                                                    placeholder="Enter mobileNumber"
                                                    value={templete.resultWaitingTime}
                                                    onChange={(e) => setTemplete({ ...templete, resultWaitingTime: e.target.value })}
                                                    name="resultWaitingTime"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="mb-3">
                                                        <label htmlFor="formrow-password-input" className="form-label">
                                                            Limit Type:
                                                        </label>
                                                        <Select
                                                            options={limitTypeOptions}
                                                            value={limitType}
                                                            onChange={(e) => {
                                                                if (e.value === "NoLimit") {
                                                                    setTemplete({ ...templete, limitValue: 0 });
                                                                } else if (e.value === "TimeLimit") {
                                                                    setTemplete({ ...templete, limitValue: 120 });
                                                                } else if (e.value === "TurnLimit") {
                                                                    setTemplete({ ...templete, limitValue: 20 });
                                                                } else if (e.value === "ScoreLimit") {
                                                                    setTemplete({ ...templete, limitValue: 20 });
                                                                }
                                                                setLimitType(e);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                {limitType.value !== "NoLimit" && (
                                                    <div className="col-md-3">
                                                        <div className="mb-3">
                                                            <label htmlFor="formrow-password-input" className="form-label">
                                                                Limit Value:
                                                            </label>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                id="limitValue"
                                                                placeholder="Limit Value"
                                                                minLength={120}
                                                                value={templete.limitValue}
                                                                onChange={(e) => setTemplete({ ...templete, limitValue: e.target.value })}
                                                                name="limitValue"
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4" style={{ position: "relative" }}>
                                    <div style={{ position: "relative" }}>
                                        <img id="boardImgBg" src="/assets/images/snake_ladder/bg.png" style={{ width: "100%", position: "absolute" }} />
                                        <img id="boardImg" src={boardImage} style={{ width: "100%", position: "absolute" }} />
                                        <img id="boardImgNumber" src="/assets/images/snake_ladder/number.png" style={{ width: "100%", position: "absolute" }} />
                                    </div>
                                    {/* Snake Div */}
                                    <div style={{ position: "relative" }}>
                                        {assetsImages.map((image, index) => {
                                            return <img key={index} src={image} style={{ width: "100%", position: "absolute" }} />;
                                        })}
                                    </div>
                                    {/* Snake Div */}
                                    <div style={{ position: "relative" }}>
                                        {ladderImages.map((image, index) => {
                                            return <img key={index} src={image} style={{ width: "100%", position: "absolute" }} />;
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <a className="btn btn-primary w-md" onClick={handleAddTemplete}>
                                    {edit ? "Update Templete" : "Add Templete"}
                                </a>
                                &nbsp; &nbsp;
                                <a className="btn btn-primary w-md" onClick={() => handleCancel()}>
                                    Cancel
                                </a>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
            <ToastContainer />
            <ConfirmationDialoge confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
        </>
    );
};

export default SnakeLadderGameTemplate;
