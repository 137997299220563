import React, { useState, useMemo } from "react";
import { stopTable } from "../../../Services/index.js";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import PageHeading from "../../Common/PageHeading.jsx";
import PaginationDataTable from "../../Common/PaginationDataTable.jsx";
import { All_Snake_Ladder_Running_Tables_List_POST, All_Snake_Ladder_Template_List_POST } from "../../../api/ApiConst.js";

const SnakeLadderRunningGame = () => {
    const [drawCount, setDrawCount] = useState(0);

    const handleRefresh = () => setDrawCount((prev) => prev + 1);

    const handleStopTableAlert = (tableId) => {
        Swal.fire({
            title: "Do you sure stop table ?",
            text: "",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, confirm !!",
            cancelButtonText: "No !!",
        }).then((result) => {
            if (result.value === true) {
                stopTable(tableId).then((res) => {
                    if (res.data.errorCode === 0) {
                        handleSuccessToast(res.data?.message);
                        handleRefresh();
                    } else {
                        handleFailerToast(res.data?.message);
                    }
                });
            }
        });
    };

    const handleSuccessToast = (data) => {
        toast("👏" + data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const handleFailerToast = (data) => {
        toast("🙁" + data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const columns = useMemo(
        () => [
            {
                name: "Table Name",
                selector: (row) => row.tableName,
                sortable: true,
                grow: 2,
                reorder: true,
            },
            {
                name: "Player Count",
                selector: (row) => row.playerCount,
                sortable: true,
                grow: 2,
                reorder: true,
            },
            {
                name: "Max Players",
                selector: (row) => row.maxPlayers,
                sortable: true,
                grow: 2,
                reorder: true,
            },
            {
                name: "Bet Amount",
                selector: (row) => row.betAmount,
                sortable: true,
                grow: 2,
                reorder: true,
            },
            {
                name: "Actions",
                cell: (row) =>
                    row.deleteTable != true ? (
                        <div>
                            <button type="button" className="btn btn-lg btn-primary" onClick={() => handleStopTableAlert(row.tableId)}>
                                <i className="fa fa-ban" aria-hidden="true"></i>
                            </button>
                        </div>
                    ) : (
                        ""
                    ),
                sortable: true,
                right: true,
                reorder: true,
            },
        ],
        [drawCount]
    );

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <PageHeading heading={"Running Game"} />
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <PaginationDataTable drawCount={drawCount} paginationUrl={All_Snake_Ladder_Running_Tables_List_POST} serverSidePagination={false} search={true} columns={columns} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SnakeLadderRunningGame;
