import React from 'react'
import VoucherDetails from '../../Common/VoucherDetails'

const AdminVoucherMaster = () => {
    return (
        <React.Fragment>
            <VoucherDetails voucher={"ADMIN_VOUCHER"} />
        </React.Fragment>
    )
}

export default AdminVoucherMaster