import { Add_New_Admin_Store_Product_POST, Admin_Store_Product_Activate_PUT, Admin_Store_Product_Deactivate_PUT, Admin_Store_Product_GET, Admin_Store_Product_Revive__PUT, Delete_Admin_Store_Product_DELETE, Update_Admin_Store_Product_PUT, } from "../../api/ApiConst.js";
import { SYSTEM_ERROR } from "../Config/CONSTANT.js";
import axios from "axios";
import Cookies from "universal-cookie";

const cook = new Cookies().get("loginData");

const headersData = {
    "content-type": "application/json",
    Accept: "application/json",
    Authorization: cook != undefined ? cook.accessToken : "",
};


export const addStore = (data) => {
    return new Promise((resolve, reject) => {
        console.log(data);
        try {
            axios
                .post(Add_New_Admin_Store_Product_POST, data, { headers: headersData })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in addStore axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const getStoreDetails = (id) => {
    return new Promise((resolve, reject) => {
        console.log(id);
        try {
            axios
                .get(Admin_Store_Product_GET.replaceAll("{recordId}", id), { headers: headersData })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getPlayerDetails axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const updateStoreDetails = (id, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(Update_Admin_Store_Product_PUT.replaceAll("{recordId}", id), data, { headers: headersData })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in updateStoreDetails axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const deleteStore = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .delete(Delete_Admin_Store_Product_DELETE.replaceAll("{recordId}", id), { headers: headersData })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in DeleteStore axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const reviveStore = (id, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(Admin_Store_Product_Revive__PUT.replaceAll("{recordId}", id), data, { headers: headersData })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in ReviveStore axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const activateStore = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(Admin_Store_Product_Activate_PUT.replaceAll("{recordId}", id), {}, { headers: headersData })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in ActivateStore axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const deactivateStore = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(Admin_Store_Product_Deactivate_PUT.replaceAll("{recordId}", id), {}, { headers: headersData })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in Deactivate Store axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
