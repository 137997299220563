import React, { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { callGetApi } from "../../api/ApiCaller";
import { Agent_Wallet_Balance_GET, Agent_Wallet_Transaction_POST, Wallet_Balance_By_Agent_PlayerID_GET, Wallet_Balance_By_PlayerID_GET, Wallet_Balance_By_WalletID_GET, Wallet_Transaction_By_Agent_PlayerID_POST, Wallet_Transaction_By_PlayerID_POST, Wallet_Transaction_By_WalletID_POST } from "../../api/ApiConst";
import { toast } from "react-hot-toast";
import { useCallback } from "react";
import dateUtilities from "../../utilities/DateUtilities";
import PaginationDataTable from "./PaginationDataTable";

const WalletTransactions = ({ playerOrWallet, ID }) => {
    const [drawCount, setDrawCount] = useState(0);
    const [walletBalance, setWalletBalance] = useState({
        walletBalance: 0,
        withdrawableBalance: 0,
    });

    const getWalletBalanceUrl = () => {
        let url = "";
        if(playerOrWallet === "PLAYER") {
            url = Wallet_Balance_By_PlayerID_GET.replaceAll("{playerID}", ID);
        } else if(playerOrWallet === "AGENT_PLAYER") {
            url = Wallet_Balance_By_Agent_PlayerID_GET.replaceAll("{recordId}", ID);
        } else if(playerOrWallet === "AGENT") {
            url = Agent_Wallet_Balance_GET;
        } else {
            url = Wallet_Balance_By_WalletID_GET.replaceAll("{walletID}", ID);
        }
        return url;
    }

    useEffect(() => {
        getWalletBalance();
    }, [playerOrWallet, ID]);
    const getWalletBalance = () => {
        let url = getWalletBalanceUrl();
        callGetApi(
            url,
            {},
            (response) => {
                if (response.success) {
                    setWalletBalance(response.responsePacket);
                } else {
                    toast.error(response.message);
                }
            },
            (error) => {
                toast.error(error.response.data.message);
            }
        );
    };

    const refreshTable = () => {
        setDrawCount((pre) => pre + 1);
    };

    const getWalletTransactionUrl = () => {
        let url = "";
        if(playerOrWallet === "PLAYER") {
            url = Wallet_Transaction_By_PlayerID_POST.replaceAll("{playerID}", ID);
        } else if(playerOrWallet === "AGENT_PLAYER") {
            url = Wallet_Transaction_By_Agent_PlayerID_POST.replaceAll("{recordId}", ID);
        } else if(playerOrWallet === "AGENT") {
            url = Agent_Wallet_Transaction_POST;
        } else {
            url = Wallet_Transaction_By_WalletID_POST.replaceAll("{walletID}", ID);
        }
        return url;
    }

    const getWalletTransactionHistory = useCallback(() => {
        const url = getWalletTransactionUrl();
        return <PaginationDataTable paginationUrl={url} serverSidePagination={true} search={false} columns={columns} drawCount={drawCount} />;
    }, [ID]);

    const columns = useMemo(
        () => [
            {
                id: "transactionId",
                name: "Txn Id",
                selector: (row) => <div style={{ height: "40px", lineHeight: "40px" }}>{row.transactionId}</div>,
                sortable: false,
                width: "220px",
            },
            {
                id: "createdAt",
                name: "Transaction Date",
                selector: (row) => dateUtilities.formattedDate(row.createdAt, "dd-MM-yyyy hh:mm aa"),
                sortable: false,
                width: "150px",
            },
            {
                id: "openingBalance",
                name: "Previous Bal",
                selector: (row) => <span className={row.drCr === "Cr" ? "text-danger" : "text-success"}>{row.openingBalance.toFixed(2)}</span>,
                sortable: false,
                right: true,
                width: "140px",
            },
            {
                id: "transactionAmount",
                name: "Txn Amount",
                selector: (row) => <span className={row.drCr === "Cr" ? "text-danger" : "text-success"}>{row.transactionAmount.toFixed(2)}</span>,
                sortable: false,
                right: true,
                width: "140px",
            },
            {
                id: "closingBalance",
                name: "Balance",
                selector: (row) => <span className={row.drCr === "Cr" ? "text-danger" : "text-success"}>{row.closingBalance.toFixed(2)}</span>,
                sortable: false,
                right: true,
                width: "140px",
            },
            {
                id: "remark",
                name: "Remark",
                selector: (row) => <span>{row.remark}</span>,
                sortable: false,
            },
        ],
        []
    );

    return (
        <>
            <div className="row">
                <div className="col-xl-4 col-sm-6 col-12">
                    <div className="card mini-stats-wid">
                        <div className="card-body">
                            <div className="d-flex">
                                <div className="flex-grow-1">
                                    <p className="text-muted fw-medium">Wallet Balance</p>
                                    <h4 className="mb-0">{walletBalance?.walletBalance.toFixed(2)}</h4>
                                </div>
                                <div className="flex-shrink-0 align-self-center">
                                    <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                                        <span className="avatar-title font-18">{process.env.REACT_APP_CURRENCY_SYMBOL}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    walletBalance?.withdrawableBalance > 0 ? <>
                        <div className="col-xl-4 col-sm-6 col-12">
                            <div className="card mini-stats-wid">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <p className="text-muted fw-medium">Withdrawable Balance</p>
                                            <h4 className="mb-0">{walletBalance?.withdrawableBalance.toFixed(2)}</h4>
                                        </div>
                                        <div className="flex-shrink-0 align-self-center">
                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                                                <span className="avatar-title font-18">{process.env.REACT_APP_CURRENCY_SYMBOL}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </> : <></>
                }
            </div>
            <div className="card mb-0">
                <div className="card-body">
                    <hr className="mb-0" />
                    <div className="table-rep-plugin custom-table">
                        <div className="table-responsive mb-0 searchInput" data-pattern="priority-columns">
                            {getWalletTransactionHistory()}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WalletTransactions;
