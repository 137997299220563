import { Agent_Profile_Change_Password } from '../../api/ApiConst.js';
import { PROFILE_URL, FILE_UPLOAD_URL } from '../CONSTANT.js'
import { SYSTEM_ERROR } from '../Config/CONSTANT.js';
import axios from 'axios'
import Cookies from 'universal-cookie';

const cook = new Cookies().get('loginData')


const headersdata = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': cook != undefined ? cook.accessToken : ''
}
const headersformdata = {
    "Content-Type": "multipart/form-data",
    "accept": "application/json",
    // ,
    // 'Authorization': cook != undefined ? cook.accessToken : ''
}


export const updatePassword = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(PROFILE_URL() + "changePassword", data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in updatePassword axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const changeAgentPassword = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(Agent_Profile_Change_Password, data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in Agent Change Password axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const uploadFile = (data) => {
    console.log(data);
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(FILE_UPLOAD_URL(), data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in uploadFile axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};