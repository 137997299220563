import WalletTransactions from "../../Common/WalletTransactions";

const AgentPlayerWalletTransactionTab = ({ playerID }) => {
    return (
        <div className='row'>
            <div className='col-12'>
                <WalletTransactions playerOrWallet={'AGENT_PLAYER'} ID={playerID} />
            </div>
        </div>
    );
};

export default AgentPlayerWalletTransactionTab;
