import React, { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { callGetApi } from "../../../api/ApiCaller";
import {  Agent_Wallet_Commission_GET, Agent_Wallet_Commission_TransactionsList_GET } from "../../../api/ApiConst";
import { toast } from "react-hot-toast";
import { useCallback } from "react";
import dateUtilities from "../../../utilities/DateUtilities";
import PaginationDataTable from "../../Common/PaginationDataTable";

const CommissionMaster = () => {
    const [drawCount, setDrawCount] = useState(0);
    const [commissionBalance, setCommissionBalance] = useState("");

    useEffect(() => {
        getCommissionBalance();
    }, []);

    const getCommissionBalance = () => {
        let url = Agent_Wallet_Commission_GET;
        callGetApi(
            url,
            {},
            (response) => {
                if (response.success) {
                    setCommissionBalance(response.responsePacket);
                } else {
                    toast.error(response.message);
                }
            },
            (error) => {
                toast.error(error.response.data.message);
            }
        );
    };

    const refreshTable = () => {
        setDrawCount((pre) => pre + 1);
    };


    const getWalletTransactionHistory = useCallback(() => {
        const url = Agent_Wallet_Commission_TransactionsList_GET;
        return <PaginationDataTable paginationUrl={url} serverSidePagination={true} search={false} columns={columns} drawCount={drawCount} />;
    }, []);

    const columns = useMemo(
        () => [
            {
                id: "transactionId",
                name: "Txn Id",
                selector: (row) => <div style={{ height: "40px", lineHeight: "40px" }}>{row?.transactionId}</div>,
                sortable: false,
                width: "200px",
            },
            {
                id: "createdAt",
                name: "Txn Date",
                selector: (row) => dateUtilities?.formattedDate(row.createdAt, "dd-MM-yyyy hh:mm aa"),
                sortable: false,
                width: "200px",
            },
            {
                id: "transactionType",
                name: "Txn Type",
                selector: (row) => row?.transactionType,
                sortable: false,
                width: "200px",
            },
            {
                id: "transactionAmount",
                name: "Txn Amount",
                selector: (row) => <span className={row.drCr === "Cr" ? "text-danger" : "text-success"}>{row?.transactionAmount.toFixed(2)}</span>,
                sortable: false,
                width: "150px",
            },
            {
                id: "remark",
                name: "Remark",
                selector: (row) => <span>{row?.remark}</span>,
                sortable: false,
            },
        ],
        []
    );

    return (
        <div className="page-content">
            <div className="row">
                <div className="col-xl-4 col-sm-6 col-12">
                    <div className="card mini-stats-wid">
                        <div className="card-body">
                            <div className="d-flex">
                                <div className="flex-grow-1">
                                    <p className="text-muted fw-medium">Commission</p>
                                    <h4 className="mb-0">{commissionBalance?.totalCommission}</h4>
                                </div>
                                <div className="flex-shrink-0 align-self-center">
                                    <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                                        <span className="avatar-title font-18">{process.env.REACT_APP_CURRENCY_SYMBOL}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card mb-0">
                <div className="card-body">
                    <hr className="mb-0" />
                    <div className="table-rep-plugin custom-table">
                        <div className="table-responsive mb-0 searchInput" data-pattern="priority-columns">
                            {getWalletTransactionHistory()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CommissionMaster;
