import React from "react";
import { useState } from "react";
import { adminLogin, agentLogin } from "../Services/AuthService/LoginService";
import Cookies from "universal-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LoginComponent = ({ pathName, loginLogo }) => {
    const [loginData, setLoginData] = useState(null);
    const handleChange = (e) => {
        setLoginData({
            ...loginData,
            [e.target.name]: e.target.value,
        });
    };
    const handleLogin = (e) => {
        if (pathName === "/al") {
            if (loginData === null || loginData === undefined) {
                toast("🙁" + "Please Fill All the Details", {
                    position: "top-right", autoClose: 5000, hideProgressBar: false,
                    closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined,
                })
            } else {
                let dict = {
                    username: loginData?.username,
                    password: loginData?.password,
                };
                adminLogin(dict).then((res) => {
                    if (res.errorCode === 0) {
                        var loginData = res.responsePacket;
                        // set cookie object here
                        const cookies = new Cookies();
                        let d = new Date();
                        d.setTime(d.getTime() + 9 * 60 * 60 * 1000);

                        cookies.set("loginData", loginData, { path: "/", expires: d });
                        toast("👏" + "Login Successfully", {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        window.location.href = "/";
                    } else {
                        toast("🙁" + res.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                });
            }
        } else {
            if (loginData === null || loginData === undefined) {
                toast("🙁" + "Please Fill All the Details", {
                    position: "top-right", autoClose: 5000, hideProgressBar: false,
                    closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined,
                })
            } else {
                let dict = {
                    mobile: loginData?.mobile,
                    password: loginData?.password,
                };
                agentLogin(dict).then((res) => {
                    if (res.errorCode === 0) {
                        var loginData = res.responsePacket;
                        // set cookie object here
                        const cookies = new Cookies();
                        let d = new Date();
                        d.setTime(d.getTime() + 9 * 60 * 60 * 1000);
                        cookies.set("loginData", loginData, { path: "/", expires: d });
                        toast("👏" + "Login Successfully", {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        window.location.href = "/";
                    } else {
                        toast("🙁" + res.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                });
            }
        }
    };
    // SpinItUpLudo
    const getLogo = () => {
        const cookies = new Cookies();
        const profile = cookies.get("profile");
        if (profile && profile !== undefined) {
            return `/assets/images/profile/${loginLogo}/login-logo.png`;
        }
        return `/assets/images/profile/${loginLogo}/login-logo.png`;
    };
    return (
        <>
            <div className="account-pages my-5 pt-sm-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-lg-6 col-xl-5">
                            <div className="card overflow-hidden">
                                <div className="bg-primary bg-soft">
                                    <div className="row">
                                        <div className="col-7">
                                            <div className="text-primary p-4">
                                                <h5 className="text-primary">
                                                    {
                                                        pathName === "/al" ? "Welcome Back !" :
                                                            pathName === "/agl" && "Welcome Back To Agent Login !"
                                                    }
                                                </h5>
                                                <p>Sign in to continue to {loginLogo}.</p>
                                            </div>
                                        </div>
                                        <div className="col-5 align-self-end">
                                            <img src="/assets/images/profile-img.png" alt="" className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body pt-0">
                                    <div className="auth-logo">
                                        <a href="#" className="auth-logo-light">
                                            <div className="avatar-md profile-user-wid mb-4">
                                                <span className="avatar-title rounded-circle bg-light">
                                                    <img src={getLogo()} alt="" className="rounded-circle" height={40} />
                                                </span>
                                            </div>
                                        </a>
                                        <a href="#" className="auth-logo-dark">
                                            <div className="avatar-md profile-user-wid mb-4">
                                                <span className="avatar-title rounded-circle bg-light">
                                                    <img src={getLogo()} alt="" className="rounded-circle" height={50} />
                                                </span>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="p-2">
                                        <form className="form-horizontal text-left">
                                            {
                                                pathName === '/al' ?
                                                    <div className="mb-3">
                                                        <label htmlFor="username" className="form-label">
                                                            Username
                                                        </label>
                                                        <input type="text" className="form-control" id="username" name="username" placeholder="Enter username" onChange={(e) => handleChange(e)} />
                                                    </div> :
                                                    <div className="mb-3">
                                                        <label htmlFor="mobile" className="form-label">
                                                            Mobile Number
                                                        </label>
                                                        <input type="number" className="form-control" id="mobile" name="mobile" placeholder="Enter mobile Number" onChange={(e) => handleChange(e)} />
                                                    </div>
                                            }
                                            <div className="mb-3">
                                                <label className="form-label">Password</label>
                                                <div className="input-group auth-pass-inputgroup">
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        placeholder="Enter password"
                                                        aria-label="Password"
                                                        name="password"
                                                        aria-describedby="password-addon"
                                                        onChange={(e) => handleChange(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mt-3 d-grid">
                                                <a className="btn btn-primary waves-effect waves-light" type="submit" onClick={(e) => handleLogin()}>
                                                    Log In
                                                </a>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 text-center">
                                <div>
                                    {/* <p>
              © Skote. Crafted with <i className="mdi mdi-heart text-danger" />{" "}
              by Themesbrand
            </p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            ;
            <ToastContainer />
        </>
    );
};

export default LoginComponent;
