import { TEMPLETE_URL } from '../CONSTANT.js'
import { SNACK_LADDER_URL } from '../CONSTANT.js'
import { SYSTEM_ERROR } from '../Config/CONSTANT.js';
import axios from 'axios'
import Cookies from 'universal-cookie';

const cook = new Cookies().get('loginData')


const headersdata = {
    'content-type': 'application/json',
    'Accept': 'application/json',
    'Authorization': cook != undefined ? cook.accessToken : ''
}


export const runningGameList = () => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(TEMPLETE_URL() + "getAllRunningTables",  { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getRunning Table List axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const stopTable = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(TEMPLETE_URL() + "stopTable/" + id, {}, { headers: headersdata })
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject("Error in stopTable axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

