import { LUDO_TEMPLATE_URL } from '../CONSTANT.js'
import { SNACK_LADDER_URL } from '../CONSTANT.js'
import { SYSTEM_ERROR } from '../Config/CONSTANT.js';
import axios from 'axios'
import Cookies from 'universal-cookie';

const cook = new Cookies().get('loginData')


const headersdata = {
    'content-type': 'application/json',
    'Accept': 'application/json',
    'Authorization': cook != undefined ? cook.accessToken : ''
}


export const addLudoGameTemplete = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(LUDO_TEMPLATE_URL(), data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in addGameTemplete axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const updateLudoTempleteDetails = (id, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(LUDO_TEMPLATE_URL() + id, data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in addGameTemplete axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const getLudoTempleteList = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(LUDO_TEMPLATE_URL() + "list", data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in addGameTemplete axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const getLudoTempleteDetails = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(LUDO_TEMPLATE_URL() + id, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in addGameTemplete axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const deleteLudoTemplete = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .delete(LUDO_TEMPLATE_URL() + id,{ headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in addGameTemplete axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const activateLudoTemplete = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(LUDO_TEMPLATE_URL() + "activate/" + id, {}, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in addGameTemplete axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const deactivateLudoTemplete = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(LUDO_TEMPLATE_URL() + "deactivate/" + id, {}, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in addGameTemplete axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const startLudoGame = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(LUDO_TEMPLATE_URL() + "startGame/" + id, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in addGameTemplete axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const getLudoSnakeList = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(SNACK_LADDER_URL() + "list/" + id, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in addGameTemplete axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const getLudoLadderList = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(SNACK_LADDER_URL() + "list/" + id, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in addGameTemplete axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const getLudoBoardList = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(SNACK_LADDER_URL() + "list/" + id, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in addGameTemplete axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const publishLudoTemplete = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(LUDO_TEMPLATE_URL() + "publishTemplate/" + id, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in addGameTemplete axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const unPublishLudoTemplete = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(LUDO_TEMPLATE_URL() + "unPublishTemplate/" + id, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in addGameTemplete axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};