import React from 'react'

import WalletTransactions from '../../Common/WalletTransactions'

const AgentWalletMaster = () => {
    return (
        <div className='page-content'>
        <React.Fragment>
           <WalletTransactions playerOrWallet={"AGENT"}/>
        </React.Fragment>
        </div>
    )
}

export default AgentWalletMaster