import { BASE_URL } from "../api/ApiConst";

// const BASE_URL = "http://192.168.1.53:8011/";



export const LOGIN_URL = () => BASE_URL + "adminLogin/";

export const AGENT_LOGIN_URL = () => BASE_URL + "agentLogin/"

export const COUNTRY_URL = () => BASE_URL + "country/";
export const PLAYER_URL = () => BASE_URL + "player/";
export const PROFILE_URL = () => BASE_URL + "profile/";
export const FILE_UPLOAD_URL = () => BASE_URL + "uploadFile/inMultipartFile";
export const EXCEL_FILE_UPLOAD_URL = () => BASE_URL + "ops/v1/uploadExcel/sendBonusToPlayers";
export const SETTINGS_URL = () => BASE_URL + "settings/";
export const SNACK_LADDER_URL = () => BASE_URL + "snakeLadderAssets/";
export const TEMPLETE_URL = () => BASE_URL + "snakeLadderGameTemplate/";
export const STATIC_PAGES_URL = () => BASE_URL + "staticPages/";

//Ludo 
export const LUDO_TEMPLATE_URL = () => BASE_URL + "ludoGameTemplate/";

//Banner
export const BANNER_PAGES_URL = () => BASE_URL + "banner/";

//Banner
export const AVATAR_PAGES_URL = () => BASE_URL + "avatar/";

//Banner
export const WITHDRAW_PAGES_URL = () => BASE_URL + "withdrawRequest/";