import React, { useEffect, useMemo, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Modal } from "react-bootstrap";
import ConfirmationDialoge from "./Common/ConfirmationDialoge";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import {
    Admin_System_Message_List_POST,
    All_Deleted_Admin_System_Message_List_POST
} from "../api/ApiConst";
import dateUtilities from "../utilities/DateUtilities";
import PaginationDataTable from "./Common/PaginationDataTable";
import PageHeading from "./Common/PageHeading";
import CustomButton from "./Common/CustomButton";
import CheckboxButton from "./Common/CheckboxButton";
import { activateSystemMessage, addSystemMessage, deactivateSystemMessage, deleteSystemMessage, getSystemMessageDetails, reviveSystemMessage, updateSystemMessageDetails } from "../Services/MasterServices/SystemMessageService";

const AdminSystemMessage = () => {
    const [show, setShow] = useState({
        addSystemMessage: false,
    });
    const [drawCount, setDrawCount] = useState(0);
    const [edit, setEdit] = useState(false);
    const [id, setId] = useState(null);

    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        isAuth: true,
        title: "",
        subTitle: "",
        severity: "",
    });


    const [viewDeletedList, setViewDeletedList] = useState(false);
    const handleToggleDeletedView = () => setViewDeletedList(!viewDeletedList);
    const handleRefresh = () => setDrawCount((prev) => prev + 1);

    const [record, setRecord] = useState({
        message: "",
    });

    const handleAddSystemMessageModal = () => {
        setShow({ ...show, addSystemMessage: false });
        handleClearForm();
    };

    const handleNewSystemMessage = () => {
        setEdit(false);
        setShow({ ...show, addSystemMessage: true });
        setId(null);
    };

    const handleInputChange = (e) => {
        const { name, value, id } = e.target;
        setRecord({ ...record, [id]: value });
    }


    const handleDeleteSystemMessage = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        deleteSystemMessage(id).then((res) => {
            if (res.errorCode === 0) {
                handleSuccessToast(res?.message);
                handleRefresh();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            } else {
                handleFailedToast(res.message);
            }
        });
    };

    const handleReviveData = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        reviveSystemMessage(id).then((res) => {
            if (res.errorCode === 0) {
                handleSuccessToast(res?.message);
                handleRefresh();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            } else {
                handleFailedToast(res.message);
            }
        });
    }
    const handleSystemMessageDeactivate = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        deactivateSystemMessage(id).then((res) => {
            if (res.errorCode === 0) {
                handleSuccessToast(res?.message);
                handleRefresh();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            } else {
                handleFailedToast(res?.message);
            }
        });
    };
    const handleSystemMessageActivate = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        activateSystemMessage(id).then((res) => {
            if (res.errorCode === 0) {
                handleSuccessToast(res?.message);
                handleRefresh();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            } else {
                handleFailedToast(res?.message);
            }
        });
    };
    const handleForceLogout = (data) => {
        toast("🙁" + data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setConfirmDialog({
            isOpen: true,
            isAuth: true,
            title: "You Are LoggedIn on Another Screen! to Countinue Logout and Login Again!!",
            onConfirm: () => logout(),
        });
    };

    const logout = () => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        const clearCookies = document.cookie.split(";").forEach((cookie) => (document.cookie = cookie.replace(/^ +/, "").replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`)));
        window.location.reload(1);
    };

    const columns = useMemo(
        () => [{
            id: "status",
            name: "",
            selector: (row) => {
                if (row.active) {
                    return <div className="led-green" productTitle="Active"></div>;
                } else {
                    return <div className="led-red" productTitle="Not Active"></div>;
                }
            },
            width: "60px",
            sortable: false,
        },
        {
            name: "Message",
            selector: (row) => row?.message,
            sortable: true,
            width: "65%",
            wrap: true
        },
        {
            name: "Created At",
            selector: (row) => dateUtilities.formattedDate(row?.createdAt, "dd-MM-yyyy hh:mm aa" ),
            sortable: true,
            width: "300px",
            wrap: true
        },
        {
            name: "Actions",
            cell: (row) => (
                <div className="dropdown">
                    <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                        <KeyboardArrowDownOutlinedIcon />
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                        {!viewDeletedList && (
                            <li onClick={() => handleEditSystemMessage(row?.uuid)}>
                                <button className="dropdown-item" type="button">
                                    Edit
                                </button>
                            </li>
                        )}
                        {!viewDeletedList && (
                            <li
                                onClick={() =>
                                    setConfirmDialog({
                                        isOpen: true,
                                        title: "Are You Sure? You want to Delete this record",
                                        onConfirm: () => handleDeleteSystemMessage(row?.uuid),
                                    })
                                }
                            >
                                <button className="dropdown-item" type="button">
                                    Delete
                                </button>
                            </li>
                        )}
                        {viewDeletedList ? (
                            <li
                                onClick={() =>
                                    setConfirmDialog({
                                        isOpen: true,
                                        title: "Are You Sure? You want to Revive this record",
                                        onConfirm: () => handleReviveData(row?.uuid),
                                    })
                                }
                            >
                                <button className="dropdown-item" type="button">
                                    Revive
                                </button>
                            </li>
                        ) : (
                            row.active ? (
                                <li
                                    onClick={() =>
                                        setConfirmDialog({
                                            isOpen: true,
                                            title: "Are You Sure? You want to Deactivate this System Message",
                                            onConfirm: () => handleSystemMessageDeactivate(row?.uuid),
                                        })
                                    }
                                >
                                    <button className="dropdown-item" type="button">
                                        Deactivate
                                    </button>
                                </li>
                            ) : (
                                <li
                                    onClick={() =>
                                        setConfirmDialog({
                                            isOpen: true,
                                            title: "Are You Sure? You want to Activate this System Message",
                                            onConfirm: () => handleSystemMessageActivate(row?.uuid),
                                        })
                                    }
                                >
                                    <button className="dropdown-item" type="button">
                                        Activate
                                    </button>
                                </li>
                            )
                        )}
                    </ul>
                </div>
            ),
            sortable: true,
            right: true,
        }
        ],
        [drawCount, viewDeletedList]
    );


    const handleAdd = (e) => {
        e.preventDefault();
        submitForm();
    };

    const submitForm = () => {
        let request = {
            message: record?.message,
        };

        if (request.chips === 0 || request.chips === null) {
            handleWarningToast("Please provide chips.");
            return;
        } else if (request.productPrice === 0 || request.productPrice === null) {
            handleWarningToast("Please provide productPrice .");
            return;
        }

        if (id) {
            updateSystemMessageDetails(id, request).then((res) => {
                if (res.errorCode === 0) {
                    handleSuccessToast(res?.message);
                    handleRefresh();
                    handleAddSystemMessageModal();
                    handleClearForm();
                } else if (res.message === "Unauthorised Access") {
                    handleForceLogout(res.message);
                } else {
                    handleFailedToast(res?.message);
                }
            })
        } else {
            addSystemMessage(request).then((res) => {
                if (res.errorCode === 0) {
                    handleSuccessToast(res?.message);
                    handleRefresh();
                    handleAddSystemMessageModal();

                    handleClearForm();
                } else if (res.message === "Unauthorised Access") {
                    handleForceLogout(res.message);
                } else {
                    handleFailedToast(res?.message);
                }
            });
        }
    };

    const handleEditSystemMessage = (id) => {
        setId(id);
        setShow({ ...show, addSystemMessage: true });
        setEdit(true);

        getSystemMessageDetails(id).then((res) => {
            if (res.errorCode === 0) {
                let MessageDetails = res.responsePacket;
                let dict = {
                    message: MessageDetails?.message,
                };

                setRecord(dict);
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            }
        });
    };

    const handleWarningToast = (data) => {
        toast.warn(data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    const handleSuccessToast = (data) => {
        toast("👏" + data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    const handleFailedToast = (data) => {
        toast("🙁" + data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    const handleClearForm = () => {
        setRecord({
            message: ""
        });
    };

    const addSystemMessageModal = () => {
        return (
            <Modal className="tablerowmodal" show={show.addSystemMessage} onHide={() => handleAddSystemMessageModal()} size="lg" aria-labelledby="contained-modal-title-vcenter">
                <Modal.Body className="">
                    <div className="modal-header border-bottom-0">
                        <h4>{edit ? "Edit Message" : "Add Message "}</h4>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleAddSystemMessageModal()}></button>
                    </div>
                    <div className="card-body">
                        <form>
                            <div className="row mb-3">
                                <div className="col-12">
                                    <label htmlFor="formrow-email-input" className="form-label">
                                        Message
                                    </label>
                                    <textarea
                                        type="text"
                                        rows="3"
                                        className="form-control"
                                        id="message"
                                        value={record?.message}
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </div>
                            </div>
                            <div>
                                <a className="btn btn-primary w-md" onClick={handleAdd}>
                                    {edit ? "Update" : "Add "}
                                </a>
                                &nbsp; &nbsp;
                                <a onClick={() => handleAddSystemMessageModal()} className="btn btn-primary w-md">
                                    Cancel
                                </a>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal >
        );
    };

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <PageHeading heading="System Message List" />
                    <div className="card">
                        <div className="d-flex justify-content-end" style={{ position: "absolute", top: "22px", left: "35px", zIndex: 100 }}>
                            <div style={{ width: "200px" }}>
                                <CustomButton handleClick={handleNewSystemMessage} title="Add Message" iconClass="bx bx-plus" buttonClass="createButton" />
                            </div>
                            <div style={{ width: "200px" }}>

                            </div>
                        </div>
                        <div className="card-body">
                            <PaginationDataTable
                                paginationUrl={viewDeletedList ? All_Deleted_Admin_System_Message_List_POST : Admin_System_Message_List_POST}
                                serverSidePagination={true}
                                search={true}
                                columns={columns}
                                additionalClassName={"agentSearchInput"}
                            />
                            <CheckboxButton title="Deleted List" handleClick={handleToggleDeletedView} additionalClass="deletedBtn ml-auto" />
                        </div>
                    </div>
                </div>
            </div>
            {show.addSystemMessage && addSystemMessageModal()}
            <ToastContainer />
            <ConfirmationDialoge confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
        </>
    );
};

export default AdminSystemMessage;
