import AgentWalletTransactions from './AgentWalletTransactions';

const AgentWalletTransactionTab = ({ agentID }) => {
    return (
        <div className='row'>
            <div className='col-12'>
                <AgentWalletTransactions playerOrWallet={''} ID={agentID} />
            </div>
        </div>
    );
};

export default AgentWalletTransactionTab;
