import React, { useState, useEffect, useMemo } from "react";
import { getWithdrawRequestList, requestApproved, requestReject } from "../Services/index.js";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import DataTable from "react-data-table-component";
import { Modal } from "react-bootstrap";
import ConfirmationDialoge from "./Common/ConfirmationDialoge";
import dateUtilities from "../utilities/DateUtilities.jsx";
import PageHeading from "./Common/PageHeading.jsx";
import { Get_Withdraw_Money_Request_Details_GET, Withdraw_Request_List_POST } from "../api/ApiConst.js";
import { callGetApi } from "../api/ApiCaller.js";
import PaginationDataTable from "./Common/PaginationDataTable.jsx";
import { Link, useNavigate, useParams } from "react-router-dom";

const filterOptions = [
    {
        value: "",
        label: "All",
    },
    {
        value: "Pending",
        label: "Pending",
    },
    {
        value: "Processing",
        label: "Processing",
    },
    {
        value: "Hold",
        label: "Hold",
    },
]

const WithdrawRequest = () => {
    const { filterType } = useParams();
    const navigate = useNavigate();

    const [id, setId] = useState(null);
    const [transactionId, setTransactionId] = useState(null);
    const [referenceId, setReferenceId] = useState(null);
    const [rejectRemark, setRejectRemark] = useState(null);
    const [details, setDetails] = useState(null);
    const [recordID, setRecordID] = useState(null);
    const [drawCount, setDrawCount] = useState(0);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, isAuth: true, title: "", subTitle: "", severity: "" });

    const [filter, setFilter] = useState("");

    useEffect(() => {
        if (filterType) {
            if (filterType === "Pending") {
                setFilter(filterOptions[1]);
            } else if (filterType === "Processing") {
                setFilter(filterOptions[2]);
            } else if (filterType === "Hold") {
                setFilter(filterOptions[3]);
            }
        } else {
            setFilter(filterOptions[0]);
        }
    }, [filterType]);

    const [show, setShow] = useState({
        accept: false,
        reject: false,
    });

    const handleAcceptModal = () => setShow({ ...show, accept: false });
    const handleRejectModal = () => setShow({ ...show, reject: false });

    const handleRefresh = () => setDrawCount((prev) => prev + 1);

    const handleAccept = (id) => {
        setId(id);
        setShow({
            ...show,
            accept: true,
        });
        setShowDetailsModal(false);
    };

    useEffect(() => {
        if (!showDetailsModal) {
            setDetails(null);
            setRecordID(null);
        }
    }, [showDetailsModal]);

    const handleClearForm = () => {
        setTransactionId(null);
        setReferenceId(null);
        setRejectRemark(null);
    };

    const handleReject = (id) => {
        setId(id);
        setShow({
            ...show,
            reject: true,
        });
        setShowDetailsModal(false);
    };

    const handleRequestApproved = () => {
        let temp = {
            referenceId: referenceId,
            transactionId: transactionId,
        };
        if (referenceId === null) {
            toast.warn("🙁" + "Reference Id Can`t be Empty", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        } else if (transactionId === null) {
            toast.warn("🙁" + "Transaction Id Can`t be Empty", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        requestApproved(id, temp).then((res) => {
            if (res.errorCode === 0) {
                toast("👏" + "Withdraw request hsa been approved successfully.", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                handleAcceptModal();
                handleClearForm();
                handleRefresh();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            } else if (res.errorCode === 999) {
                handleFailerToast(res.message);
            }
        });
    };

    const handleFailerToast = (data) => {
        toast("🙁" + data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const handleForceLogout = (data) => {
        toast("🙁" + data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setConfirmDialog({
            isOpen: true,
            isAuth: true,
            title: "You Are LoggedIn on Another Screen! to Countinue Logout and Login Again!!",
            onConfirm: () => logout(),
        });
    };

    const logout = () => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        const clearCookies = document.cookie.split(";").forEach((cookie) => (document.cookie = cookie.replace(/^ +/, "").replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`)));
        window.location.reload(1);
    };

    const handleRequestReject = () => {
        let temp = {
            remark: rejectRemark,
        };
        if (rejectRemark === null) {
            toast.warn("🙁" + "Remarks Can`t be Empty", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        requestReject(id, temp).then((res) => {
            if (res.errorCode === 0) {
                toast("👏" + "Withdraw request hsa been rejected successfully.", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                handleRejectModal();
                handleClearForm();
                handleRefresh();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            } else if (res.errorCode === 999) {
                handleFailerToast(res.message);
            }
        });
    };

    const columns = useMemo(
        () => [
            {
                id: "status",
                name: "",
                selector: (row) => {
                    if (row.withdrawTransactionStatus === "Pending") {
                        return <div className="led-yellow" title="Pending"></div>;
                    } else if (row.withdrawTransactionStatus === "Approved") {
                        return <div className="led-green" title="Approved"></div>;
                    } else {
                        return <div className="led-red" title="Rejected"></div>;
                    }
                },
                width: "60px",
                sortable: false,
            },
            {
                name: "Player Mobile",
                selector: (row) => (
                    <Link to={"/playerDetail?rId=" + row.playerId} target="_blank" key="t-adminUserDetail">
                        {"+91 " + row.playerMobile}
                    </Link>
                ),
                sortable: true,
                reorder: true,
            },
            // {
            //     name: 'Player Name',
            //     selector: row => row.playerName,
            //     sortable: true,
            //     grow: 2,
            //     reorder: true,
            // },
            {
                name: "Transaction ID",
                selector: (row) => row.transactionRefId,
                sortable: true,
                reorder: true,
            },
            {
                name: "Transaction Date",
                selector: (row) => dateUtilities.formattedDate(row.createdAt, "dd-MM-yyyy hh:mm aa"),
                sortable: true,
                reorder: true,
            },
            {
                name: "Account Type",
                selector: (row) => row.accountType,
                sortable: true,
                right: true,
                reorder: true,
            },
            {
                name: "Amount",
                selector: (row) => row.amount?.toFixed(2),
                sortable: true,
                right: true,
                reorder: true,
            },
            {
                name: "Transfer Type",
                selector: (row) => row.transferType,
                sortable: true,
                right: true,
                reorder: true,
            },
            {
                name: "Actions",
                cell: (row) => (
                    <div>
                        <span className="btn btn-success mx-1 py-1 px-2 font-size-16" onClick={() => handleAccept(row.recordId)}>
                            <i className="bx bx-check" />
                        </span>
                        <span className="btn btn-danger mx-1 py-1 px-2 font-size-16" onClick={() => handleReject(row.recordId)}>
                            <i className="bx bx-x" />
                        </span>
                        <span className="btn btn-primary mx-1 py-1 px-2 font-size-16" onClick={() => handleViewDetails(row.recordId)}>
                            <i className="bx bx-show" />
                        </span>
                    </div>
                ),
                sortable: true,
                right: true,
                reorder: true,
            },
        ],
        [drawCount, filter]
    );

    const handleViewDetails = (recordId) => {
        let url = Get_Withdraw_Money_Request_Details_GET.replaceAll("{withdrawRequestID}", recordId);
        setRecordID(recordId);
        callGetApi(
            url,
            {},
            (response) => {
                setDetails(response.responsePacket);
                setShowDetailsModal(true);
            },
            (error) => {
                console.log(error);
            }
        );
    };

    const detailsModal = () => {
        return (
            <Modal className="tablerowmodal" show={showDetailsModal} onHide={() => setShowDetailsModal(false)} size="md" aria-labelledby="contained-modal-title-vcenter">
                <Modal.Body>
                    <div className="border-bottom d-flex justify-content-between align-items-center pb-3">
                        <h4 className="m-0">Withdraw Request Details</h4>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowDetailsModal(false)}></button>
                    </div>
                    <div className="card-body">
                        <div className="d-flex justify-content-between font-size-13 mb-2">
                            <span>Transaction ID:</span>
                            <b className="ms-2">{details?.transactionId}</b>
                        </div>
                        <div className="d-flex justify-content-between font-size-13 mb-2">
                            <span>Withdraw Transaction Status:</span>
                            <b className="ms-2">{details?.withdrawTransactionStatus}</b>
                        </div>
                        <div className="d-flex justify-content-between font-size-13 mb-2">
                            <span>Amount:</span>
                            <b className="ms-2">{details?.amount.toFixed(2)}</b>
                        </div>
                        <div className="d-flex justify-content-between font-size-13 mb-2">
                            <span>Account Type:</span>
                            <b className="ms-2">{details?.accountType}</b>
                        </div>
                        {details?.transferType === "UPI" ? (
                            <div className="d-flex justify-content-between font-size-13 mb-2">
                                <span>UPI ID:</span>
                                <b className="ms-2">{details?.upiId}</b>
                            </div>
                        ) : details?.transferType === "CRYPTO" ? (
                            <div className="d-flex justify-content-between font-size-13 mb-2">
                                <span>Crypto Wallet Address:</span>
                                <b className="ms-2">{details?.cryptoWalletAddress}</b>
                            </div>
                        ) : (
                            <div>
                                <div className="d-flex justify-content-between font-size-13 mb-2">
                                    <span>Bank Name:</span>
                                    <b className="ms-2">{details?.bankName}</b>
                                </div>
                                <div className="d-flex justify-content-between font-size-13 mb-2">
                                    <span>Account Number:</span>
                                    <b className="ms-2">{details?.accountNumber}</b>
                                </div>
                                <div className="d-flex justify-content-between font-size-13 mb-2">
                                    <span>Account Holder Name:</span>
                                    <b className="ms-2">{details?.accountHolderName}</b>
                                </div>
                                <div className="d-flex justify-content-between font-size-13 mb-2">
                                    <span>IFSC Code:</span>
                                    <b className="ms-2">{details?.ifscCode}</b>
                                </div>
                            </div>
                        )}
                        {details?.payTmMobileNumber && details?.payTmMobileNumber !== "" && (
                            <div className="d-flex justify-content-between font-size-13 mb-2">
                                <span>PayTm Mobile Number:</span>
                                <b className="ms-2">{details?.payTmMobileNumber}</b>
                            </div>
                        )}
                        <div className="d-flex justify-content-between font-size-13 mb-2">
                            <span>Transfer Type:</span>
                            <b className="ms-2">{details?.transferType}</b>
                        </div>
                        <div className="d-flex justify-content-between font-size-13 mb-2">
                            <span>Convenience Fee:</span>
                            <b className="ms-2">{details?.convenienceFee.toFixed(2)}</b>
                        </div>
                        {details?.remark && details?.remark !== "" && (
                            <div className="d-flex justify-content-between font-size-13 mb-2">
                                <span>Remark:</span>
                                <b className="ms-2">{details?.remark}</b>
                            </div>
                        )}
                        {details?.thirdPartyReferenceId && details?.thirdPartyReferenceId !== "" && (
                            <div className="d-flex justify-content-between font-size-13 mb-2">
                                <span>Third Party Reference Id:</span>
                                <b className="ms-2">{details?.thirdPartyReferenceId}</b>
                            </div>
                        )}
                        {details?.thirdPartyTransactionId && details?.thirdPartyTransactionId !== "" && (
                            <div className="d-flex justify-content-between font-size-13 mb-2">
                                <span>Third Party Transaction Id:</span>
                                <b className="ms-2">{details?.thirdPartyTransactionId}</b>
                            </div>
                        )}
                        {details?.refundTransactionId && details?.refundTransactionId !== "" && (
                            <div className="d-flex justify-content-between font-size-13 mb-2">
                                <span>Refund Transaction Id:</span>
                                <b className="ms-2">{details?.refundTransactionId}</b>
                            </div>
                        )}
                    </div>
                    <div className="d-flex justify-content-end pe-3">
                        <button className="btn btn-success me-2" onClick={() => handleAccept(recordID)}>
                            Accept
                        </button>
                        <button className="btn btn-danger" onClick={() => handleReject(recordID)}>
                            Reject
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    };

    return (
        <div>
            <div className="page-content">
                <div className="container-fluid">
                    <PageHeading heading={"Withdraw Request"} />
                    <div className="row">
                        <div className="col-12">
                            <div className="card p-3">
                                    <div className="custom_dropdown">
                                    <Select
                                    options={filterOptions}
                                    value={filter}
                                    onChange={(e) => {
                                        e.value === "" ? navigate(`/withdraw-request`) : navigate(`/withdraw-request/${e.value}`);
                                        setFilter(e);
                                    }}
                                    />
                                    </div>
                                    <div id="datatable_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                    <PaginationDataTable 
                                    paginationUrl={Withdraw_Request_List_POST} 
                                    serverSidePagination={true} 
                                    drawCount={drawCount} search={true} 
                                    columns={columns} 
                                    filter={{txnStatus: filter.value }}
                                />
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {detailsModal()}
            <Modal className="tablerowmodal" show={show.accept} onHide={() => handleAcceptModal()} size="md" aria-labelledby="contained-modal-title-vcenter">
                <Modal.Body className="">
                    <div className="modal-header border-bottom-0">
                        <h4>Accept</h4>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleAcceptModal()}></button>
                    </div>
                    <div className="card-body">
                        <form>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label htmlFor="formrow-email-input" className="form-label">
                                            Enter Reference ID
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="formrow-email-input"
                                            placeholder="Enter Reference ID"
                                            value={referenceId}
                                            onChange={(e) => setReferenceId(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label htmlFor="formrow-email-input" className="form-label">
                                            Enter Transaction ID
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="formrow-email-input"
                                            placeholder="Enter Transaction ID"
                                            value={transactionId}
                                            onChange={(e) => setTransactionId(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <a className="btn btn-primary w-md" onClick={() => handleRequestApproved()}>
                                    Approved
                                </a>
                                &nbsp; &nbsp;
                                <a onClick={() => handleAcceptModal()} className="btn btn-danger w-md">
                                    Close
                                </a>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal className="tablerowmodal" show={show.reject} onHide={() => handleRejectModal()} size="md" aria-labelledby="contained-modal-title-vcenter">
                <Modal.Body className="">
                    <div className="modal-header border-bottom-0">
                        <h4>Reject</h4>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleRejectModal()}></button>
                    </div>
                    <div className="card-body">
                        <form>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label htmlFor="formrow-email-input" className="form-label">
                                            Please enter note, why you want to reject this request.
                                        </label>
                                        <textarea
                                            type="text"
                                            className="form-control"
                                            id="formrow-email-input"
                                            placeholder="Enter Remarks"
                                            value={rejectRemark}
                                            onChange={(e) => setRejectRemark(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <a className="btn btn-primary w-md" onClick={() => handleRequestReject()}>
                                    Reject
                                </a>
                                &nbsp; &nbsp;
                                <a onClick={() => handleRejectModal()} className="btn btn-danger w-md">
                                    Close
                                </a>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
            <ToastContainer />
            <ConfirmationDialoge confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
        </div>
    );
};

export default WithdrawRequest;
