import React, { useState, useEffect, useMemo } from "react";
import { ToastContainer } from "react-toastify";
import ConfirmationDialoge from "./Common/ConfirmationDialoge";
import dateUtilities from "../utilities/DateUtilities.jsx";
import PageHeading from "./Common/PageHeading.jsx";
import { Get_Login_Logs_POST } from "../api/ApiConst.js";
import PaginationDataTable from "./Common/PaginationDataTable.jsx";
import { Link } from "react-router-dom";

const LoginLog = () => {
    const [recordID, setRecordID] = useState(null);
    const [drawCount, setDrawCount] = useState(0);

    const columns = useMemo(
        () => [
            {
                name: "Record Id",
                selector: (row) => row.recordId,
                sortable: true,
                reorder: true,
            },
            {
                name: "Player",
                selector: (row) => (row.fullName ? row.fullName : row.nickName),
                sortable: true,
                reorder: true,
            },
            {
                name: "Mobile Number",
                selector: (row) => (
                    <Link to={"/playerDetail?rId=" + row.playerId} target="_blank">
                        {row.countryCode+ " " + row.mobileNumber}
                    </Link>
                ),
                sortable: true,
                reorder: true,
            },
            {
                name: "IP Address",
                selector: (row) => row.ipAddress,
                sortable: true,
                reorder: true,
            },
            {
                name: "Created At",
                selector: (row) => dateUtilities.formattedDate(row.createdAt, "dd-MM-yyyy"),
                sortable: true,
                reorder: true,
            },
            // {
            //     name: "Gender",
            //     selector: (row) => row.gender,
            //     sortable: true,
            //     reorder: true,
            // },
        ],
        [drawCount]
    );

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <PageHeading heading={"Login Logs"} />
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div id="datatable_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                        <PaginationDataTable
                                            paginationUrl={Get_Login_Logs_POST}
                                            serverSidePagination={true}
                                            drawCount={drawCount} 
                                            search={true}
                                            columns={columns}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LoginLog;
