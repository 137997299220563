import React, { useEffect } from "react";
import { Admin_Player_Details_GET, Get_Details_Agent_AgentTemplate_GET } from "../../../api/ApiConst";
import { callGetApi } from "../../../api/ApiCaller";
import { useState } from "react";
import dateUtilities from "../../../utilities/DateUtilities";
import { Toaster } from "react-hot-toast";
import AgentWalletTransactionTab from "./AgentWalletTransactionTab";
import formatMoneyAmountUtils from "../../../utilities/FormatMoneyAmountUtils";

const AgentTabs = ["Wallet Transactions"];

const AgentDetails = (props) => {
    let agentID = props.rId;
    const [activeTab, setActiveTab] = useState(AgentTabs[0]);
    const [record, setRecord] = useState({
        recordId: "",
        nickName: "",
        countryCode: "",
        mobileNumber: "",
        profileImageUrl: "",
        referralCode: "",
        walletBalance: 0,
        createdAtTimeStamp: "",
    });

    useEffect(() => {
        getPlayerDetails();
    }, []);

    const getPlayerDetails = () => {
        let url = Get_Details_Agent_AgentTemplate_GET.replaceAll("{recordId}", agentID);
        callGetApi(
            url,
            {},
            (response) => {
                setRecord({
                    recordId: response.responsePacket.recordId,
                    fullName: response.responsePacket.fullName,
                    isdCode: response.responsePacket.isdCode,
                    gender: response.responsePacket.gender,
                    mobileNumber: response.responsePacket.mobileNumber,
                    profileImageUrl: response.responsePacket.profileImageUrl,
                    referralCode: response.responsePacket.referralCode,
                    walletBalance: response.responsePacket.walletBalance,
                    dateOfBirth: response.responsePacket.dateOfBirth,
                    createdAtTimeStamp: response.responsePacket.createdAtTimeStamp,
                });
            },
            (error) => {
                console.log(error);
            }
        );
    };

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">Agent Details</h4>
                                <div className="page-title-right"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div id="datatable_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="card p-3">
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <div className="form-group mb-3 text-center">
                                                        <img className="playerDetailProfile" src={record?.profileImageUrl ? record?.profileImageUrl : "/assets/images/user.png"} alt="Player" />
                                                        <h5 className="mt-3">
                                                            <b>{record?.fullName}</b>
                                                        </h5>
                                                    </div>
                                                </div>
                                                <div className="form-group mb-2">
                                                    <div className="input-group">
                                                        <div className="input-group-addon">
                                                            <i className="bx bx-phone text-primary"></i>
                                                        </div>
                                                        <div className="form-control">
                                                            {record?.isdCode}
                                                            <span className="ms-1">{record?.mobileNumber}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group mb-2">
                                                    <div className="input-group">
                                                        <div className="input-group-addon">
                                                            <i className="bx bx-calendar text-primary"></i>
                                                        </div>
                                                        <div className="form-control">
                                                            <span>{record?.dateOfBirth && dateUtilities.formattedDate(record?.dateOfBirth, "dd-MM-yyyy")}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group mb-2">
                                                    <div className="d-flex justify-content-between form-control">
                                                        <div className="text-grey">Gender:</div>
                                                        <span className="text-primary ms-2 fw-bold">{record?.gender}</span>
                                                    </div>
                                                </div>
                                                <div className="form-group mb-2">
                                                    <div className="d-flex justify-content-between form-control">
                                                        <div className="text-grey">Wallet Balance:</div>
                                                        <span className="text-primary ms-2 fw-bold">{formatMoneyAmountUtils?.formatAmount(record?.walletBalance)}</span>
                                                    </div>
                                                </div>
                                                <div className="form-group mb-2">
                                                    <div className="d-flex justify-content-between form-control">
                                                        <div className="text-grey">Agent Code:</div>
                                                        <span className="text-primary ms-2 fw-bold">{record?.referralCode}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" col-lg-9">
                                        <div className="card p-3">
                                            <nav>
                                                <div className="nav nav-tabs nav-tabs-custom mb-3" id="nav-tab" role="tablist">
                                                    {AgentTabs.map((tab, i) => (
                                                        <a
                                                            key={i}
                                                            onClick={() => setActiveTab(tab)}
                                                            className={`nav-link ${tab === activeTab ? "active" : ""}`}
                                                            data-bs-toggle="tab"
                                                            data-bs-target="#nav-001"
                                                            type="button"
                                                            role="tab"
                                                            aria-selected={tab === activeTab ? "true" : "false"}
                                                        >
                                                            {tab}
                                                        </a>
                                                    ))}
                                                </div>
                                            </nav>
                                            <div className="tab-content border bg-light" id="nav-tabContent">
                                                <div className="tab-pane fade active show" id="nav-001" role="tabpanel" aria-labelledby="nav-001-tab">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="card mb-0">
                                                                <div className="card-body">
                                                                    <div className="table-rep-plugin custom-table">
                                                                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                                            {activeTab === "Wallet Transactions" && <AgentWalletTransactionTab agentID={agentID} />}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster />
        </>
    );
};

export default AgentDetails;
