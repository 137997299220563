import React, { useMemo, useState } from "react";
import { ToastContainer } from "react-toastify";
import { Modal } from "react-bootstrap";
import ConfirmationDialoge from "../../Common/ConfirmationDialoge.jsx";
import { Agent_Poker_Session_Details, Agent_Poker_Session_Details_GET, Agent_Poker_Session_History, Agent_Poker_Session_History_POST, Get_Details_Poker_PokerSession_GET, Poker_PokerSession_List_POST } from "../../../api/ApiConst.js";
import PaginationDataTable from "../../Common/PaginationDataTable.jsx";
import dateUtilities from "../../../utilities/DateUtilities.jsx";
import { callGetApi } from "../../../api/ApiCaller.js";

const PokerGameSessionHistory = (props) => {
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, isAuth: true, title: "", subTitle: "", severity: "" });
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [details, setDetails] = useState(null);

    const columns = useMemo(
        () => [
            {
                name: "Session ID",
                selector: (row) => row.sessionId,
                sortable: true,
                reorder: true,
                width: "250px",
            },
            {
                name: "Table",
                selector: (row) => row.tableName + " ( " + row.gameVariation + " )",
                sortable: true,
                reorder: true,
                width: "300px"
            },
            {
                name: "Type",
                selector: (row) => {
                    const backgroundColor = row.privatePublicType === "PUBLIC" ? "green" : "orange";
                    const color = row.privatePublicType === "PUBLIC" ? "white" : "black";
                    return (
                        <div className="privatePublicType" style={{ backgroundColor, color }}>
                            {row.privatePublicType}
                        </div>
                    );
                },
                sortable: true,
                reorder: true,
                width: "130px"
            },
            {
                name: "Player",
                selector: (row) => `${row.playingPlayerCount} / ${row.maxPlayers}`,
                sortable: true,
                reorder: true,
                width: "110px"
            },
            {
                name: "Rake",
                selector: (row) => `${row.rakePercentage.toFixed(2)}% > ${row.totalRake.toFixed(2)}`,
                sortable: true,
                reorder: true,
                width: "120px"
            },
            {
                name: "Blind",
                selector: (row) => row.blinds,
                sortable: true,
                reorder: true,
                width: "120px"
            },
            {
                name: "Buy In",
                selector: (row) => row.buyIn,
                sortable: true,
                reorder: true,
                width: "130px"
            },
            {
                name: "Start Date",
                selector: (row) => dateUtilities.formattedDate(row.startDateTimeStamp, "dd-MM-yyyy hh:mm aa"),
                sortable: true,
                reorder: true,
                width : "200px",
            },
            // {
            //     name: "Speed",
            //     selector: (row) => row.turn,
            //     sortable: true,
            //     reorder: true,
            //     width: "90px"
            // },
            {
                name: "",
                cell: (row) => (
                    <span className="btn btn-primary mx-1 py-1 px-2 font-size-16" onClick={() => handleViewDetails(row)}>
                        <i className="bx bx-show" />
                    </span>
                ),
                sortable: true,
                reorder: true,
                width: "80px"
            },
        ],
        []
    );


    const handleViewDetails = (data) => {
        let url = "";
        if (props.forPlayer) {
            url = Get_Details_Poker_PokerSession_GET.replace('{recordId}', data.sessionId);
        } else if (props.AgentPlayer) {
            url = Agent_Poker_Session_Details_GET.replace('{sessionId}', data.sessionId);
        } else {
            url = Get_Details_Poker_PokerSession_GET.replace('{recordId}', data.sessionId);
        }
        callGetApi(
            url,
            {},
            (response) => {
                setDetails(response.responsePacket);
                setShowDetailsModal(true);
            },
            (error) => {
                console.log(error);
            }
        );
        setShowDetailsModal(true);
    };

    const getImage = (card) => {
        if (card) {
            return `/assets/images/cards/${card.suit}_${card.sequence <= 12 ? card.sequence + 1 : 1}.png`;
        }
        return `/assets/images/cards/CARD.png`;
    };

    const detailsModal = () => {
        return (
            showDetailsModal &&
            details && (
                <Modal className="tablerowmodal modal-min-width" show={showDetailsModal} onHide={() => setShowDetailsModal(false)} size="lg" aria-labelledby="contained-modal-title-vcenter">
                    <Modal.Body>
                        <div className="border-bottom d-flex justify-content-between align-items-center pb-3">
                            <h4 className="m-0">Game Play Details [{"  Session ID > " +
                                details.pokerGameSession?.sessionId}] </h4>

                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowDetailsModal(false)}></button>
                        </div>
                        <div>
                            <h5 className="text-center my-3">
                                {details.pokerGameSession?.gameType +
                                    " > " +
                                    details.pokerGameSession?.gameVariation +
                                    " > " +
                                    details.pokerGameSession?.tableName

                                }
                                <div
                                    className="privatePublicType"
                                    style={{
                                        backgroundColor: details.pokerGameSession.privatePublicType === "PUBLIC" ? "green" : "orange",
                                        color: "white",
                                        width: "auto",
                                        float: "right",
                                        marginBottom: "8px",
                                        // marginTop : "10px"
                                    }}
                                >
                                    {details.pokerGameSession.privatePublicType}
                                </div>
                            </h5>

                            <hr className="mb-2" />

                        </div>

                        <table className="table table-bordered border-dark">
                            <thead>
                                <tr>
                                    <th>Player</th>
                                    <th>Blind</th>
                                    <th>BuyIn</th>
                                    <th>Turn</th>
                                    <th>Rake (%)</th>
                                    <th>Rake</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {details.pokerGameSession?.playingPlayerCount} / {details.pokerGameSession?.maxPlayers}
                                    </td>
                                    <td>{`${details.pokerGameSession?.stackBigBlind?.toFixed(2)} - ${details.pokerGameSession?.stackBigBlind?.toFixed(2)}`}</td>
                                    <td>{`${details.pokerGameSession?.stackMinimumBuyIn?.toFixed(2)} - ${details.pokerGameSession?.stackMaximumBuyIn?.toFixed(2)}`}</td>
                                    <td>{`${details.pokerGameSession?.turnTitle} (${details.pokerGameSession?.turnTurnTime} / ${details.pokerGameSession?.turnGraceTime})`}</td>
                                    <td>{details.pokerGameSession?.rakePercentage.toFixed(2)}</td>
                                    <td>{details.pokerGameSession?.totalRake.toFixed(2)}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="table table-bordered border-dark">
                            <thead>
                                <tr>
                                    <th>Flop</th>
                                    <th>Turn</th>
                                    <th>River</th>
                                    <th>Burn Cards</th>
                                    <th>Win Cards {details.pokerGameSession?.winningHandRanking ? `(${details.pokerGameSession?.winningHandRanking})` : ""}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {details.pokerGameSession?.flopCardsJson !== null && details.pokerGameSession?.flopCardsJson !== undefined && details.pokerGameSession?.flopCardsJson !== "null" ? (
                                            <>
                                                <img className="ms-1" style={{ maxHeight: "60px", width: "auto" }} src={getImage(JSON.parse(details.pokerGameSession?.flopCardsJson)[0])} alt="" />
                                                <img className="ms-1" style={{ maxHeight: "60px", width: "auto" }} src={getImage(JSON.parse(details.pokerGameSession?.flopCardsJson)[1])} alt="" />
                                                <img className="ms-1" style={{ maxHeight: "60px", width: "auto" }} src={getImage(JSON.parse(details.pokerGameSession?.flopCardsJson)[2])} alt="" />
                                            </>
                                        ) : (
                                            <>
                                                <img className="ms-1" style={{ maxHeight: "60px", width: "auto" }} src={getImage(null)} alt="" />
                                                <img className="ms-1" style={{ maxHeight: "60px", width: "auto" }} src={getImage(null)} alt="" />
                                                <img className="ms-1" style={{ maxHeight: "60px", width: "auto" }} src={getImage(null)} alt="" />
                                            </>
                                        )}
                                    </td>
                                    <td>
                                        {details.pokerGameSession?.turnCardJson !== null && details.pokerGameSession?.turnCardJson !== undefined && details.pokerGameSession?.turnCardJson !== "null" ? (
                                            <img style={{ maxHeight: "60px", width: "auto" }} src={getImage(JSON.parse(details.pokerGameSession?.turnCardJson))} alt="" />
                                        ) : (
                                            <img className="ms-1" style={{ maxHeight: "60px", width: "auto" }} src={getImage(null)} alt="" />
                                        )}
                                    </td>
                                    <td>
                                        {details.pokerGameSession?.riverCardJson !== null && details.pokerGameSession?.riverCardJson !== undefined && details.pokerGameSession?.riverCardJson !== "null" ? (
                                            <img style={{ maxHeight: "60px", width: "auto" }} src={getImage(JSON.parse(details.pokerGameSession?.riverCardJson))} alt="" />
                                        ) : (
                                            <img className="ms-1" style={{ maxHeight: "60px", width: "auto" }} src={getImage(null)} alt="" />
                                        )}
                                    </td>
                                    <td>
                                        {details.pokerGameSession?.burnCardsJson !== null && details.pokerGameSession?.burnCardsJson !== undefined && details.pokerGameSession?.burnCardsJson !== "null" ? (
                                            <>
                                                {JSON.parse(details.pokerGameSession?.burnCardsJson)[0] ? (
                                                    <img className="ms-1" style={{ maxHeight: "60px", width: "auto" }} src={getImage(JSON.parse(details.pokerGameSession?.burnCardsJson)[0])} alt="" />
                                                ) : (
                                                    <img className="ms-1" style={{ maxHeight: "60px", width: "auto" }} src={getImage(null)} alt="" />
                                                )}
                                                {JSON.parse(details.pokerGameSession?.burnCardsJson)[1] ? (
                                                    <img className="ms-1" style={{ maxHeight: "60px", width: "auto" }} src={getImage(JSON.parse(details.pokerGameSession?.burnCardsJson)[1])} alt="" />
                                                ) : (
                                                    <img className="ms-1" style={{ maxHeight: "60px", width: "auto" }} src={getImage(null)} alt="" />
                                                )}
                                                {JSON.parse(details.pokerGameSession?.burnCardsJson)[2] ? (
                                                    <img className="ms-1" style={{ maxHeight: "60px", width: "auto" }} src={getImage(JSON.parse(details.pokerGameSession?.burnCardsJson)[2])} alt="" />
                                                ) : (
                                                    <img className="ms-1" style={{ maxHeight: "60px", width: "auto" }} src={getImage(null)} alt="" />
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <img className="ms-1" style={{ maxHeight: "60px", width: "auto" }} src={getImage(null)} alt="" />
                                                <img className="ms-1" style={{ maxHeight: "60px", width: "auto" }} src={getImage(null)} alt="" />
                                                <img className="ms-1" style={{ maxHeight: "60px", width: "auto" }} src={getImage(null)} alt="" />
                                            </>
                                        )}
                                    </td>
                                    <td>
                                        {details.pokerGameSession?.winningHandJson !== null && details.pokerGameSession?.winningHandJson !== undefined && details.pokerGameSession?.winningHandJson !== "null" ? (
                                            <>
                                                <img className="ms-1" style={{ maxHeight: "60px", width: "auto" }} src={getImage(JSON.parse(details.pokerGameSession?.winningHandJson)[0])} alt="" />
                                                <img className="ms-1" style={{ maxHeight: "60px", width: "auto" }} src={getImage(JSON.parse(details.pokerGameSession?.winningHandJson)[1])} alt="" />
                                                <img className="ms-1" style={{ maxHeight: "60px", width: "auto" }} src={getImage(JSON.parse(details.pokerGameSession?.winningHandJson)[2])} alt="" />
                                                <img className="ms-1" style={{ maxHeight: "60px", width: "auto" }} src={getImage(JSON.parse(details.pokerGameSession?.winningHandJson)[3])} alt="" />
                                                <img className="ms-1" style={{ maxHeight: "60px", width: "auto" }} src={getImage(JSON.parse(details.pokerGameSession?.winningHandJson)[4])} alt="" />
                                            </>
                                        ) : (
                                            <>
                                                <img className="ms-1" style={{ maxHeight: "60px", width: "auto" }} src={getImage(null)} alt="" />
                                                <img className="ms-1" style={{ maxHeight: "60px", width: "auto" }} src={getImage(null)} alt="" />
                                                <img className="ms-1" style={{ maxHeight: "60px", width: "auto" }} src={getImage(null)} alt="" />
                                                <img className="ms-1" style={{ maxHeight: "60px", width: "auto" }} src={getImage(null)} alt="" />
                                                <img className="ms-1" style={{ maxHeight: "60px", width: "auto" }} src={getImage(null)} alt="" />
                                            </>
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="table table-bordered border-dark">
                            <thead>
                                <tr>
                                    <th>Player Name</th>
                                    <th>Open</th>
                                    <th>Bet</th>
                                    <th>Rake</th>
                                    <th>Win/Loos</th>
                                    <th>Close</th>
                                    <th>Cards</th>
                                </tr>
                            </thead>
                            <tbody >
                                {details.sessionPlayers.map((data) => (
                                    <tr>
                                        <td>{data.playerVisibleName}</td>
                                        <td>{data.openingBalance.toFixed(2)}</td>
                                        <td>{data.totalBet.toFixed(2)}</td>
                                        <td>{data.totalRake.toFixed(2)}</td>
                                        <td>{data.totalWin.toFixed(2)}</td>
                                        <td>{data.closingBalance.toFixed(2)}</td>
                                        <td>
                                            {data?.inHandCardsJson &&
                                                JSON.parse(data?.inHandCardsJson).map((data) => <img className="ms-1" style={{ maxHeight: "60px", width: "auto" }} src={getImage(data)} alt="" />)}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <table className="table table-bordered border-dark">
                            <thead>
                                <tr>
                                    <th>Player Name</th>
                                    <th>Bet At</th>
                                    <th>Player State</th>
                                    <th>Message</th>
                                    <th>Bet</th>
                                </tr>
                            </thead>
                            <tbody>
                                {details.sessionBets?.map((data) => (
                                    <tr>
                                        <td>{data.playerVisibleName}</td>
                                        <td>{dateUtilities.formattedDate(data?.betAtTimeStamp, "dd-MM-yyyy hh:mm aa")}</td>
                                        <td>{data.playerState}</td>
                                        <td>{data.message}</td>
                                        <td>{data.betAmount.toFixed(2)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Modal.Body>
                </Modal>
            )
        );
    };

    const getDataTableOfSessions = () => {
        if (props.forPlayer) {
            return <PaginationDataTable
                paginationUrl={Poker_PokerSession_List_POST + "/" + props.playerID}
                serverSidePagination={true}
                search={true}
                columns={columns} />;
        } else if (props.AgentPlayer) {
            return <PaginationDataTable
                paginationUrl={Agent_Poker_Session_History_POST.replaceAll("{playerId}", props.playerID)}
                serverSidePagination={true}
                search={true}
                columns={columns} />;
        } else {
            return <PaginationDataTable
                paginationUrl={Poker_PokerSession_List_POST}
                serverSidePagination={true}
                search={true}
                columns={columns} />;
        }
    }

    return (
        <>
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div id="datatable_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                    {
                                        getDataTableOfSessions()
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {detailsModal()}
            <ToastContainer />
            <ConfirmationDialoge confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
        </>
    );
};

export default PokerGameSessionHistory;
