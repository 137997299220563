import WalletTransactions from '../../Common/WalletTransactions';

const PlayerWalletTransactionTab = ({ playerID }) => {
    return (
        <div className='row'>
            <div className='col-12'>
                <WalletTransactions playerOrWallet={'PLAYER'} ID={playerID} />
            </div>
        </div>
    );
};

export default PlayerWalletTransactionTab;
