import React, { useEffect, useState } from "react";
import { getStaticPagesList, getPagesDetails, updatePagesKeyValue } from "../Services/index.js";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import PageHeading from "./Common/PageHeading.jsx";
import { CKEditor } from "ckeditor4-react";

const StaticPages = () => {
    const [list, setList] = useState(null);
    const [pagesList, setPagesList] = useState(null);
    const handleClose = () => setShow(false);
    const handleOpen = () => setShow(true);
    const [show, setShow] = useState(false);
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, isAuth: true, title: "", subTitle: "", severity: "" });

    const getPagesList = () => {
        getStaticPagesList().then((res) => {
            if (res.errorCode === 0) {
                setList(res.responsePacket);
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            }
        });
    };
    const handleEditPages = (data) => {
        getPagesDetails(data).then((res) => {
            if (res.errorCode === 0) {
                handleOpen();
                setPagesList(res?.responsePacket);
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            } else if (res.errorCode === 1) {
                handleFailerToast(res?.message);
            }
        });
    };

    const handleUpdate = (pageType) => {
        let request = {
            pageHtmlContent: pagesList?.pageHtmlContent,
        };
        updatePagesKeyValue(pageType, request).then((res) => {
            if (res.errorCode === 0) {
                toast("👏" + res.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                handleClose();
                getPagesList();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            }
        });
    };

    const handleForceLogout = (data) => {
        toast("🙁" + data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setConfirmDialog({
            isOpen: true,
            isAuth: true,
            title: "You Are LoggedIn on Another Screen! to Countinue Logout and Login Again!!",
            onConfirm: () => logout(),
        });
    };

    const logout = () => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        const clearCookies = document.cookie.split(";").forEach((cookie) => (document.cookie = cookie.replace(/^ +/, "").replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`)));
        window.location.reload(1);
    };

    const handleFailerToast = (data) => {
        toast("🙁" + data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    useEffect(() => {
        getPagesList();
    }, []);

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <PageHeading heading={"Static Pages"} />
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Static Pages Settings</h4>
                                    <div className="table-responsive">
                                        <table className="table mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Key</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {list &&
                                                    list.map((data, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>{data}</td>
                                                                <td>
                                                                    <a onClick={() => handleEditPages(data)} className="btn btn-primary">
                                                                        Edit
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="tablerowmodal" size="lg" show={show} onHide={() => handleClose()} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Body className="">
                    <div className="modal-header border-bottom-0">
                        <h4>{pagesList?.pageType}</h4>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleClose()}></button>
                    </div>
                    <div className="card-body">
                        <CKEditor
                            initData={pagesList?.pageHtmlContent}
                            onChange={(e) => {
                                const data = e.editor?.getData().trim();
                                setPagesList({
                                    ...pagesList,
                                    pageHtmlContent: data,
                                });
                            }}
                        />
                        <div>
                            <a className="btn btn-primary w-md" onClick={() => handleUpdate(pagesList?.pageType)}>
                                Update
                            </a>
                            &nbsp; &nbsp;
                            <a className="btn btn-primary w-md" onClick={() => handleClose()}>
                                Cancel
                            </a>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default StaticPages;
