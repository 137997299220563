import React, { useCallback, useEffect, useMemo, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import Select, { components } from "react-select";
import ConfirmationDialoge from "./Common/ConfirmationDialoge";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { Admin_Lucky_Wheel_List_POST, All_Deleted_Admin_Lucky_Wheel_List_POST, ItemTypeKeyList_GET, WheelTypeKeyList_GET } from "../api/ApiConst";
import PaginationDataTable from "./Common/PaginationDataTable";
import PageHeading from "./Common/PageHeading";
import CustomButton from "./Common/CustomButton";
import AddIcon from "@mui/icons-material/Add";
import CheckboxButton from "./Common/CheckboxButton";
import { activateLuckyWheel, addLuckyWheel, deactivateLuckyWheel, deleteLuckyWheel, getLuckyWheelDetails, reviveLuckyWheel, updateLuckyWheelDetails } from "../Services/MasterServices/LuckyWheelService";
import { callGetApi, showWarningToast ,showSuccessToast,showFailureToast} from "../api/ApiCaller";
import $ from 'jquery';


const AdminLuckyWheel = () => {

    const handleForceLogout = (data) => {
        toast("🙁" + data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setConfirmDialog({
            isOpen: true,
            isAuth: true,
            title: "You Are LoggedIn on Another Screen! to Countinue Logout and Login Again!!",
            onConfirm: () => logout(),
        });
    };
    const logout = () => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        const clearCookies = document.cookie.split(";").forEach((cookie) => (document.cookie = cookie.replace(/^ +/, "").replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`)));
        window.location.reload(1);
    };

    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false, isAuth: true, title: "", subTitle: "", severity: "",
    });
    const [wheelTypeList, setWheelTypeList] = useState([]);
    const [itemTypeList, setItemTypeList] = useState([]);
    const [selectedImage, setSelectedImage] = useState("");
    const [assetsImageFormData, setAssetsImageFormData] = useState(null);
    const [id, setId] = useState(null);
    const [bodyFormFileData, setbodyFormFileData] = useState("");
    const daysKeyValueList = [
        { label: "1 Hour", value: 60 },
        { label: "2 Hour", value: 120 },
        { label: "3 Hour", value: 180 },
    ];
    const getWheelTypeKeyList = () => {
        callGetApi(WheelTypeKeyList_GET, {}, (response) => {
            const res = response.responsePacket;
            let typeList = res.map((type, index) => {
                return { label: type, value: type }
            });
            setWheelTypeList(typeList);
        }, (error) => { }
        );
    }

    const getItemTypeKeyList = () => {
        callGetApi(ItemTypeKeyList_GET, {}, (response) => {
            const res = response.responsePacket;
            let typeList = res.map((type, index) => {
                return { label: type, value: type }
            });
            setItemTypeList(typeList);
        }, (error) => { }
        );
    }

    useEffect(() => {
        getWheelTypeKeyList();
        getItemTypeKeyList();
    }, []);

    const itemRow = {
        count: 0,
        imageUrl: "",
        itemType: null,
        occurrenceProbability: 0
    };

    const [record, setRecord] = useState({
        id: null,
        wheelTypeEnum: null,
        spinAfterEvery: 0,
        itemList: [itemRow]
    });

    const [drawCount, setDrawCount] = useState(0);
    const handleRefresh = () => setDrawCount((prev) => prev + 1);
    const [viewDeletedList, setViewDeletedList] = useState(false);
    const handleToggleDeletedView = () => setViewDeletedList(!viewDeletedList);

    const [show, setShow] = useState({
        addWheelForm: false,
    });

    const showAddWheelForm = () => {
        setShow({ ...show, addWheelForm: true });
    };

    const hideAddWheelForm = () => {
        setShow({ ...show, addWheelForm: false });
        clearForm();
    };

    const clearForm = () => {
        setRecord({
            id: null,
            wheelTypeEnum: null,
            spinAfterEvery: 0,
            itemList: [itemRow]
        });
    };

    const addNewItem = (e) => {
        let iList = record.itemList;
            iList.push(itemRow);
            setRecord((prev) => ({ ...prev, itemList: iList }));
      };

    const deleteItem = (index) => {
        let iList = record.itemList;
        iList.splice(index, 1);
        setRecord((prev) => ({ ...prev, itemList: iList }));
    }

    const handleSelectChange = (fieldName, selectedValue) => {
        setRecord({ ...record, [fieldName]: selectedValue });
    }

    const handleItemSelectChange = (fieldName, selectedValue, index) => {
        let iList = record.itemList;
        iList[index][fieldName] = selectedValue;
        setRecord((prev) => ({ ...prev, itemList: iList }));
    }

    const handleItemInputChange = (event, index) => {
        let { name, value } = event.target;
        let iList = record.itemList;
        iList[index][name] = value;
        setRecord((prev) => ({ ...prev, itemList: iList }));
    };

    const handleAddUpdate = (e) => {
        if (record.itemList.length < 2) {
            showWarningToast("Please add at least two items.");
            return;
        }
    
        let tRecord = JSON.parse(JSON.stringify(record));

        if (tRecord.spinAfterEvery !== null && tRecord.spinAfterEvery !== undefined) {
            tRecord.spinAfterEvery = tRecord.spinAfterEvery.value;
        } else {
            showWarningToast("Please provide Spin After Every.");
            return;
        }

        if (tRecord.wheelTypeEnum !== null && tRecord.wheelTypeEnum !== undefined) {
            tRecord.wheelTypeEnum = tRecord.wheelTypeEnum.value;
        } else {
            showWarningToast("Please provide Wheel Type.");
            return;
        }
        if (tRecord.itemList.some(item => item.imageUrl === null)) {
            showWarningToast("Please provide images for all items.");
            return;
        }
        // Data Conversion to Send to the Server.

        tRecord.itemList.find((item) => {
            if (item.itemType !== null && item.itemType !== undefined) {
                item.itemType = item.itemType.value
            } else {
                item.itemType = null;
            }
        });

        // Validation
        for (let i = 0; i < tRecord.itemList.length; i++) {
            if (tRecord.itemList[i].count === "" || tRecord.itemList[i].count === null) {
                showWarningToast("Please provide count.");
                return;
            }
             else if (tRecord.itemList[i].itemType === 0 || tRecord.itemList[i].itemType == null) {
                showWarningToast("Please provide itemType.");
                return;
            }
             else if (tRecord.itemList[i].occurrenceProbability === 0 || tRecord.itemList[i].occurrenceProbability === null) {
                showWarningToast("Please provide occurrenceProbability.");
                return;
            }
        }

        if (tRecord.id !== null) {
            // Update
            updateLuckyWheelDetails(tRecord.id, tRecord).then((res) => {
                if (res.errorCode === 0) {
                    showSuccessToast(res?.message);
                    handleRefresh();
                    hideAddWheelForm();
                } else if (res.message === "Unauthorised Access") {
                    handleForceLogout(res.message);
                } else {
                    showFailureToast(res?.message);
                }
            })
        } else {
            // Create
            addLuckyWheel(tRecord).then((res) => {
                if (res.errorCode === 0) {
                    showSuccessToast(res?.message);
                    handleRefresh();
                    hideAddWheelForm();
                    clearForm();
                } else if (res.message === "Unauthorised Access") {
                    handleForceLogout(res.message);
                } else {
                    showFailureToast(res?.message);
                }
            });
        }
    };

    const handleEditRecord = (id) => {
        setShow({ ...show, addWheelForm: true });
        getLuckyWheelDetails(id).then((res) => {
            if (res.errorCode === 0) {
                let luckWheelDetails = res.responsePacket;
                let _wheelType = wheelTypeList.find((data) => data.value === luckWheelDetails.wheelTypeEnum);
                let _daysType = daysKeyValueList.find((data) => data.value === luckWheelDetails.spinAfterEvery);
                let iList = luckWheelDetails.itemList.map((item) => ({
                    imageUrl: item.imageUrl,
                    count: item.count,
                    itemType: itemTypeList.find((data) => data.value === item.itemType),
                    occurrenceProbability: item.occurrenceProbability,
                }));
                let tRecord = {
                    id: luckWheelDetails.recordId,
                    wheelTypeEnum: _wheelType,
                    spinAfterEvery: _daysType,
                    itemList: iList
                };
                setRecord(tRecord);
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            }
        });
    };

    const handleDeleteRecord = (id) => {
        setConfirmDialog({
            isOpen: true,
            title: "Are You Sure? You want to Delete this record",
            onConfirm: () => {
                setConfirmDialog({
                    ...confirmDialog,
                    isOpen: false,
                });

                deleteLuckyWheel(id).then((res) => {
                    if (res.errorCode === 0) {
                        showSuccessToast(res?.message);
                        handleRefresh();
                    } else if (res.message === "Unauthorised Access") {
                        handleForceLogout(res.message);
                    } else {
                        showFailureToast(res.message);
                    }
                });
            }
        });
    };

    const handleReviveRecord = (id) => {
        setConfirmDialog({
            isOpen: true,
            title: "Are You Sure? You want to Revive this record",
            onConfirm: () => {
                setConfirmDialog({
                    ...confirmDialog,
                    isOpen: false,
                });
                reviveLuckyWheel(id).then((res) => {
                    if (res.errorCode === 0) {
                        showSuccessToast(res?.message);
                        handleRefresh();
                    } else if (res.message === "Unauthorised Access") {
                        handleForceLogout(res.message);
                    } else {
                        showFailureToast(res.message);
                    }
                });
            }
        });
    }

    const handleDeactivateRecord = (id) => {
        setConfirmDialog({
            isOpen: true,
            title: "Are You Sure? You want to Deactivate this wheel",
            onConfirm: () => {
                setConfirmDialog({
                    ...confirmDialog,
                    isOpen: false,
                });
                deactivateLuckyWheel(id).then((res) => {
                    if (res.errorCode === 0) {
                        showSuccessToast(res?.message);
                        handleRefresh();
                    } else if (res.message === "Unauthorised Access") {
                        handleForceLogout(res.message);
                    } else {
                        showFailureToast(res?.message);
                    }
                });
            }
        });
    };

    const handleActivateRecord = (id) => {
        setConfirmDialog({
            isOpen: true,
            title: "Are You Sure? You want to Activate this wheel",
            onConfirm: () => {
                setConfirmDialog({
                    ...confirmDialog,
                    isOpen: false,
                });
                activateLuckyWheel(id).then((res) => {
                    if (res.errorCode === 0) {
                        showSuccessToast(res?.message);
                        handleRefresh();
                    } else if (res.message === "Unauthorised Access") {
                        handleForceLogout(res.message);
                    } else {
                        showFailureToast(res?.message);
                    }
                });
            }
        });
    };

    const handleImageClick = (e) => {
        $(e.target.parentElement).find("input[type='file']").trigger("click");
    }

    const addItemRow = (item, index) => {
        const handleImageUpload = (e) => {
            var input = e.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.onload = function (ev) {
                    setRecord((prev) => {
                        const newItemList = [...prev.itemList];
                        newItemList[index] = { ...newItemList[index], imageUrl: ev.target.result };
                        return { ...prev, itemList: newItemList };
                    });
                };
                reader.readAsDataURL(input.files[0]);
            }
            let image = e.target.files[0];
            let bodyFormData = new FormData();
            bodyFormData.append("file", image);
            setbodyFormFileData(bodyFormData);
        };
        return <>
            <div className="row item-card mb-3" key={index}>
                <div className="col-3 mb-3">
                    <img
                        onClick={handleImageClick}
                        src={item.imageUrl != "" ? item.imageUrl : "https://th.bing.com/th/id/OIP.vx0QOVHGpMq7Furxtzu6KgHaHa?pid=ImgDet&amp;rs=1"}
                        id="imageUrl"
                        style={{
                            height: "63px",
                            width: "100%",
                            objectFit: "contain",
                            border: "1px solid #ced4da",
                            padding: "10px",
                            borderRadius: "4px",
                        }}
                    />
                    <input type="file" className="form-control display-none"   accept="image/*" id="formrow-inputZip" onChange={(e) => handleImageUpload(e)} />
                </div>
                <div className="col-3 mb-3">
                    <label htmlFor="formrow-password-input" className="form-label">Item Type</label>
                    <Select
                     isSearchable = {false}
                     options={itemTypeList} 
                     name="itemType"
                        value={item.itemType}
                        onChange={(selectedValue) => handleItemSelectChange("itemType", selectedValue, index)} />
                </div>
                <div className="col-3 mb-3">
                    <label htmlFor="formrow-password-input" className="form-label">Count</label>
                    <input type="number" className="form-control" name="count" min={0}
                        value={item.count} onChange={(event) => handleItemInputChange(event, index)} />
                </div>
                <div className="col-3 mb-3">
                    <div className="d-flex align-items-center">
                        <div className="me-2">
                            <label htmlFor="formrow-email-input" className="form-label">
                                Probability
                            </label>
                            <input type="number" className="form-control" min={0} max={1}
                                value={item.occurrenceProbability} name="occurrenceProbability"
                                onChange={(event) => handleItemInputChange(event, index)}
                            />
                        </div>
                        <Button onClick={() => deleteItem(index)} style={{ marginTop: "28px" }}>
                            <i className="bx bx-trash" />
                        </Button>
                    </div>
                </div>
            </div>
        </>;
    }

    const addWheelModal = () => {
        return (
            <Modal className="tablerowmodal" show={show.addWheelForm} onHide={() => hideAddWheelForm()} size="lg" aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header>
                    <h4>{record.id !== null ? "Edit" : "Add"} Lucky Wheel</h4>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => hideAddWheelForm()} ></button>
                </Modal.Header>
                <Modal.Body className="wheel-scroll">
                    <div className="card-body" > 
                        <form>
                            <div className="row mb-3">
                                <div className="col-6">
                                    <label>
                                        Spin After Every
                                    </label>
                                    <Select
                                        isSearchable = {false}
                                        options={daysKeyValueList}
                                        name="spinAfterEvery"
                                        value={record.spinAfterEvery}
                                        onChange={(selectedValue) => handleSelectChange("spinAfterEvery", selectedValue)} />
                                </div>
                                <div className="col-6">
                                    <label>Wheel Type</label>
                                    <Select
                                    isSearchable = {false}
                                     options={wheelTypeList} 
                                     name="wheelTypeEnum"
                                        value={record.wheelTypeEnum}
                                        onChange={(selectedValue) => handleSelectChange("wheelTypeEnum", selectedValue)} />
                                </div>
                            </div>
                            <div className="row align-items-center mb-3">
                                <div className="col-6">
                                    Lucky Wheel Items
                                </div>
                                <div className="col-6 text-end">
                                    <a className="btn border btn-default addbtn" style={{ textAlign: "right" }} onClick={addNewItem}>
                                        <AddIcon style={{ color: "blue" }} /> Add New Item
                                    </a>
                                </div>
                            </div>

                            <div className="row mb-3 justify-content-center">
                                {
                                    record.itemList.map((item, index) => {
                                        return addItemRow(item, index);
                                    })
                                }
                            </div>
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <a className="btn btn-primary w-md" onClick={handleAddUpdate} >
                            {record.id !== null ? "Update" : "Add"}
                        </a>
                        &nbsp; &nbsp;
                        <a onClick={() => hideAddWheelForm()} className="btn btn-primary w-md">
                            Cancel
                        </a>
                    </div>
                </Modal.Footer>
            </Modal >
        );
    };

    const columns = useMemo(
        () => [{
            id: "status",
            name: "",
            selector: (row) => {
                if (row.active) {
                    return <div className="led-green" title="Active"></div>;
                } else {
                    return <div className="led-red" title="Not Active"></div>;
                }
            },
            width: "60px",
            sortable: false,
        }, {
            name: "Wheel Type",
            selector: (row) => row.wheelTypeEnum,
            sortable: true,
            wrap: true,
            width: "200px",
        }, {
            name: "Spin After Every[Minutes]",
            selector: (row) => row.spinAfterEvery,
            sortable: true,
            wrap: true,
            width: "300px",
        }, 
        {
            name: "Items",
            cell: (row) =>  (row.itemList?.map((item) =><div className="itemList d-block w-100">
            <div className="w-100 d-flex align-items-center justify-content-between p-2">
                <div className="itemImg"><img src={item.imageUrl} alt="" /></div>
                <div className="d-flex gap-3">
                <span className="itemCount">{item.count}</span>
                <span className="itemType">{item.itemType}</span>
                </div>
                <div className="itemOccurrenceProbability">{(item.occurrenceProbability).toFixed(4)}</div>
            </div></div>)),
            sortable: true,
            width:"500px",
            style:{display:"block",},
         
        },
         {
            name: "Actions",
            cell: (row) => (
                <div className="dropdown">
                    <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                        <KeyboardArrowDownOutlinedIcon />
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                        {
                            !viewDeletedList && (
                                <>
                                    <li onClick={() => handleEditRecord(row.recordId)} >
                                        <button className="dropdown-item" type="button"> Edit </button>
                                    </li>
                                    <li onClick={() => handleDeleteRecord(row.recordId)}>
                                        <button className="dropdown-item" type="button">
                                            Delete
                                        </button>
                                    </li>
                                    {row.active ?
                                        <li onClick={() => handleDeactivateRecord(row.recordId)}>
                                            <button className="dropdown-item" type="button">
                                                Deactivate
                                            </button>
                                        </li> :
                                        <li onClick={() => handleActivateRecord(row.recordId)}>
                                            <button className="dropdown-item" type="button">
                                                Activate
                                            </button>
                                        </li>
                                    }
                                </>
                            )
                        }
                        {
                            viewDeletedList && (
                                <li onClick={() => handleReviveRecord(row.recordId)}>
                                    <button className="dropdown-item" type="button">
                                        Revive
                                    </button>
                                </li>
                            )
                        }
                    </ul>
                </div >
            ),
            sortable: true,
            right: true,
        }],
        [drawCount, viewDeletedList, wheelTypeList, itemTypeList]);

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <PageHeading heading="Lucky-Wheel" />
                    <div className="card">
                        <div className="d-flex justify-content-end" style={{ position: "absolute", top: "22px", left: "35px", zIndex: 100 }}>
                            <div style={{ width: "200px" }}>
                                <CustomButton handleClick={showAddWheelForm} title="Add Lucky Wheel" iconClass="bx bx-plus" buttonClass="createButton" />
                            </div>
                            <div style={{ width: "200px" }}></div>
                        </div>
                        <div className="card-body">
                            <PaginationDataTable
                                paginationUrl={viewDeletedList ? All_Deleted_Admin_Lucky_Wheel_List_POST : Admin_Lucky_Wheel_List_POST}
                                serverSidePagination={true}
                                search={true}
                                columns={columns}
                                additionalClassName={"agentSearchInput"}
                            />
                            <CheckboxButton title="Deleted List" handleClick={handleToggleDeletedView} additionalClass="deletedBtn ml-auto" />
                        </div>
                    </div>
                </div>
            </div>
            {addWheelModal()}
            <ToastContainer />
            <ConfirmationDialoge confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
        </>
    );
};

export default AdminLuckyWheel;
