import React from 'react';
import PokerGameSessionHistory from './PokerGameSessionHistory';
import PageHeading from '../../Common/PageHeading';

const AdminPokerGameSessionHistory = () => {
    return (
        <div className='page-content'>
            <div className='container-fluid'>
                <PageHeading heading='Poker Game Session History' />
                <div className='card'>
                    <div className='card-body'>
                        <PokerGameSessionHistory />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminPokerGameSessionHistory;
