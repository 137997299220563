import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import {
    addNewSnackLadderAssets,
    getSnackLadderList,
    getSnakeLadderDetails,
    updateSnakeLadderDetails,
    deleteSnakeLadderRecord,
    activateSnakeLadderRecord,
    deactivateSnakeLadderRecord,
    uploadFile,
} from "../../../Services/index.js";
import { toast, ToastContainer } from "react-toastify";
import ConfirmationDialoge from "../../Common/ConfirmationDialoge.jsx";
import Select from "react-select";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import $ from "jquery";
import PageHeading from "../../Common/PageHeading.jsx";
import PaginationDataTable from "../../Common/PaginationDataTable.jsx";
import { All_Snake_Ladder_Asset_List_POST } from "../../../api/ApiConst.js";

const Options = [
    {
        value: "SNAKE",
        label: "Snake",
    },
    {
        value: "LADDER",
        label: "Ladder",
    },
    {
        value: "BOARD",
        label: "Board",
    },
];

const SnakeLadderAssets = () => {
    const [type, setType] = useState(null);
    const [rows, setRows] = useState([]);
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, isAuth: true, title: "", subTitle: "", severity: "" });
    const [edit, setEdit] = useState(false);
    const [number, setNumber] = useState({
        fromNumber: "",
        toNumber: "",
    });
    const [selectedImage, setSelectedImage] = useState("");
    const [assetsImageFormData, setAssetsImageFormData] = useState(null);
    const [id, setId] = useState(null);
    const handleSelectOptions = (e) => {
        if (e.value === "BOARD") {
            $(".fromNumber").css("display", "none");
            $(".toNumber").css("display", "none");
        } else {
            $(".fromNumber").css("display", "block");
            $(".toNumber").css("display", "block");
        }
        setType(e);
    };
    const [imageURL, setImageURL] = useState(null);
    const [bodyFormFileData, setbodyFormFileData] = useState("");
    const [drawCount, setDrawCount] = useState(0);

    const handleRefresh = () => setDrawCount((prev) => prev + 1);

    const handleClearForm = () => {
        setNumber({
            fromNumber: "",
            toNumber: "",
        });
        setType(null);
        setSelectedImage("");
        $("#formrow-inputZip").val("");
        $("#assetsImageInput").val("");
        $("#configform")[0].reset();
    };

    const handleEditRow = (id) => {
        setEdit(true);
        setId(id);
        getSnakeLadderDetails(id).then((res) => {
            if (res.errorCode === 0) {
                let details = res.responsePacket;
                let dict = {
                    value: details.assetsType,
                    label: details.assetsType,
                };
                setType(dict);

                $("#imageId").val(res.responsePacket.recordId);

                $("#assetsImageInput").val(details.imageUrl);

                setNumber({
                    fromNumber: details.fromNumber,
                    toNumber: details.toNumber,
                });

                setSelectedImage(details.imageUrl);
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            }
        });
    };

    const columns = useMemo(
        () => [
            {
                id: "status",
                name: "",
                selector: (row) => {
                    if (row.active) {
                        return <div className="led-green" title="Active"></div>;
                    } else {
                        return <div className="led-red" title="Not Active"></div>;
                    }
                },
                width: "60px",
                sortable: false,
            },
            {
                name: "Image",
                selector: (row) => <img src={row.imageUrl} style={{ width: "75px", height: "75px", objectFit: "scale-down" }} />,
                sortable: true,
                reorder: true,
            },
            {
                name: "FromNumber",
                selector: (row) => row.fromNumber,
                sortable: true,
                reorder: true,
            },
            {
                name: "ToNumber",
                selector: (row) => row.toNumber,
                sortable: true,
                right: true,
                reorder: true,
            },
            {
                name: "Type",
                selector: (row) => row.assetsType,
                sortable: true,
                right: true,
                reorder: true,
            },
            {
                name: "Actions",
                cell: (row) => (
                    <div>
                        <div className="dropdown">
                            <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                <KeyboardArrowDownOutlinedIcon />
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                <li onClick={() => handleEditRow(row.recordId)}>
                                    <button className="dropdown-item" type="button">
                                        Edit
                                    </button>
                                </li>
                                <li
                                    onClick={() =>
                                        setConfirmDialog({
                                            isOpen: true,
                                            title: "Are Your Sure? Your want to delete this record",
                                            onConfirm: () => handleDeleteRow(row.recordId),
                                        })
                                    }
                                >
                                    <button className="dropdown-item" type="button">
                                        Delete
                                    </button>
                                </li>
                                {row.active ? (
                                    <li
                                        onClick={() =>
                                            setConfirmDialog({
                                                isOpen: true,
                                                title: "Are Your Sure? Your want to Deactivate this player",
                                                onConfirm: () => handleDeactivateRow(row.recordId),
                                            })
                                        }
                                    >
                                        <button className="dropdown-item" type="button">
                                            Deactivate
                                        </button>
                                    </li>
                                ) : (
                                    <li
                                        onClick={() =>
                                            setConfirmDialog({
                                                isOpen: true,
                                                title: "Are Your Sure? Your want to Activate this player",
                                                onConfirm: () => handleActivateRow(row.recordId),
                                            })
                                        }
                                    >
                                        <button className="dropdown-item" type="button">
                                            Activate
                                        </button>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                ),
                sortable: true,
                right: true,
                reorder: true,
            },
        ],
        [drawCount]
    );

    const handleDeleteRow = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        deleteSnakeLadderRecord(id).then((res) => {
            if (res.errorCode === 0) {
                toast("👏" + res.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                handleRefresh();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            }
        });
    };

    const handleDeactivateRow = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        deactivateSnakeLadderRecord(id).then((res) => {
            if (res.errorCode === 0) {
                toast("👏" + res.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                handleRefresh();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            }
        });
    };

    const handleActivateRow = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        activateSnakeLadderRecord(id).then((res) => {
            if (res.errorCode === 0) {
                toast("👏" + res.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                handleRefresh();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            }
        });
    };

    const handleImageUpload = (e) => {
        // e.preventDefault();
        var input = e.target;
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = function (ev) {
                $("#assetImageImg").attr("src", ev.target.result);
                setAssetsImageFormData(ev.target.result);
            };
            reader.readAsDataURL(input.files[0]);
        }
        let image = e.target.files[0];
        let bodyFormData = new FormData();
        bodyFormData.append("file", image);
        setbodyFormFileData(bodyFormData);

        // uploadFile(bodyFormData).then((res) => {
        //   if (res.errorCode === 0) {
        //     setImageURL(res.responsePacket)
        //     setSelectedImage({
        //         ...selectedImage,
        //         "ImageUrl" : res.responsePacket
        //       })
        //   } else {
        //     toast('🙁' + res.message, {
        //       position: "top-right",
        //       autoClose: 3000,
        //       hideProgressBar: false,
        //       closeOnClick: true,
        //       pauseOnHover: true,
        //       draggable: true,
        //       progress: undefined,
        //     });
        //   }
        // })
    };

    const handleForceLogout = (data) => {
        toast("🙁" + data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setConfirmDialog({
            isOpen: true,
            isAuth: true,
            title: "You Are LoggedIn on Another Screen! to Countinue Logout and Login Again!!",
            onConfirm: () => logout(),
        });
    };

    const logout = () => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        const clearCookies = document.cookie.split(";").forEach((cookie) => (document.cookie = cookie.replace(/^ +/, "").replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`)));
        window.location.reload(1);
    };

    const handleSuccessToast = (data) => {
        toast("👏" + data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const validateAddUpdateAssets = () => {
        let assetsType = type?.label;

        if (assetsType === null || assetsType === undefined) {
            toast.warn("Please Select Type as Snack Or Ladder ", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return false;
        }

        if (assetsType === "Board") {
            // we do not need from and to number
        } else {
            let fNumber = Number(number?.fromNumber);
            let tNumber = Number(number?.toNumber);

            if (assetsType === "Ladder") {
                if (parseInt(fNumber) >= parseInt(tNumber)) {
                    toast.warn("In Ladder To-Number should be grater then From-Number ", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    return false;
                }
            } else if (assetsType === "Snake") {
                if (parseInt(fNumber) <= parseInt(tNumber)) {
                    toast.warn("In Snake From-Number should be grater then To-Number ", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    return false;
                }
            }
        }

        let inputTag = $("#formrow-inputZip").val();
        if (!(inputTag != "" || ($("#imageId").val() != "" && $("#assetsImageInput").val() != ""))) {
            toast.warn("Please select image first.", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return false;
        }
        return true;
    };
    const handleSave = (e) => {
        e.preventDefault();

        if (validateAddUpdateAssets(e)) {
            let inputTag = $("#formrow-inputZip").val();
            if (inputTag != "") {
                uploadFile(bodyFormFileData).then((res) => {
                    if (res.errorCode === 0) {
                        $("#assetsImageInput").val(res.responsePacket);
                        submitForm();
                    }
                });
            } else {
                submitForm();
            }
        }
    };

    const submitForm = () => {
        let request = {
            assetsType: type?.label,
            imageUrl: $("#assetsImageInput").val(),
            fromNumber: Number(number?.fromNumber),
            toNumber: Number(number?.toNumber),
        };

        if ($("#imageId").val() != "") {
            updateSnakeLadderDetails(id, request).then((res) => {
                if (res.errorCode === 0) {
                    toast("👏" + res.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    handleRefresh();
                    handleClearForm();
                } else if (res.message === "Unauthorised Access") {
                    handleForceLogout(res.message);
                }
            });
        } else {
            addNewSnackLadderAssets(request).then((res) => {
                if (res.errorCode === 0) {
                    handleSuccessToast(res?.message);
                    handleRefresh();
                    handleClearForm();
                } else if (res.message === "Unauthorised Access") {
                    handleForceLogout(res.message);
                }
            });
        }
    };

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <PageHeading heading={"Snake Ladder Settings"} />
                    <div className="row">
                        <div className="col-xl-5">
                            <div className="card">
                                <div className="card-body">
                                    <form id="configform">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="mb-3">
                                                    <label htmlFor="formrow-inputState" className="form-label">
                                                        Type
                                                    </label>
                                                    <Select options={Options} value={type} onChange={(e) => handleSelectOptions(e)} />
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="mb-3">
                                                    <label htmlFor="formrow-inputZip" className="form-label">
                                                        Upload Image
                                                    </label>
                                                    <input type="hidden" id="imageId" />
                                                    <input type="hidden" id="assetsImageInput" />
                                                    <img
                                                        src={selectedImage != "" ? selectedImage : "https://th.bing.com/th/id/OIP.vx0QOVHGpMq7Furxtzu6KgHaHa?pid=ImgDet&amp;rs=1"}
                                                        id="assetImageImg"
                                                        style={{
                                                            height: "130px",
                                                            width: "100%",
                                                            objectFit: "contain",
                                                            border: "1px solid #7c7979",
                                                            padding: "10px",
                                                        }}
                                                    />
                                                    <input type="file" className="form-control" accept="image/*" id="formrow-inputZip" onChange={(e) => handleImageUpload(e)} />
                                                </div>
                                                {/* {edit ? <div><img src={selectedImage.ImageUrl} style={{height: '60px',paddingTop: '7px'}}/></div> : ""} */}
                                            </div>
                                        </div>
                                        <div className="row fromNumber">
                                            <div className="col-md-12">
                                                <div className="mb-3">
                                                    <label htmlFor="formrow-email-input" className="form-label">
                                                        From Number
                                                    </label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        id="fromNumber"
                                                        name="fromNumber"
                                                        placeholder="Enter From Number"
                                                        value={number.fromNumber}
                                                        onChange={(e) => setNumber({ ...number, fromNumber: e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12 toNumber">
                                                <div className="mb-3">
                                                    <label htmlFor="formrow-password-input" className="form-label">
                                                        To Number
                                                    </label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        id="toNumber"
                                                        name="toNumber"
                                                        placeholder="Enter To Number"
                                                        value={number.toNumber}
                                                        onChange={(e) => setNumber({ ...number, toNumber: e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <button type="submit" className="btn btn-primary w-md" onClick={(e) => handleSave(e)}>
                                                Save
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-7">
                            <div className="card">
                                <div className="card-body">
                                    <PaginationDataTable drawCount={drawCount} paginationUrl={All_Snake_Ladder_Asset_List_POST} serverSidePagination={true} search={true} columns={columns} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
            <ConfirmationDialoge confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
        </>
    );
};

export default SnakeLadderAssets;
