import {
    Add_New_Admin_System_Message_POST,
    Admin_System_Message_Activate_PUT,
    Admin_System_Message_Deactivate_PUT,
    Admin_System_Message_GET,
    Admin_System_Message_Revive__PUT,
    Delete_Admin_System_Message_DELETE,
    Update_Admin_System_Message_PUT
} from "../../api/ApiConst.js";
import { SYSTEM_ERROR } from "../Config/CONSTANT.js";
import axios from "axios";
import Cookies from "universal-cookie";

const cook = new Cookies().get("loginData");

const headersData = {
    "content-type": "application/json",
    Accept: "application/json",
    Authorization: cook != undefined ? cook.accessToken : "",
};


export const addSystemMessage = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(Add_New_Admin_System_Message_POST, data, { headers: headersData })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in SystemMessage axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const getSystemMessageDetails = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(Admin_System_Message_GET.replaceAll("{recordId}", id), { headers: headersData })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getSystemMessageDetails axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const updateSystemMessageDetails = (id, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(Update_Admin_System_Message_PUT.replaceAll("{recordId}", id), data, { headers: headersData })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in updateSystemMessageDetails axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const deleteSystemMessage = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .delete(Delete_Admin_System_Message_DELETE.replaceAll("{recordId}", id), { headers: headersData })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in SystemMessage axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const reviveSystemMessage = (id, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(Admin_System_Message_Revive__PUT.replaceAll("{recordId}", id), data, { headers: headersData })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in ReviveSystemMessage axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const activateSystemMessage = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(Admin_System_Message_Activate_PUT.replaceAll("{recordId}", id), {}, { headers: headersData })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in ActivateSystemMessage axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const deactivateSystemMessage = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(Admin_System_Message_Deactivate_PUT.replaceAll("{recordId}", id), {}, { headers: headersData })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in Deactivate SystemMessageaxios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
