import { Add_Admin_VoucherTemplate_POST, Add_Agent_AgentTemplate_POST, Add_Agent_PlayerTemplate_POST, Add_Agent_VoucherTemplate_POST, Agent_Activate_AgentTemplate_PUT, Agent_Activate_PlayerTemplate_PUT, Agent_Change_Commission_AgentTemplate_POST, Agent_Change_Commission_AgentTemplate_PUT, Agent_ClaimBack_POST, Agent_Deactivate_AgentTemplate_PUT, Agent_Deactivate_PlayerTemplate_PUT, Agent_Player_ClaimBack_PUT, Agent_Player_SendOut_PUT, Agent_Revive_AgentTemplate_PUT, Agent_Revive_PlayerTemplate_PUT, Agent_SendOut_POST, All_Agent_VoucherTemplate_List_POST, Delete_Agent_AgentTemplate_Delete, Delete_Agent_PlayerTemplate_Delete, Get_Details_Agent_AgentTemplate_GET, Get_Details_Agent_PlayerTemplate_GET, Update_Agent_AgentTemplate_PUT, Update_Agent_PlayerTemplate_PUT, Agent_Reset_Password_PUT, Get_Admin_Assign_Agent_ListKeys_GET, Add_Change_Agent_Player, Player_GetUnVerifiedNumbers_GET } from "../../api/ApiConst.js";
import { EXCEL_FILE_UPLOAD_URL, PLAYER_URL } from "../CONSTANT.js";
import { SYSTEM_ERROR } from "../Config/CONSTANT.js";
import axios from "axios";
import Cookies from "universal-cookie";

const cook = new Cookies().get("loginData");

const headersdata = {
    "content-type": "application/json",
    Accept: "application/json",
    Authorization: cook != undefined ? cook.accessToken : "",
};

export const getAllPlayerList = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(PLAYER_URL() + "list", data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getAllPlayerList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const addPlayer = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(PLAYER_URL(), data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in addPlayer axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const addAgent = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(Add_Agent_AgentTemplate_POST, data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in addAgent axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const addAgentPlayer = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(Add_Agent_PlayerTemplate_POST, data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in add Agent Player axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const addAdminVoucher = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(Add_Admin_VoucherTemplate_POST, data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in add Admin Voucher axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const addAgentVoucher = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(Add_Agent_VoucherTemplate_POST, data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in add Admin Voucher axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const updatePlayerDetails = (id, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(PLAYER_URL() + id, data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in updatePlayerDetails axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const updateAgentDetails = (id, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(Update_Agent_AgentTemplate_PUT.replaceAll("{recordId}", id), data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in updatePlayerDetails axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
// export const updateAgentPlayerDetails = (id, data) => {
//     return new Promise((resolve, reject) => {
//         try {
//             axios
//                 .put(Update_Agent_PlayerTemplate_PUT.replaceAll("{recordId}", id), data, { headers: headersdata })
//                 .then((res) => {
//                     resolve(res.data);
//                 })
//                 .catch((err) => {
//                     reject("Error in update Agent Player Details axios!");
//                 });
//         } catch (error) {
//             reject(SYSTEM_ERROR);
//         }
//     });
// };
export const getPlayerDetails = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(PLAYER_URL() + id, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getPlayerDetails axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const getAgentDetails = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(Get_Details_Agent_AgentTemplate_GET.replaceAll("{recordId}", id), { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getPlayerDetails axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const getAgentPlayerDetails = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(Get_Details_Agent_PlayerTemplate_GET.replaceAll("{recordId}", id), { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in get Agent Player Details axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const activatePlayer = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(PLAYER_URL() + "activate/" + id, {}, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in updatePlayerDetails axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const deactivatePlayer = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(PLAYER_URL() + "deactivate/" + id, {}, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in updatePlayerDetails axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const activateAgent = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(Agent_Activate_AgentTemplate_PUT.replaceAll("{recordId}", id), {}, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in ActivateAgent axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const deactivateAgent = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(Agent_Deactivate_AgentTemplate_PUT.replaceAll("{recordId}", id), {}, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in Deactivate Agent axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const activateAgentPlayer = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(Agent_Activate_PlayerTemplate_PUT.replaceAll("{recordId}", id), {}, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in ActivateAgent axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const deactivateAgentPlayer = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(Agent_Deactivate_PlayerTemplate_PUT.replaceAll("{recordId}", id), {}, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in Deactivate Agent axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const deletePlayer = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .delete(PLAYER_URL() + id, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in updatePlayerDetails axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const deleteAgent = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .delete(Delete_Agent_AgentTemplate_Delete.replaceAll("{recordId}", id), { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in updatePlayerDetails axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const reviveAdminAgent = (id, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(Agent_Revive_AgentTemplate_PUT.replaceAll("{recordId}", id), data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in walletClaimBack axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const deleteAgentPlayer = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .delete(Delete_Agent_PlayerTemplate_Delete.replaceAll("{recordId}", id), { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in Delete Agent Player Details axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const reviveAgentPlayer = (id, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(Agent_Revive_PlayerTemplate_PUT.replaceAll("{recordId}", id), data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in walletClaimBack axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const walletClaimBack = (id, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(PLAYER_URL() + id + "/wallet/claimBack", data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in walletClaimBack axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const agentPlayerClaimBack = (id, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(Agent_Player_ClaimBack_PUT.replaceAll("{recordId}", id), data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in Agent Player ClaimBack axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const agentClaimBack = (id, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(Agent_ClaimBack_POST.replaceAll("{recordId}", id), data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in Claim Back axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const walletSendOut = (id, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(PLAYER_URL() + id + "/wallet/sendOut", data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in walletSendOut axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const agentWalletSendOut = (id, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(Agent_SendOut_POST.replaceAll("{recordId}", id), data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in Send Out axios!");
                });

        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const agentChangeCommission = (id, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(Agent_Change_Commission_AgentTemplate_POST.replaceAll("{recordId}", id), data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in Send Out axios!");
                });

        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const adminAgentResetPassword = (id, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(Agent_Reset_Password_PUT.replaceAll("{recordId}", id), data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in Agent Player Send Out axios!");
                });

        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const agentPlayerSendOut = (id, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(Agent_Player_SendOut_PUT.replaceAll("{recordId}", id), data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in Agent Player Send Out axios!");
                });

        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const RewardSend = (id, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(PLAYER_URL() + id + "/wallet/sendRewardPoint", data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in walletSendOut axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const PlayerAgentChange = (id, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(Add_Change_Agent_Player.replaceAll("{recordId}/{agentId}", id + "/" + data), data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in walletSendOut axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const walletWithdrawableBalance = (id, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(PLAYER_URL() + id + "/wallet/updateWithdrawableBalance", data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in updateWithdrawableBalance axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const getAssignAgentDetails = () => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(Get_Admin_Assign_Agent_ListKeys_GET, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getPlayerDetails axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const getUnVerifiedNumbers = () =>{
    return new Promise((resolve,reject)=>{
        try{
            axios
                .get(Player_GetUnVerifiedNumbers_GET, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getPlayerDetails axios!");
                });
        }catch(error){
           reject(SYSTEM_ERROR);
        }
    })
}

export const handleUploadFile = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(EXCEL_FILE_UPLOAD_URL(), data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in uploadFile axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};