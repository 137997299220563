import { AVATAR_PAGES_URL,FILE_UPLOAD_URL } from '../CONSTANT.js'
import { SYSTEM_ERROR } from '../Config/CONSTANT.js';
import axios from 'axios'
import Cookies from 'universal-cookie';

const cook = new Cookies().get('loginData')


const headersdata = {
    'content-type': 'application/json',
    'Accept': 'application/json',
    'Authorization': cook != undefined ? cook.accessToken : ''
}

export const getAvatarList = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(AVATAR_PAGES_URL() + "list", data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getAvatarList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const uploadAvatarImage = (data) => {
    console.log(data);
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(FILE_UPLOAD_URL(), data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in uploadAvatarImage axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const updateAvatarDetails = (id,data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(AVATAR_PAGES_URL() + id, data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in updateAvatarDetails axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const addNewAvatar = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(AVATAR_PAGES_URL(), data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in addNewAvatar axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const getAvatarDetails = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(AVATAR_PAGES_URL() + id, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getAvatarDetails axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const deleteAvatarRecord = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .delete(AVATAR_PAGES_URL() + id, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in deleteAvatarRecord axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const activateAvatarRecord = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(AVATAR_PAGES_URL() +"activate/"+ id, {}, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in activateAvatarRecord axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const deactivateAvatarRecord = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(AVATAR_PAGES_URL() +"deactivate/"+ id, {}, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in deactivateSnakeLadderRecord axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

