import { Component } from "react";
import { format } from "date-fns";

class ModuleUtilities extends Component {
    checkModuleIsEnable = (moduleList, targetModule) => {
        if (moduleList !== undefined) {
            for (var i = 0; i < moduleList?.length; i++) {
                if (moduleList[i].settingKey === targetModule &&
                    moduleList[i].settingValue === "ON") {
                    return true;
                }
            }
        }
        return false;
    }
}

const moduleUtilities = new ModuleUtilities();

export default moduleUtilities;
