import React, {/* useMemo,  */ useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { callGetApi, callPutApi } from "../../../api/ApiCaller.js";
import {
    All_Game_Settings_List_GET,
    CLIENT_ID,
    LUDO_SERVER_URL,
    POKER_SERVER_URL,
    Set_Game_Settings_PUT,
} from "../../../api/ApiConst.js";
import PageHeading from "../../Common/PageHeading.jsx";

const PokerGame = () => {
    // const [startTime, setStartTime] = useState("");
    // const [endTime, setEndTime] = useState("");
    // const [maintenance, setMaintenance] = useState(null);
    // const [maintenanceStatus, setMaintenanceStatus] = useState(null);

    // const getLudoGameSettings = () => {
    //     callGetApi(
    //         All_Game_Settings_List_GET.replaceAll("{game}", "Ludo"),
    //         {},
    //         (response) => {
    //             response.responsePacket.map((data) => {
    //                 if (data.gameSettingKey === "LudoStartTime") {
    //                     setStartTime(data.gameSettingValue);
    //                 } else if (data.gameSettingKey === "LudoEndTime") {
    //                     setEndTime(data.gameSettingValue);
    //                 } else if (data.gameSettingKey === "LudoMaintenance") {
    //                     setMaintenance(data.gameSettingValue);
    //                     setMaintenanceStatus(data.gameSettingValue);
    //                 }
    //             });
    //         },
    //         (error) => {
    //             console.log(error);
    //         }
    //     );
    // }
    // useEffect(() => {
    //     getLudoGameSettings();
    // }, []);

    // const handleSubmitGameSetting = () => {
    //     if (startTime > endTime) {
    //         toast("Start Time Should be less than End Time");
    //         return;
    //     }
    //     const obj = {
    //         gameSettings: [
    //             {
    //                 gameSettingKey: "LudoStartTime",
    //                 gameSettingValue:
    //                     startTime +
    //                     (startTime.split(":").length === 3 ? "" : ":00"),
    //             },
    //             {
    //                 gameSettingKey: "LudoEndTime",
    //                 gameSettingValue:
    //                     endTime +
    //                     (endTime.split(":").length === 3 ? "" : ":00"),
    //             },
    //             {
    //                 gameSettingKey: "LudoMaintenance",
    //                 gameSettingValue: maintenance,
    //             },
    //         ],
    //     };
    //     callPutApi(Set_Game_Settings_PUT.replaceAll("{game}", "Ludo"), obj, {}, (response) => {
    //         if (response.errorCode === 0) {
    //             toast.success(response.message);
    //             getLudoGameSettings();
    //         }
    //     }
    //     );
    // };

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-3 d-flex align-items-center">
                            <PageHeading
                                heading={`Poker`}
                                maintenanceFlag={false}
                            />
                        </div>
                        {/* <div className="col-lg-9 mb-2">
                            <form className="row g-3 justify-content-lg-end align-items-center">
                                <div className="col-auto">
                                    <label className="col-form-label">
                                        Maintenance:
                                    </label>
                                </div>
                                <div className="col-auto">
                                    <button
                                        type="button"
                                        className={`btn btn-sm btn-toggle ${maintenance === "true" ? "active" : ""}`}
                                        data-toggle="button"
                                        aria-pressed="true"
                                        autoComplete="off"
                                        onClick={() => setMaintenance(maintenance === "true" ? "false" : "true")}
                                    >
                                        <div className="handle" />
                                    </button>
                                </div>
                                <div className="col-auto">
                                    <label className="col-form-label">
                                        Start Time
                                    </label>
                                </div>
                                <div className="col-auto">
                                    <input
                                        type="time"
                                        value={startTime}
                                        className="form-control"
                                        // style={{ width: "50%" }}
                                        id="startTime"
                                        name="startTime"
                                        onChange={(e) =>
                                            setStartTime(e.target.value)
                                        }
                                    />
                                </div>
                                <div className="col-auto">
                                    <label className="col-form-label">
                                        End Time
                                    </label>
                                </div>
                                <div className="col-auto">
                                    <input
                                        type="time"
                                        value={endTime}
                                        className="form-control "
                                        id="endTime"
                                        name="endTime"
                                        onChange={(e) =>
                                            setEndTime(e.target.value)
                                        }
                                    />
                                </div>
                                <div className="col-auto justify-content-end">
                                    <button
                                        type="button"
                                        className="btn btn-primary "
                                        onClick={handleSubmitGameSetting}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div> */}
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <iframe
                                src={POKER_SERVER_URL.replace(
                                    "{clientId}",
                                    CLIENT_ID
                                )}
                                title="Poker Setup"
                                style={{
                                    width: "100%",
                                    height: "72vh",
                                }}
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
};

export default PokerGame;
