import axios from "axios";
import Cookies from "universal-cookie";
import { toast} from "react-toastify";
import { UNAUTHORIZED } from "../api/constants";

const getHeaders = (headerMap) => {
    const loginData = new Cookies().get("loginData");
    const pathName = new Cookies().get("currentUser");
    if (!loginData) {
        return;
    }
    headerMap["Authorization"] = loginData.accessToken;
    return headerMap;
};
const handleSuccess = (result, successCallBack) => {
    if (successCallBack != null) {
        if (result.data.errorCode === UNAUTHORIZED) {
            const cookies = new Cookies();
            cookies.remove("loginData");
            // if 
            // window.location.href = pathName;
            window.location.href = '/';
        }
        successCallBack(result.data);
    }
};

const handleFailure = (error, failedCallBack) => {
    toast.error(error?.response?.data?.message ? error?.response?.data?.message : error.message);
    if (failedCallBack != null) {
        failedCallBack(error);
    }
};

export const callPostApiWithoutAuth = (url, data, successCallBack, failedCallBack) => {
    axios
        .post(url, data)
        .then((result) => handleSuccess(result, successCallBack))
        .catch((error) => handleFailure(error, failedCallBack));
};

export const callGetApi = (url, headerMap, successCallBack, failedCallBack) => {
    axios
        .get(url, {
            headers: getHeaders(headerMap),
        })
        .then((result) => handleSuccess(result, successCallBack))
        .catch((error) => handleFailure(error, failedCallBack));
};

export const callPostApiForExcelDownLoad = (url, data, headerMap, successCallBack, failedCallBack) => {
    axios
        .post(url, data, {
            responseType: "arraybuffer",
            headers: getHeaders(headerMap),
        })
        .then((result) => successCallBack(result.data))
        .catch((error) => handleFailure(error, failedCallBack));
};

export const callPostApi = (url, data, headerMap, successCallBack, failedCallBack) => {
    axios
        .post(url, data, {
            headers: getHeaders(headerMap),
        })
        .then((result) => handleSuccess(result, successCallBack))
        .catch((error) => handleFailure(error, failedCallBack));
};

export const callPutApi = (url, data, headerMap, successCallBack, failedCallBack) => {
    axios
        .put(url, data, {
            headers: getHeaders(headerMap),
        })
        .then((result) => handleSuccess(result, successCallBack))
        .catch((error) => handleFailure(error, failedCallBack));
};

export const callPatchApi = (url, data, headerMap, successCallBack, failedCallBack) => {
    axios
        .patch(url, data, {
            headers: getHeaders(headerMap),
        })
        .then((result) => handleSuccess(result, successCallBack))
        .catch((error) => handleFailure(error, failedCallBack));
};

export const callDeleteApi = (url, headerMap, successCallBack, failedCallBack) => {
    axios
        .delete(url, {
            headers: getHeaders(headerMap),
        })
        .then((result) => handleSuccess(result, successCallBack))
        .catch((error) => handleFailure(error, failedCallBack));
};


export const showWarningToast = (data) => {
    toast.warn(data, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
};

export const showSuccessToast = (data) => {
    toast("👏" + data, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
};

export const showFailureToast = (data) => {
    toast("🙁" + data, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
};