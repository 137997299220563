import React, { useEffect, useMemo, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import ConfirmationDialoge from "./Common/ConfirmationDialoge";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { All_Deleted_Coupon_Code_List_POST, Coupon_Code_List_POST } from "../api/ApiConst";
import PaginationDataTable from "./Common/PaginationDataTable";
import PageHeading from "./Common/PageHeading";
import dateUtilities from "../utilities/DateUtilities";
import CustomButton from "./Common/CustomButton";
import CheckboxButton from "./Common/CheckboxButton";
import { activateCoupon, addCoupon, deactivateCoupon, deleteCoupon, getCouponDetails, reviveCoupon, updateCouponDetails } from "../Services/MasterServices/CouponService";

const AdminCouponMaster = () => {
    const [show, setShow] = useState({
        addCoupon: false,
    });
    const [drawCount, setDrawCount] = useState(0);
    const [edit, setEdit] = useState(false);
    const [id, setId] = useState(null);
    const [discountType, setDiscountType] = useState(null);

    const discountTypeKeyValueList = [
        { label: "VALUE", value: "VALUE" },
        { label: "PERCENTAGE", value: "PERCENTAGE" },
    ];

    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        isAuth: true,
        title: "",
        subTitle: "",
        severity: "",
    });

    const [viewDeletedList, setViewDeletedList] = useState(false);
    const handleToggleDeletedView = () => setViewDeletedList(!viewDeletedList);
    const handleRefresh = () => setDrawCount((prev) => prev + 1);

    const [record, setRecord] = useState({
        couponCode: "",
        description: "",
        discountType: "",
        discountValue: 0,
        endDate: 0,
        maxDiscountAmount: 0,
        maxUsePerUser: 0,
        minOrderValue: 0,
        startDate: 0,
        title: ""
    });

    const handleAddCouponModal = () => {
        setShow({ ...show, addCoupon: false });
        handleClearForm();
    };

    const handleNewCoupon = () => {
        setEdit(false);
        setShow({ ...show, addCoupon: true });
        setId(null);
    };

    const handleInputChange = (e) => {
        const { name, value, id } = e.target;
        if (name === 'title' && value.length > 20 || name === 'couponCode' && value.length > 8) {
            return;
        }
        setRecord({ ...record, [id]: value });
    }

    const handleDiscountTypeChange = (e) => {
        setDiscountType(e)
        setRecord({ ...record, discountType: e });
    }

    const handleDeleteCoupon = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        deleteCoupon(id).then((res) => {
            if (res.errorCode === 0) {
                handleSuccessToast(res?.message);
                handleRefresh();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            } else {
                handleFailerToast(res.message);
            }
        });
    };

    const handleReviveData = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        reviveCoupon(id).then((res) => {
            if (res.errorCode === 0) {
                handleSuccessToast(res?.message);
                handleRefresh();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            } else {
                handleFailerToast(res.message);
            }
        });
    }
    const handleCouponDeactivate = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        deactivateCoupon(id).then((res) => {
            if (res.errorCode === 0) {
                handleSuccessToast(res?.message);
                handleRefresh();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            } else {
                handleFailerToast(res?.message);
            }
        });
    };
    const handleCouponActivate = (id) => {
        setConfirmDialog({  
            ...confirmDialog,
            isOpen: false,
        });
        activateCoupon(id).then((res) => {
            if (res.errorCode === 0) {
                handleSuccessToast(res?.message);
                handleRefresh();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            } else {
                handleFailerToast(res?.message);
            }
        });
    };
    const handleForceLogout = (data) => {
        toast("🙁" + data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setConfirmDialog({
            isOpen: true,
            isAuth: true,
            title: "You Are LoggedIn on Another Screen! to Countinue Logout and Login Again!!",
            onConfirm: () => logout(),
        });
    };

    const logout = () => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        const clearCookies = document.cookie.split(";").forEach((cookie) => (document.cookie = cookie.replace(/^ +/, "").replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`)));
        window.location.reload(1);
    };

    const columns = useMemo(
        () => [{
                id: "status",
                name: "",
                selector: (row) => {
                    if (row.active) {
                        return <div className="led-green" title="Active"></div>;
                    } else {
                        return <div className="led-red" title="Not Active"></div>;
                    }
                },
                width: "60px",
                sortable: false,
            },
            {
                name: "Title",
                selector: (row) => (row.title && row.title !== "" ? row.title : row.title),
                sortable: true,
                wrap: true,
                width: "500px",
            },
            {
                name: "Coupon Code",
                selector: (row) => (row.couponCode && row.couponCode !== "" ? row.couponCode : row.couponCode),
                sortable: true,
                wrap: true,
                width: "150px",
            },
            {
                name: "Date Range",
                selector: (row) => {
                    return (dateUtilities.formattedDate(row.startDate, "yyyy-MM-dd") + " -- " + dateUtilities.formattedDate(row.endDate, "yyyy-MM-dd"))
                },
                sortable: true,
                width: "240px",
                wrap: true
            },
            {
                name: "Min Order",
                selector: (row) => row.minOrderValue.toFixed(2),
                sortable: true,
                width: "120px",
                right: true,
                wrap: true
            }, {
                name: "Discount",
                selector: (row) => {
                    return (row.discountValue.toFixed(2) + "" + (row.discountType === "PERCENTAGE" ? "%" : "\-"));
                },
                sortable: true,
                right: true,
                width: "140px",
                wrap: true
            }, {
                name: "Max Discount Amount",
                selector: (row) => row?.maxDiscountAmount.toFixed(2),
                sortable: true,
                width: "200px",
                right: true,
                wrap: true
            }, {
                name: "Actions",
                cell: (row) => (
                    <div className="dropdown">
                        <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                            <KeyboardArrowDownOutlinedIcon />
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                            {!viewDeletedList && (
                                <li onClick={() => handleEditCoupon(row.recordId)}>
                                    <button className="dropdown-item" type="button">
                                        Edit
                                    </button>
                                </li>
                            )}
                            {!viewDeletedList && (
                                <li
                                    onClick={() =>
                                        setConfirmDialog({
                                            isOpen: true,
                                            title: "Are You Sure? You want to Delete this record",
                                            onConfirm: () => handleDeleteCoupon(row.recordId),
                                        })
                                    }
                                >
                                    <button className="dropdown-item" type="button">
                                        Delete
                                    </button>
                                </li>
                            )}
                            {viewDeletedList ? (
                                <li
                                    onClick={() =>
                                        setConfirmDialog({
                                            isOpen: true,
                                            title: "Are You Sure? You want to Revive this record",
                                            onConfirm: () => handleReviveData(row.recordId),
                                        })
                                    }
                                >
                                    <button className="dropdown-item" type="button">
                                        Revive
                                    </button>
                                </li>
                            ) : (
                                row.active ? (
                                    <li
                                        onClick={() =>
                                            setConfirmDialog({
                                                isOpen: true,
                                                title: "Are You Sure? You want to Deactivate this coupon",
                                                onConfirm: () => handleCouponDeactivate(row.recordId),
                                            })
                                        }
                                    >
                                        <button className="dropdown-item" type="button">
                                            Deactivate
                                        </button>
                                    </li>
                                ) : (
                                    <li
                                        onClick={() =>
                                            setConfirmDialog({
                                                isOpen: true,
                                                title: "Are You Sure? You want to Activate this coupon",
                                                onConfirm: () => handleCouponActivate(row.recordId),
                                            })
                                        }
                                    >
                                        <button className="dropdown-item" type="button">
                                            Activate
                                        </button>
                                    </li>
                                )
                            )}
                        </ul>
                    </div>
                ),
                sortable: true,
                right: true,
            }
        ],
        [drawCount, viewDeletedList]
    );
    

    const handleAdd = (e) => {
        e.preventDefault();
        let inputTag = id !== "";
        if (inputTag) {
            submitForm();
        } else {
            submitForm();
        }
    };

    const submitForm = () => {
        const timestamp = new Date().getTime();
        let request = {
            couponCode: record?.couponCode,
            discountType: discountType.value,
            description: record?.description,
            discountValue: parseInt(record?.discountValue),
            endDate: Date.parse(record?.endDate),
            maxDiscountAmount: parseInt(record?.maxDiscountAmount),
            maxUsePerUser: parseInt(record?.maxUsePerUser),
            minOrderValue: parseInt(record?.minOrderValue),
            startDate: Date.parse(record?.startDate),
            title: record?.title
        };

        if (request.couponCode === "" || request.couponCode === null) {
            handleWarningToast("Please provide coupon code.");
            return;
        } else if (request.discountValue === 0 || request.discountValue === null) {
            handleWarningToast("Please provide discountValue.");
            return;
        } else if (request.endDate === 0 || request.endDate === null) {
            handleWarningToast("Please provide endDate.");
            return;
        } else if (request.maxDiscountAmount === 0 || request.maxDiscountAmount === null) {
            handleWarningToast("Please provide maxDiscountAmount .");
            return;
        /* } else if (request.maxUsePerUser === 0 || request?.maxUsePerUser === null) {
            handleWarningToast("Please provide maxUsePerUser.");
            return; */
        } else if (request.minOrderValue === 0 || request?.minOrderValue === null) {
            handleWarningToast("Please provide minOrderValue ");
            return;
        } else if (request.startDate === 0 || request.startDate === null) {
            handleWarningToast("Please Select startDate !");
            return;
        } else if (request.title === "" || request.title === null) {
            handleWarningToast("Please Select title");
            return;
        }

        if (id) {
            updateCouponDetails(id, request).then((res) => {
                if (res.errorCode === 0) {
                    handleSuccessToast(res?.message);
                    handleRefresh();
                    handleAddCouponModal();
                    handleClearForm();
                } else if (res.message === "Unauthorised Access") {
                    handleForceLogout(res.message);
                } else {
                    handleFailerToast(res?.message);
                }
            })
        } else {
            addCoupon(request).then((res) => {
                if (res.errorCode === 0) {
                    handleSuccessToast(res?.message);
                    handleRefresh();
                    handleAddCouponModal();

                    handleClearForm();
                } else if (res.message === "Unauthorised Access") {
                    handleForceLogout(res.message);
                } else {
                    handleFailerToast(res?.message);
                }
            });
        }
    };

    const handleEditCoupon = (id) => {
        setId(id);
        setShow({ ...show, addCoupon: true });
        setEdit(true);

        getCouponDetails(id).then((res) => {
            if (res.errorCode === 0) {
                let couponDetails = res.responsePacket;
                const _discountType = discountTypeKeyValueList.find(data => data.value === couponDetails.discountType)
                let dict = {
                    title: couponDetails.title,
                    couponCode: couponDetails?.couponCode,
                    description: couponDetails?.description,
                    startDate: dateUtilities.formattedDate(couponDetails.startDate, "yyyy-MM-dd"),
                    endDate: dateUtilities.formattedDate(couponDetails?.endDate, "yyyy-MM-dd"),
                    minOrderValue: couponDetails.minOrderValue,
                    maxDiscountAmount: couponDetails.maxDiscountAmount,
                    maxUsePerUser: couponDetails.maxUsePerUser,
                    discountValue: couponDetails.discountValue,

                };
                setDiscountType(_discountType);
                setRecord(dict);
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            }
        });
    };

    const handleWarningToast = (data) => {
        toast.warn(data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    const handleSuccessToast = (data) => {
        toast("👏" + data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    const handleFailerToast = (data) => {
        toast("🙁" + data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    const handleClearForm = () => {
        // setRecord({});
        setDiscountType("");
    };

    const addCouponModal = () => {
        return (
            <Modal className="tablerowmodal" show={show.addCoupon} onHide={() => handleAddCouponModal()} size="lg" aria-labelledby="contained-modal-title-vcenter">
                <Modal.Body className="">
                    <div className="modal-header border-bottom-0">
                        <h4>{edit ? "Edit Coupon" : "Add Coupon"}</h4>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleAddCouponModal()}></button>
                    </div>
                    <div className="card-body">
                        <form>
                            <div className="row mb-3">
                                <div className="col-6">
                                    <label htmlFor="formrow-password-input" className="form-label">
                                        Title
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="title"
                                        name="title"
                                        value={record.title}
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </div>
                                <div className="col-3">
                                    <label>Coupon Code *</label>
                                    <input type='text'
                                        className="form-control input-default"
                                        id='couponCode'
                                        name="couponCode"
                                        value={record.couponCode}
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </div>
                                <div className="col-3">
                                    <div >
                                        <label htmlFor="formrow-email-input" className="form-label">
                                            Min Order Value
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="minOrderValue"
                                            min={0}
                                            value={record.minOrderValue}
                                            name="commissionPercentage"
                                            onChange={(e) => handleInputChange(e)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-4">
                                    <label>Discount Type *</label>
                                    <Select
                                        options={discountTypeKeyValueList}
                                        name="discountType"
                                        onChange={(e) => handleDiscountTypeChange(e)}
                                        value={discountType}
                                    />
                                </div>
                                <div className="col-4">
                                    <label htmlFor="formrow-email-input" className="form-label">
                                        Discount Value
                                    </label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="discountValue"
                                        min={0}
                                        value={record.discountValue}
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </div>
                                <div className="col-4">
                                    <div className="">
                                        <label htmlFor="formrow-email-input" className="form-label">
                                            Max Discount
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="maxDiscountAmount"
                                            min={0}
                                            value={record.maxDiscountAmount}
                                            onChange={(e) => handleInputChange(e)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-6">
                                    <label htmlFor="formrow-password-input" className="form-label">
                                        Start Date
                                    </label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        id="startDate"
                                        value={record.startDate}
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="formrow-password-input" className="form-label">
                                        End Date
                                    </label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        id="endDate"
                                        value={record.endDate}
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </div>
                                {/* <div className="col-4">
                                    <label htmlFor="formrow-email-input" className="form-label">
                                        Max Use Per User
                                    </label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="maxUsePerUser"
                                        min={0}
                                        value={record.maxUsePerUser}
                                        name="commissionPercentage"
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </div> */}
                            </div>
                            <div className="row mb-3">
                                <div className="col-12">
                                    <label htmlFor="formrow-email-input" className="form-label">
                                        Description
                                    </label>
                                    <textarea
                                        type="text"
                                        rows="3"
                                        className="form-control"
                                        id="description"
                                        value={record.description}
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </div>
                            </div>
                            <div>
                                <a className="btn btn-primary w-md" onClick={handleAdd}>
                                    {edit ? "Update Coupon" : "Add Coupon"}
                                </a>
                                &nbsp; &nbsp;
                                <a onClick={() => handleAddCouponModal()} className="btn btn-primary w-md">
                                    Cancel
                                </a>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal >
        );
    };

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <PageHeading heading="Coupon-List" />
                    <div className="card">
                        <div className="d-flex justify-content-end" style={{ position: "absolute", top: "22px", left: "35px", zIndex: 100 }}>
                            <div style={{ width: "200px" }}>
                                <CustomButton handleClick={handleNewCoupon} title="Add Coupon" iconClass="bx bx-plus" buttonClass="createButton" />
                            </div>
                            <div style={{ width: "200px" }}>

                            </div>
                        </div>
                        <div className="card-body">
                            <PaginationDataTable
                                paginationUrl={viewDeletedList ? All_Deleted_Coupon_Code_List_POST : Coupon_Code_List_POST}
                                serverSidePagination={true}
                                search={true}
                                columns={columns}
                                additionalClassName={"agentSearchInput"}
                            />
                            <CheckboxButton title="Deleted List" handleClick={handleToggleDeletedView} additionalClass="deletedBtn ml-auto" />
                        </div>
                    </div>
                </div>
            </div>
            {show.addCoupon && addCouponModal()}
            <ToastContainer />
            <ConfirmationDialoge confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
        </>
    );
};

export default AdminCouponMaster;
