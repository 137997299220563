import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { BrowserRouter as Router } from "react-router-dom";

import "./App.css";
import { Pre_Launch_Data_GET } from "./api/ApiConst";
import { callGetApi, callPostApi } from "./api/ApiCaller";

import LoginComponent from "./Components/LoginComponent";
import Navigation from "./Components/Navigation";

function App() {
    const location = window.location;
    const cookies = new Cookies();
    var loginData = cookies.get("loginData");
    const [loginLogo, setLoginLogo] = useState("");
    // TODO   : ADD NEW Image Folder 
    const imageFilePathNames = ["Benno Poker", "Bunzzi Ludo", "Kpis", "GamzCorner", "Poker99", "Revolution",
        "SpinItUpLudo"];

    useEffect(() => {
        callPostApi(Pre_Launch_Data_GET, {}, {}, (res) => {
            if (res.errorCode === 0) {
                const cookies = new Cookies();
                const profile = res.responsePacket.profile || "Kpis";
                // Check if profile matches any entry in imageFilePathNames
                const isProfileValid = imageFilePathNames.includes(profile);
                setLoginLogo(isProfileValid ? profile : "Kpis");
                // Set profile and favicon
                cookies.set("profile", isProfileValid ? profile : "Kpis", { path: "/" });
                const link = document.querySelector("link[rel*='icon']") || document.createElement("link");
                link.type = "image/x-icon";
                link.rel = "shortcut icon";
                document.title = isProfileValid ? profile : "Admin Panel";
                link.href = `/assets/images/profile/${isProfileValid ? profile : "Kpis"}/favicon.png`;
                document.getElementsByTagName("head")[0].appendChild(link);
            }
        });
    }, []);

    if (loginData === undefined) {
        if (location?.pathname === '/') {
            const currentUserPath = cookies.get("currentUser");
            if (currentUserPath) {
                window.history.pushState({}, '', currentUserPath);
            } else {
                window.history.pushState({}, '', '/al');
            }
        }
        if (location?.pathname === '/' || location?.pathname === '/al' || location?.pathname === '/agl') {
            cookies.set("currentUser", location?.pathname, { path: '/' });
            return (
                <div className="App">
                    <LoginComponent pathName={location?.pathname} loginLogo={loginLogo} />
                </div>
            );
        } else {
            return <div className="d-flex justify-content-center mt-5 "><h4>Invalid URL Request </h4> </div>
        }
    } else {
        return (
            <div id="layout-wrapper">
                <Router>
                    <Navigation pathName={location.pathname} />
                </Router>
            </div>
        );
    }
}

export default App;
