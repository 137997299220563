export const countryCodeList = [
    {
        flag: "ie",
        country: "Ireland",
        code: "+353",
    },
    {
        flag: "us",
        country: "United States",
        code: "+1",
    },
    {
        flag: "gb",
        country: "United Kingdom",
        code: "+44",
    },
    {
        flag: "af",
        country: "Afghanistan (‫افغانستان‬‎)",
        code: "+93",
    },
    {
        flag: "al",
        country: "Albania (Shqipëri)",
        code: "+355",
    },
    {
        flag: "dz",
        country: "Algeria (‫الجزائر‬‎)",
        code: "+213",
    },
    {
        flag: "as",
        country: "American Samoa",
        code: "+1684",
    },
    {
        country: "Andorra",
        flag: "ad",
        code: "+376",
    },
    {
        country: "Angola",
        flag: "ao",
        code: "+244",
    },
    {
        country: "Anguilla",
        flag: "ai",
        code: "+1264",
    },
    {
        country: "Antigua and Barbuda",
        flag: "ag",
        code: "+1268",
    },
    {
        country: "Argentina",
        flag: "ar",
        code: "+54",
    },
    {
        country: "Armenia (Հայաստան)",
        flag: "am",
        code: "+374",
    },
    {
        country: "Aruba",
        flag: "aw",
        code: "+297",
    },
    {
        country: "Australia",
        flag: "au",
        code: "+61",
    },
    {
        country: "Austria (Österreich)",
        flag: "at",
        code: "+43",
    },
    {
        country: "Azerbaijan (Azərbaycan)",
        flag: "az",
        code: "+994",
    },
    {
        country: "Bahamas",
        flag: "bs",
        code: "+1242",
    },
    {
        country: "Bahrain (‫البحرين‬‎)",
        flag: "bh",
        code: "+973",
    },
    {
        country: "Bangladesh (বাংলাদেশ)",
        flag: "bd",
        code: "+880",
    },
    {
        country: "Barbados",
        flag: "bb",
        code: "+1246",
    },
    {
        country: "Belarus (Беларусь)",
        flag: "by",
        code: "+375",
    },
    {
        country: "Belgium (België)",
        flag: "be",
        code: "+32",
    },
    {
        country: "Belize",
        flag: "bz",
        code: "+501",
    },
    {
        country: "Benin (Bénin)",
        flag: "bj",
        code: "+229",
    },
    {
        country: "Bermuda",
        flag: "bm",
        code: "+1441",
    },
    {
        country: "Bhutan (འབྲུག)",
        flag: "bt",
        code: "+975",
    },
    {
        country: "Bolivia",
        flag: "bo",
        code: "+591",
    },
    {
        country: "Bosnia and Herzegovina (Босна и Херцеговина)",
        flag: "ba",
        code: "+387",
    },
    {
        country: "Botswana",
        flag: "bw",
        code: "+267",
    },
    {
        country: "Brazil (Brasil)",
        flag: "br",
        code: "+55",
    },
    {
        country: "British Indian Ocean Territory",
        flag: "io",
        code: "+246",
    },
    {
        country: "British Virgin Islands",
        flag: "vg",
        code: "+1284",
    },
    {
        country: "Brunei",
        flag: "bn",
        code: "+673",
    },
    {
        country: "Bulgaria (България)",
        flag: "bg",
        code: "+359",
    },
    {
        country: "Burkina Faso",
        flag: "bf",
        code: "+226",
    },
    {
        country: "Burundi (Uburundi)",
        flag: "bi",
        code: "+257",
    },
    {
        country: "Cambodia (កម្ពុជា)",
        flag: "kh",
        code: "+855",
    },
    {
        country: "Cameroon (Cameroun)",
        flag: "cm",
        code: "+237",
    },
    {
        country: "Canada",
        flag: "ca",
        code: "+1",
    },
    {
        country: "Cape Verde (Kabu Verdi)",
        flag: "cv",
        code: "+238",
    },
    {
        country: "Caribbean Netherlands",
        flag: "bq",
        code: "+599",
    },
    {
        country: "Cayman Islands",
        flag: "ky",
        code: "+1345",
    },
    {
        country: "Central African Republic (République centrafricaine)",
        flag: "cf",
        code: "+236",
    },
    {
        country: "Chad (Tchad)",
        flag: "td",
        code: "+235",
    },
    {
        country: "Chile",
        flag: "cl",
        code: "+56",
    },
    {
        country: "China (中国)",
        flag: "cn",
        code: "+86",
    },
    {
        country: "Christmas Island",
        flag: "cx",
        code: "+61",
    },
    {
        country: "Cocos (Keeling) Islands",
        flag: "cc",
        code: "+61",
    },
    {
        country: "Colombia",
        flag: "co",
        code: "+57",
    },
    {
        country: "Comoros (‫جزر القمر‬‎)",
        flag: "km",
        code: "+269",
    },
    {
        country: "Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)",
        flag: "cd",
        code: "+243",
    },
    {
        country: "Congo (Republic) (Congo-Brazzaville)",
        flag: "cg",
        code: "+242",
    },
    {
        country: "Cook Islands",
        flag: "ck",
        code: "+682",
    },
    {
        country: "Costa Rica",
        flag: "cr",
        code: "+506",
    },
    {
        country: "Côte d’Ivoire",
        flag: "ci",
        code: "+225",
    },
    {
        country: "Croatia (Hrvatska)",
        flag: "hr",
        code: "+385",
    },
    {
        country: "Cuba",
        flag: "cu",
        code: "+53",
    },
    {
        country: "Curaçao",
        flag: "cw",
        code: "+599",
    },
    {
        country: "Cyprus (Κύπρος)",
        flag: "cy",
        code: "+357",
    },
    {
        country: "Czech Republic (Česká republika)",
        flag: "cz",
        code: "+420",
    },
    {
        country: "Denmark (Danmark)",
        flag: "dk",
        code: "+45",
    },
    {
        country: "Djibouti",
        flag: "dj",
        code: "+253",
    },
    {
        country: "Dominica",
        flag: "dm",
        code: "+1767",
    },
    {
        country: "Dominican Republic (República Dominicana)",
        flag: "do",
        code: "+1",
    },
    {
        country: "Ecuador",
        flag: "ec",
        code: "+593",
    },
    {
        country: "Egypt (‫مصر‬‎)",
        flag: "eg",
        code: "+20",
    },
    {
        country: "El Salvador",
        flag: "sv",
        code: "+503",
    },
    {
        country: "Equatorial Guinea (Guinea Ecuatorial)",
        flag: "gq",
        code: "+240",
    },
    {
        country: "Eritrea",
        flag: "er",
        code: "+291",
    },
    {
        country: "Estonia (Eesti)",
        flag: "ee",
        code: "+372",
    },
    {
        country: "Ethiopia",
        flag: "et",
        code: "+251",
    },
    {
        country: "Falkland Islands (Islas Malvinas)",
        flag: "fk",
        code: "+500",
    },
    {
        country: "Faroe Islands (Føroyar)",
        flag: "fo",
        code: "+298",
    },
    {
        country: "Fiji",
        flag: "fj",
        code: "+679",
    },
    {
        country: "Finland (Suomi)",
        flag: "fi",
        code: "+358",
    },
    {
        country: "France",
        flag: "fr",
        code: "+33",
    },
    {
        country: "French Guiana (Guyane française)",
        flag: "gf",
        code: "+594",
    },
    {
        country: "French Polynesia (Polynésie française)",
        flag: "pf",
        code: "+689",
    },
    {
        country: "Gabon",
        flag: "ga",
        code: "+241",
    },
    {
        country: "Gambia",
        flag: "gm",
        code: "+220",
    },
    {
        country: "Georgia (საქართველო)",
        flag: "ge",
        code: "+995",
    },
    {
        country: "Germany (Deutschland)",
        flag: "de",
        code: "+49",
    },
    {
        country: "Ghana (Gaana)",
        flag: "gh",
        code: "+233",
    },
    {
        country: "Gibraltar",
        flag: "gi",
        code: "+350",
    },
    {
        country: "Greece (Ελλάδα)",
        flag: "gr",
        code: "+30",
    },
    {
        country: "Greenland (Kalaallit Nunaat)",
        flag: "gl",
        code: "+299",
    },
    {
        country: "Grenada",
        flag: "gd",
        code: "+1473",
    },
    {
        country: "Guadeloupe",
        flag: "gp",
        code: "+590",
    },
    {
        country: "Guam",
        flag: "gu",
        code: "+1671",
    },
    {
        country: "Guatemala",
        flag: "gt",
        code: "+502",
    },
    {
        country: "Guernsey",
        flag: "gg",
        code: "+44",
    },
    {
        country: "Guinea (Guinée)",
        flag: "gn",
        code: "+224",
    },
    {
        country: "Guinea-Bissau (Guiné Bissau)",
        flag: "gw",
        code: "+245",
    },
    {
        country: "Guyana",
        flag: "gy",
        code: "+592",
    },
    {
        country: "Haiti",
        flag: "ht",
        code: "+509",
    },
    {
        country: "Honduras",
        flag: "hn",
        code: "+504",
    },
    {
        country: "Hong Kong (香港)",
        flag: "hk",
        code: "+852",
    },
    {
        country: "Hungary (Magyarország)",
        flag: "hu",
        code: "+36",
    },
    {
        country: "Iceland (Ísland)",
        flag: "is",
        code: "+354",
    },
    {
        country: "India (भारत)",
        flag: "in",
        code: "+91",
    },
    {
        country: "Indonesia",
        flag: "id",
        code: "+62",
    },
    {
        country: "Iran (‫ایران‬‎)",
        flag: "ir",
        code: "+98",
    },
    {
        country: "Iraq (‫العراق‬‎)",
        flag: "iq",
        code: "+964",
    },
    {
        country: "Isle of Man",
        flag: "im",
        code: "+44",
    },
    {
        country: "Israel (‫ישראל‬‎)",
        flag: "il",
        code: "+972",
    },
    {
        country: "Italy (Italia)",
        flag: "it",
        code: "+39",
    },
    {
        country: "Jamaica",
        flag: "jm",
        code: "+1876",
    },
    {
        country: "Japan (日本)",
        flag: "jp",
        code: "+81",
    },
    {
        country: "Jersey",
        flag: "je",
        code: "+44",
    },
    {
        country: "Jordan (‫الأردن‬‎)",
        flag: "jo",
        code: "+962",
    },
    {
        country: "Kazakhstan (Казахстан)",
        flag: "kz",
        code: "+7",
    },
    {
        country: "Kenya",
        flag: "ke",
        code: "+254",
    },
    {
        country: "Kiribati",
        flag: "ki",
        code: "+686",
    },
    {
        country: "Kuwait (‫الكويت‬‎)",
        flag: "kw",
        code: "+965",
    },
    {
        country: "Kyrgyzstan (Кыргызстан)",
        flag: "kg",
        code: "+996",
    },
    {
        country: "Laos (ລາວ)",
        flag: "la",
        code: "+856",
    },
    {
        country: "Latvia (Latvija)",
        flag: "lv",
        code: "+371",
    },
    {
        country: "Lebanon (‫لبنان‬‎)",
        flag: "lb",
        code: "+961",
    },
    {
        country: "Lesotho",
        flag: "ls",
        code: "+266",
    },
    {
        country: "Liberia",
        flag: "lr",
        code: "+231",
    },
    {
        country: "Libya (‫ليبيا‬‎)",
        flag: "ly",
        code: "+218",
    },
    {
        country: "Liechtenstein",
        flag: "li",
        code: "+423",
    },
    {
        country: "Lithuania (Lietuva)",
        flag: "lt",
        code: "+370",
    },
    {
        country: "Luxembourg",
        flag: "lu",
        code: "+352",
    },
    {
        country: "Macau (澳門)",
        flag: "mo",
        code: "+853",
    },
    {
        country: "Macedonia (FYROM) (Македонија)",
        flag: "mk",
        code: "+389",
    },
    {
        country: "Madagascar (Madagasikara)",
        flag: "mg",
        code: "+261",
    },
    {
        country: "Malawi",
        flag: "mw",
        code: "+265",
    },
    {
        country: "Malaysia",
        flag: "my",
        code: "+60",
    },
    {
        country: "Maldives",
        flag: "mv",
        code: "+960",
    },
    {
        country: "Mali",
        flag: "ml",
        code: "+223",
    },
    {
        country: "Malta",
        flag: "mt",
        code: "+356",
    },
    {
        country: "Marshall Islands",
        flag: "mh",
        code: "+692",
    },
    {
        country: "Martinique",
        flag: "mq",
        code: "+596",
    },
    {
        country: "Mauritania (‫موريتانيا‬‎)",
        flag: "mr",
        code: "+222",
    },
    {
        country: "Mauritius (Moris)",
        flag: "mu",
        code: "+230",
    },
    {
        country: "Mayotte",
        flag: "yt",
        code: "+262",
    },
    {
        country: "Mexico (México)",
        flag: "mx",
        code: "+52",
    },
    {
        country: "Micronesia",
        flag: "fm",
        code: "+691",
    },
    {
        country: "Moldova (Republica Moldova)",
        flag: "md",
        code: "+373",
    },
    {
        country: "Monaco",
        flag: "mc",
        code: "+377",
    },
    {
        country: "Mongolia (Монгол)",
        flag: "mn",
        code: "+976",
    },
    {
        country: "Montenegro (Crna Gora)",
        flag: "me",
        code: "+382",
    },
    {
        country: "Montserrat",
        flag: "ms",
        code: "+1664",
    },
    {
        country: "Morocco (‫المغرب‬‎)",
        flag: "ma",
        code: "+212",
    },
    {
        country: "Mozambique (Moçambique)",
        flag: "mz",
        code: "+258",
    },
    {
        country: "Myanmar (Burma) (မြန်မာ)",
        flag: "mm",
        code: "+95",
    },
    {
        country: "Namibia (Namibië)",
        flag: "na",
        code: "+264",
    },
    {
        country: "Nauru",
        flag: "nr",
        code: "+674",
    },
    {
        country: "Nepal (नेपाल)",
        flag: "np",
        code: "+977",
    },
    {
        country: "Netherlands (Nederland)",
        flag: "nl",
        code: "+31",
    },
    {
        country: "New Caledonia (Nouvelle-Calédonie)",
        flag: "nc",
        code: "+687",
    },
    {
        country: "New Zealand",
        flag: "nz",
        code: "+64",
    },
    {
        country: "Nicaragua",
        flag: "ni",
        code: "+505",
    },
    {
        country: "Niger (Nijar)",
        flag: "ne",
        code: "+227",
    },
    {
        country: "Nigeria",
        flag: "ng",
        code: "+234",
    },
    {
        country: "Niue",
        flag: "nu",
        code: "+683",
    },
    {
        country: "Norfolk Island",
        flag: "nf",
        code: "+672",
    },
    {
        country: "North Korea (조선 민주주의 인민 공화국)",
        flag: "kp",
        code: "+850",
    },
    {
        country: "Northern Mariana Islands",
        flag: "mp",
        code: "+1670",
    },
    {
        country: "Norway (Norge)",
        flag: "no",
        code: "+47",
    },
    {
        country: "Oman (‫عُمان‬‎)",
        flag: "om",
        code: "+968",
    },
    {
        country: "Pakistan (‫پاکستان‬‎)",
        flag: "pk",
        code: "+92",
    },
    {
        country: "Palau",
        flag: "pw",
        code: "+680",
    },
    {
        country: "Palestine (‫فلسطين‬‎)",
        flag: "ps",
        code: "+970",
    },
    {
        country: "Panama (Panamá)",
        flag: "pa",
        code: "+507",
    },
    {
        country: "Papua New Guinea",
        flag: "pg",
        code: "+675",
    },
    {
        country: "Paraguay",
        flag: "py",
        code: "+595",
    },
    {
        country: "Peru (Perú)",
        flag: "pe",
        code: "+51",
    },
    {
        country: "Philippines",
        flag: "ph",
        code: "+63",
    },
    {
        country: "Poland (Polska)",
        flag: "pl",
        code: "+48",
    },
    {
        country: "Portugal",
        flag: "pt",
        code: "+351",
    },
    {
        country: "Puerto Rico",
        flag: "pr",
        code: "+1",
    },
    {
        country: "Qatar (‫قطر‬‎)",
        flag: "qa",
        code: "+974",
    },
    {
        country: "Réunion (La Réunion)",
        flag: "re",
        code: "+262",
    },
    {
        country: "Romania (România)",
        flag: "ro",
        code: "+40",
    },
    {
        country: "Russia (Россия)",
        flag: "ru",
        code: "+7",
    },
    {
        country: "Rwanda",
        flag: "rw",
        code: "+250",
    },
    {
        country: "Saint Barthélemy (Saint-Barthélemy)",
        flag: "bl",
        code: "+590",
    },
    {
        country: "Saint Helena",
        flag: "sh",
        code: "+290",
    },
    {
        country: "Saint Kitts and Nevis",
        flag: "kn",
        code: "+1869",
    },
    {
        country: "Saint Lucia",
        flag: "lc",
        code: "+1758",
    },
    {
        country: "Saint Martin (Saint-Martin (partie française))",
        flag: "mf",
        code: "+590",
    },
    {
        country: "Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)",
        flag: "pm",
        code: "+508",
    },
    {
        country: "Saint Vincent and the Grenadines",
        flag: "vc",
        code: "+1784",
    },
    {
        country: "Samoa",
        flag: "ws",
        code: "+685",
    },
    {
        country: "San Marino",
        flag: "sm",
        code: "+378",
    },
    {
        country: "São Tomé and Príncipe (São Tomé e Príncipe)",
        flag: "st",
        code: "+239",
    },
    {
        country: "Saudi Arabia (‫المملكة العربية السعودية‬‎)",
        flag: "sa",
        code: "+966",
    },
    {
        country: "Senegal (Sénégal)",
        flag: "sn",
        code: "+221",
    },
    {
        country: "Serbia (Србија)",
        flag: "rs",
        code: "+381",
    },
    {
        country: "Seychelles",
        flag: "sc",
        code: "+248",
    },
    {
        country: "Sierra Leone",
        flag: "sl",
        code: "+232",
    },
    {
        country: "Singapore",
        flag: "sg",
        code: "+65",
    },
    {
        country: "Sint Maarten",
        flag: "sx",
        code: "+1721",
    },
    {
        country: "Slovakia (Slovensko)",
        flag: "sk",
        code: "+421",
    },
    {
        country: "Slovenia (Slovenija)",
        flag: "si",
        code: "+386",
    },
    {
        country: "Solomon Islands",
        flag: "sb",
        code: "+677",
    },
    {
        country: "Somalia (Soomaaliya)",
        flag: "so",
        code: "+252",
    },
    {
        country: "South Africa",
        flag: "za",
        code: "+27",
    },
    {
        country: "South Korea (대한민국)",
        flag: "kr",
        code: "+82",
    },
    {
        country: "South Sudan (‫جنوب السودان‬‎)",
        flag: "ss",
        code: "+211",
    },
    {
        country: "Spain (España)",
        flag: "es",
        code: "+34",
    },
    {
        country: "Sri Lanka (ශ්‍රී ලංකාව)",
        flag: "lk",
        code: "+94",
    },
    {
        country: "Sudan (‫السودان‬‎)",
        flag: "sd",
        code: "+249",
    },
    {
        country: "Suriname",
        flag: "sr",
        code: "+597",
    },
    {
        country: "Svalbard and Jan Mayen",
        flag: "sj",
        code: "+47",
    },
    {
        country: "Swaziland",
        flag: "sz",
        code: "+268",
    },
    {
        country: "Sweden (Sverige)",
        flag: "se",
        code: "+46",
    },
    {
        country: "Switzerland (Schweiz)",
        flag: "ch",
        code: "+41",
    },
    {
        country: "Syria (‫سوريا‬‎)",
        flag: "sy",
        code: "+963",
    },
    {
        country: "Taiwan (台灣)",
        flag: "tw",
        code: "+886",
    },
    {
        country: "Tajikistan",
        flag: "tj",
        code: "+992",
    },
    {
        country: "Tanzania",
        flag: "tz",
        code: "+255",
    },
    {
        country: "Thailand (ไทย)",
        flag: "th",
        code: "+66",
    },
    {
        country: "Timor-Leste",
        flag: "tl",
        code: "+670",
    },
    {
        country: "Togo",
        flag: "tg",
        code: "+228",
    },
    {
        country: "Tokelau",
        flag: "tk",
        code: "+690",
    },
    {
        country: "Tonga",
        flag: "to",
        code: "+676",
    },
    {
        country: "Trinidad and Tobago",
        flag: "tt",
        code: "+1868",
    },
    {
        country: "Tunisia (‫تونس‬‎)",
        flag: "tn",
        code: "+216",
    },
    {
        country: "Turkey (Türkiye)",
        flag: "tr",
        code: "+90",
    },
    {
        country: "Turkmenistan",
        flag: "tm",
        code: "+993",
    },
    {
        country: "Turks and Caicos Islands",
        flag: "tc",
        code: "+1649",
    },
    {
        country: "Tuvalu",
        flag: "tv",
        code: "+688",
    },
    {
        country: "U.S. Virgin Islands",
        flag: "vi",
        code: "+1340",
    },
    {
        country: "Uganda",
        flag: "ug",
        code: "+256",
    },
    {
        country: "Ukraine (Україна)",
        flag: "ua",
        code: "+380",
    },
    {
        country: "United Arab Emirates (‫الإمارات العربية المتحدة‬‎)",
        flag: "ae",
        code: "+971",
    },
    {
        country: "United Kingdom",
        flag: "gb",
        code: "+44",
    },
    {
        country: "United States",
        flag: "us",
        code: "+1",
    },
    {
        country: "Uruguay",
        flag: "uy",
        code: "+598",
    },
    {
        country: "Uzbekistan (Oʻzbekiston)",
        flag: "uz",
        code: "+998",
    },
    {
        country: "Vanuatu",
        flag: "vu",
        code: "+678",
    },
    {
        country: "Vatican City (Città del Vaticano)",
        flag: "va",
        code: "+39",
    },
    {
        country: "Venezuela",
        flag: "ve",
        code: "+58",
    },
    {
        country: "Vietnam (Việt Nam)",
        flag: "vn",
        code: "+84",
    },
    {
        country: "Wallis and Futuna",
        flag: "wf",
        code: "+681",
    },
    {
        country: "Western Sahara (‫الصحراء الغربية‬‎)",
        flag: "eh",
        code: "+212",
    },
    {
        country: "Yemen (‫اليمن‬‎)",
        flag: "ye",
        code: "+967",
    },
    {
        country: "Zambia",
        flag: "zm",
        code: "+260",
    },
    {
        country: "Zimbabwe",
        flag: "zw",
        code: "+263",
    },
    {
        country: "Åland Islands",
        flag: "ax",
        code: "+358",
    },
];
