import React, { useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";

import { All_Admin_VoucherTemplate_List_POST, All_Agent_VoucherTemplate_List_POST } from "../../api/ApiConst";
import { addAdminVoucher, addAgentVoucher } from "../../Services/MasterServices/PlayerService";

import CustomButton from "./CustomButton";
import PageHeading from "./PageHeading";
import PaginationDataTable from "./PaginationDataTable";
import dateUtilities from "../../utilities/DateUtilities";
import ConfirmationDialoge from "../Common/ConfirmationDialoge";

const VoucherDetails = (props) => {
    const [drawCount, setDrawCount] = useState(0);
    const [show, setShow] = useState({ addVoucher: false });
    const [user, setUser] = useState({ voucherAmount: "", referenceNote: "", });
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false, isAuth: true, title: "", subTitle: "", severity: "",
    });
    const handleRefresh = () => setDrawCount((prev) => prev + 1);

    const handleAddPlayerModal = () => {
        setShow({ ...show, addVoucher: false });
        handleClearForm();
    };
    const columns = useMemo(
        () => [{
            name: "Voucher Amount",
            selector: (row) => <>{process.env.REACT_APP_CURRENCY_SYMBOL} {row?.voucherAmount.toFixed(2)}</>,
            sortable: true,
            // right: true,
            width: "170px",
            wrap: true
        }, {
            name: "Registered At",
            selector: (row) => dateUtilities.formattedDate(row?.createdAt, "dd-MM-yyyy hh:mm aa"),
            sortable: true,
            // right: true,
            // width: "250px",
            wrap: true
        }, {
            // id: "status",
            name: "Consumed",
            selector: (row) => {
                if (!row?.consumed) {
                    return <div className="bg-success px-1 border rounded-3 text-white " title="Active">Consume</div>;
                } else {
                    return <div className="bg-danger px-1 border rounded-3 text-white " title="Not Active">Consumed</div>;
                }
            },
            width: "170px",
            sortable: false,
        }, {
            name: "Voucher Code",
            selector: (row) => (row?.voucherCode ? row?.voucherCode : ""),
            sortable: true,
            wrap: true,
            width: "220px",
        }, {
            name: "Reference Note",
            selector: (row) => <>{row?.referenceNote ? row?.referenceNote : ""}</>,
            sortable: true,
            // right: true,
            width: "160px",
            wrap: true
        },],
        [drawCount]
    );
    const handleUserBasic = (e) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value,
        });
    };
    const handleForceLogout = (data) => {
        toast("🙁" + data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setConfirmDialog({
            isOpen: true,
            isAuth: true,
            title: "You Are LoggedIn on Another Screen! to Countinue Logout and Login Again!!",
            onConfirm: () => logout(),
        });
    };
    const logout = () => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        const clearCookies = document.cookie.split(";").forEach((cookie) => (document.cookie = cookie.replace(/^ +/, "").replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`)));
        window.location.reload(1);
    };
    const handleClearForm = () => {
        setUser({
            voucherAmount: "",
            referenceNote: ""
        });
        // setDate(new Date());
    };
    const handleWarningToast = (data) => {
        toast.warn(data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    const handleSuccessToast = (data) => {
        toast("👏" + data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    const handleFailerToast = (data) => {
        toast("🙁" + data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    const handleNewPlayer = () => {
        setShow({ ...show, addVoucher: true });
    };
    const addPlayerModal = () => {
        return (
            <Modal className="tablerowmodal" show={show.addVoucher} onHide={() => handleAddPlayerModal()} size="md" aria-labelledby="contained-modal-title-vcenter">
                <Modal.Body className="">
                    <div className="modal-header border-bottom-0">
                        <h4>Add Voucher</h4>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleAddPlayerModal()}></button>
                    </div>
                    <div className="card-body">
                        <form>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label htmlFor="formrow-email-input" className="form-label">
                                            Enter Amount
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="formrow-email-input"
                                            placeholder="Enter Amount"
                                            name="voucherAmount"
                                            min={1}
                                            value={user.voucherAmount}
                                            // step={0.01}
                                            onChange={(e) => handleUserBasic(e)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label htmlFor="formrow-email-input" className="form-label">
                                            Reference Note
                                        </label>
                                        <textarea
                                            type="text"
                                            className="form-control"
                                            id="formrow-email-input"
                                            name="referenceNote"
                                            placeholder="Enter Reference Note"
                                            value={user.referenceNote}
                                            onChange={(e) => handleUserBasic(e)}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-md-12">
                                <div className="mb-3">
                                    <label htmlFor="formrow-password-input" className="form-label">
                                        Valid Till
                                    </label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        id="formrow-password-input"
                                        placeholder="Enter Your Password"
                                        onChange={(e) => setDate(e.target.value)}
                                        value={date}
                                    />
                                </div>
                            </div> */}
                            <div>
                                <a className="btn btn-primary w-md" onClick={handleAddPlayer}>
                                    Add Voucher
                                </a>
                                &nbsp; &nbsp;
                                <a onClick={() => handleAddPlayerModal()} className="btn btn-primary w-md">
                                    Cancel
                                </a>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        );
    };
    const handleAddPlayer = (e) => {
        e.preventDefault();
        // if (validateAddUpdateAssets(e)) {
        // let inputTag = $("#formrow-inputZip").val();
        // if (inputTag != "") {
        //     uploadFile(bodyFormFileData).then((res) => {
        //         if (res.errorCode === 0) {
        //             $("#assetsImageInput").val(res.responsePacket);
        //             submitForm();
        //         }
        //     });
        // } else {
        submitForm();
        // }
    }
    const submitForm = () => {
        let request = {
            voucherAmount: user?.voucherAmount,
            referenceNote: user?.referenceNote
        };
        if (request.voucherAmount === "" || request.voucherAmount === null) {
            handleWarningToast("Please provide Voucher Amount.");
            return;
        }
        if (props.voucher === "ADMIN_VOUCHER") {
            addAdminVoucher(request).then((res) => {
                if (res.errorCode === 0) {
                    handleSuccessToast(res?.message);
                    handleRefresh();
                    handleAddPlayerModal();
                } else if (res.message === "Unauthorised Access") {
                    handleForceLogout(res.message);
                } else {
                    handleFailerToast(res?.message);
                }
            });
        } else {
            addAgentVoucher(request).then((res) => {
                if (res.errorCode === 0) {
                    handleSuccessToast(res?.message);
                    handleRefresh();
                    handleAddPlayerModal();
                } else if (res.message === "Unauthorised Access") {
                    handleForceLogout(res.message);
                } else {
                    handleFailerToast(res?.message);
                }
            });
        }
    }
    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <PageHeading heading="Voucher List" />
                    <div className="card">
                        <div className="d-flex justify-content-end" style={{ position: "absolute", top: "22px", left: "35px", zIndex: 100 }}>
                            <div style={{ width: "200px" }}>
                                <CustomButton handleClick={handleNewPlayer} title="Add Voucher" iconClass="bx bx-plus" buttonClass="createButton" />
                            </div>
                            <div style={{ width: "200px" }}>
                            </div>
                        </div>
                        <div className="card-body">
                            <PaginationDataTable
                                paginationUrl={props.voucher === "ADMIN_VOUCHER" ? All_Admin_VoucherTemplate_List_POST : All_Agent_VoucherTemplate_List_POST}
                                serverSidePagination={true}
                                search={true}
                                columns={columns}
                            // additionalClassName={"agentSearchInput"}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {show.addVoucher && addPlayerModal()}
            <ToastContainer />
            <ConfirmationDialoge confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
        </>
    );
};
export default VoucherDetails;
