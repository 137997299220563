import { AGENT_LOGIN_URL, LOGIN_URL } from '../CONSTANT.js'
import { SYSTEM_ERROR } from '../Config/CONSTANT';
import axios from 'axios'

export const adminLogin = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(LOGIN_URL(), data)
                .then((res) => {
                    console.log("login > axios res=", res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("signIn > axios err=", err);
                    reject("Error in adminLogin axios!");
                });
        } catch (error) {
            console.error("in adminLogin > adminLogin, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

export const agentLogin = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(AGENT_LOGIN_URL(), data)
                .then((res) => {
                    console.log("login > axios res=", res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("signIn > axios err=", err);
                    reject("Error in agentLogin axios!");
                });
        } catch (error) {
            console.error("in agentLogin > agentLogin, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};