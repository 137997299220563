import {
    Add_New_Coupon_Code_POST,
    Coupon_Code_Activate_PUT,
    Coupon_Code_Deactivate_PUT,
    Coupon_Code_GET,
    Coupon_Code_Revive__PUT,
    Delete_Existing_Coupon_Code_DELETE,
    Update_Existing_Coupon_Code_PUT
} from "../../api/ApiConst.js";
import { PLAYER_URL } from "../CONSTANT.js";
import { SYSTEM_ERROR } from "../Config/CONSTANT.js";
import axios from "axios";
import Cookies from "universal-cookie";

const cook = new Cookies().get("loginData");

const headersData = {
    "content-type": "application/json",
    Accept: "application/json",
    Authorization: cook != undefined ? cook.accessToken : "",
};

export const addCoupon = (data) => {
    return new Promise((resolve, reject) => {
        console.log(data);
        try {
            axios
                .post(Add_New_Coupon_Code_POST, data, { headers: headersData })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in addCupon axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const getCouponDetails = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(Coupon_Code_GET.replaceAll("{recordId}", id), { headers: headersData })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getPlayerDetails axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const deleteCoupon = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .delete(Delete_Existing_Coupon_Code_DELETE.replaceAll("{recordId}", id), { headers: headersData })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in updatePlayerDetails axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const activateCoupon = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(Coupon_Code_Activate_PUT.replaceAll("{recordId}", id), {}, { headers: headersData })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in ActivateAgent axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const deactivateCoupon = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(Coupon_Code_Deactivate_PUT.replaceAll("{recordId}", id), {}, { headers: headersData })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in Deactivate Agent axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const reviveCoupon = (id, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(Coupon_Code_Revive__PUT.replaceAll("{recordId}", id), data, { headers: headersData })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in walletClaimBack axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const updateCouponDetails = (id, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(Update_Existing_Coupon_Code_PUT.replaceAll("{recordId}", id), data, { headers: headersData })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in updatePlayerDetails axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};