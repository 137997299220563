import React, { useCallback, useMemo, useState } from "react";
import PaginationDataTable from "../../Common/PaginationDataTable";
import { Player_Deposit_Money_Request_List_POST } from "../../../api/ApiConst";
import dateUtilities from "../../../utilities/DateUtilities";
import Select from "react-select";


const filterOptions = [
    { value: "", label: "All", },
    { value: "Pending", label: "Pending", },
    { value: "Completed", label: "Completed", },
    { value: "Failed", label: "Failed", },
    { value: "Refunded", label: "Refunded", },
    { value: "Hold", label: "Hold", }
]

const PlayerDepositTransactionTab = ({ playerID }) => {
    const [filter, setFilter] = useState("");

    const withdrawalTransactionColumns = useMemo(
        () => [{
            id: "status",
            name: "",
            selector: (row) => {
                if (row.paymentStatus === "Failed") {
                    return <div className="led-red" title={row.paymentStatus}></div>;
                } else if (row.paymentStatus === "Completed") {
                    return <div className="led-green" title={row.paymentStatus}></div>;
                } else {
                    return <div className="led-yellow" title={row.paymentStatus}></div>;
                }
            },
            width: "60px",
            sortable: false,
        }, {
            id: "paymentTransactionRefId",
            name: "Transaction ID",
            selector: (row) => row.paymentTransactionRefId,
            sortable: false,
        }, {
            id: "createdAt",
            name: "Transaction Date",
            selector: (row) => dateUtilities.formattedDate(row.createdAt, "dd, MM yyyy hh:mm aa"),
            sortable: false,
        }, {
            id: "paymentStatus",
            name: "Transaction Date",
            selector: (row) => (
                <span
                    className={
                        row.paymentStatus === "Pending" || row.paymentStatus === "Hold"
                            ? "text-warning"
                            : row.paymentStatus === "Failed"
                                ? "text-danger"
                                : row.paymentStatus === "Completed"
                                    ? "text-success"
                                    : ""
                    }
                >
                    {row.paymentStatus}
                </span>
            ),
            sortable: false,
        }, {
            id: "requestedAmount",
            name: "Requested Amt.",
            selector: (row) => row.requestedAmount.toFixed(2),
            sortable: false,
            right: true,
        }, {
            id: "amount",
            name: "Amount",
            selector: (row) => row.amount.toFixed(2),
            sortable: false,
            right: true,
        }],
        [filter]
    );

    const getTableView = useCallback(() => {
        return (

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="custom_dropdown">
                                <Select
                                    options={filterOptions}
                                    value={filter}
                                    onChange={(e) => {
                                        setFilter(e);
                                    }}
                                />
                            </div>
                            <PaginationDataTable
                                paginationUrl={Player_Deposit_Money_Request_List_POST.replaceAll("{playerID}", playerID)}
                                serverSidePagination={true}
                                search={true}
                                columns={withdrawalTransactionColumns}
                                filter={{ paymentStatus: filter.value }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }, [filter]);

    return <>{getTableView()}</>;
};

export default PlayerDepositTransactionTab;
