import { STATIC_PAGES_URL } from '../CONSTANT.js'
import { SYSTEM_ERROR } from '../Config/CONSTANT.js';
import axios from 'axios'
import Cookies from 'universal-cookie';

const cook = new Cookies().get('loginData')


const headersdata = {
    'content-type': 'application/json',
    'Accept': 'application/json',
    'Authorization': cook != undefined ? cook.accessToken : ''
}


export const getStaticPagesList = () => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(STATIC_PAGES_URL() + "list",  { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getSettingsList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const getPagesDetails = (pageType) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(STATIC_PAGES_URL() + pageType,  { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getSettingDetails axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const updatePagesKeyValue = (id, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(STATIC_PAGES_URL() + id, data,  { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in updateSettingKeyValue axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};




