import { Add_New_Admin_Lucky_Wheel_POST, Admin_Lucky_Wheel_Activate_PUT, Admin_Lucky_Wheel_Deactivate_PUT, Admin_Lucky_Wheel_GET, Admin_Lucky_Wheel_Revive__PUT, Coupon_Code_Revive__PUT, Delete_Lucky_Wheel_Product_DELETE, Update_Lucky_Wheel_Product_PUT } from "../../api/ApiConst.js";
import { PLAYER_URL } from "../CONSTANT.js";
import { SYSTEM_ERROR } from "../Config/CONSTANT.js";
import axios from "axios";
import Cookies from "universal-cookie";

const cook = new Cookies().get("loginData");

const headersData = {
    "content-type": "application/json",
    Accept: "application/json",
    Authorization: cook != undefined ? cook.accessToken : "",
};

export const addLuckyWheel = (data) => {
    return new Promise((resolve, reject) => {
        console.log(data);
        try {
            axios
                .post(Add_New_Admin_Lucky_Wheel_POST, data, { headers: headersData })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in addCupon axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const getLuckyWheelDetails = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(Admin_Lucky_Wheel_GET.replaceAll("{recordId}", id), { headers: headersData })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getLuckyWheel axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const deleteLuckyWheel = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .delete(Delete_Lucky_Wheel_Product_DELETE.replaceAll("{recordId}", id), { headers: headersData })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in updateLuckyWheel axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const activateLuckyWheel = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(Admin_Lucky_Wheel_Activate_PUT.replaceAll("{recordId}", id), {}, { headers: headersData })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in LuckyWheel axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const deactivateLuckyWheel = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(Admin_Lucky_Wheel_Deactivate_PUT.replaceAll("{recordId}", id), {}, { headers: headersData })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in LuckyWheel axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const reviveLuckyWheel = (id, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(Admin_Lucky_Wheel_Revive__PUT.replaceAll("{recordId}", id), data, { headers: headersData })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in LuckyWheel axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const updateLuckyWheelDetails = (id, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(Update_Lucky_Wheel_Product_PUT.replaceAll("{recordId}", id), data, { headers: headersData })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in updateLuckyWheel axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};