import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { getAvatarList, uploadAvatarImage, updateAvatarDetails, addNewAvatar, getAvatarDetails, deleteAvatarRecord, activateAvatarRecord, deactivateAvatarRecord } from "../Services/index.js";
import { toast, ToastContainer } from "react-toastify";
import ConfirmationDialoge from "./Common/ConfirmationDialoge";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import $ from "jquery";
import PageHeading from "./Common/PageHeading.jsx";
import { All_Avatar_List_POST } from "../api/ApiConst.js";
import PaginationDataTable from "./Common/PaginationDataTable.jsx";

const AvatarMaster = () => {
    const [type, setType] = useState(null);
    const [rows, setRows] = useState([]);
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, isAuth: true, title: "", subTitle: "", severity: "" });
    const [edit, setEdit] = useState(false);
    const [selectedImage, setSelectedImage] = useState("");
    const [assetsImageFormData, setAssetsImageFormData] = useState(null);
    const [id, setId] = useState(null);
    const [avatarName, setAvatarName] = useState("");
    const [bodyFormFileData, setbodyFormFileData] = useState("");
    const [drawCount, setDrawCount] = useState(0);

    const handleRefresh = () => setDrawCount((prev) => prev + 1);

    const validateAddUpdateAssets = () => {
        let inputTag = $("#formrow-inputZip").val();
        if (!(inputTag != "" || ($("#imageId").val() != "" && $("#assetsImageInput").val() != ""))) {
            toast.warn("Please select image first.", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return false;
        }
        return true;
    };

    const handleImageUpload = (e) => {
        var input = e.target;
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = function (ev) {
                setSelectedImage(ev.target.result);
                setAssetsImageFormData(ev.target.result);
            };
            reader.readAsDataURL(input.files[0]);
        }
        let image = e.target.files[0];
        let bodyFormData = new FormData();
        bodyFormData.append("file", image);
        setbodyFormFileData(bodyFormData);
    };

    const handleSave = (e) => {
        e.preventDefault();
        if (avatarName === "") {
            toast("Please enter Avatar name");
            return;
        }
        if (validateAddUpdateAssets(e)) {
            let inputTag = $("#formrow-inputZip").val();
            if (inputTag != "") {
                uploadAvatarImage(bodyFormFileData).then((res) => {
                    if (res.errorCode === 0) {
                        $("#assetsImageInput").val(res.responsePacket);
                        submitForm();
                    }
                });
            } else {
                submitForm();
            }
        }
    };

    const submitForm = () => {
        let request = {
            avatarImageUrl: $("#assetsImageInput").val(),
            avatarName: avatarName,
        };

        // if ($('#imageId').val() != '') {
        if (id) {
            updateAvatarDetails(id, request).then((res) => {
                if (res.errorCode === 0) {
                    toast("👏" + res.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    handleRefresh();
                    handleClearForm();
                    setAvatarName("");
                    setId(null);
                } else if (res.message === "Unauthorised Access") {
                    handleForceLogout(res.message);
                }
            });
        } else {
            addNewAvatar(request).then((res) => {
                if (res.errorCode === 0) {
                    handleClearForm();
                    handleSuccessToast(res?.message);
                    setAvatarName("");
                    handleRefresh();
                } else if (res.message === "Unauthorised Access") {
                    handleForceLogout(res.message);
                }
            });
        }
    };

    const handleClearForm = () => {
        setSelectedImage("");
        setType(null);
        $("#assetsImageInput").val("");
        $("#configform")[0].reset();
    };

    const handleSuccessToast = (data) => {
        toast("👏" + data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const handleEditRow = (id) => {
        setEdit(true);
        setId(id);
        getAvatarDetails(id).then((res) => {
            if (res.errorCode === 0) {
                let details = res.responsePacket;
                let dict = {
                    value: details.adBannerPosition,
                    label: details.adBannerPosition,
                };
                setType(dict);

                $("#imageId").val(res.responsePacket.recordId);

                $("#assetsImageInput").val(details.avatarImageUrl);
                setAvatarName(res.responsePacket.avatarName);
                setSelectedImage(details.avatarImageUrl);
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            }
        });
    };

    const handleDeleteRow = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        deleteAvatarRecord(id).then((res) => {
            if (res.errorCode === 0) {
                toast("👏" + res.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                handleRefresh();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            }
        });
    };

    const handleDeactivateRow = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        deactivateAvatarRecord(id).then((res) => {
            if (res.errorCode === 0) {
                toast("👏" + res.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                handleRefresh();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            }
        });
    };

    const handleForceLogout = (data) => {
        toast("🙁" + data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setConfirmDialog({
            isOpen: true,
            isAuth: true,
            title: "You Are LoggedIn on Another Screen! to Countinue Logout and Login Again!!",
            onConfirm: () => logout(),
        });
    };

    const logout = () => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        const clearCookies = document.cookie.split(";").forEach((cookie) => (document.cookie = cookie.replace(/^ +/, "").replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`)));
        window.location.reload(1);
    };

    const handleActivateRow = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        activateAvatarRecord(id).then((res) => {
            if (res.errorCode === 0) {
                toast("👏" + res.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                handleRefresh();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            }
        });
    };

    const columns = useMemo(
        () => [
            {
                id: "status",
                name: "",
                selector: (row) => {
                    if (row.active) {
                        return <div className="led-green" title="Active"></div>;
                    } else {
                        return <div className="led-red" title="Not Active"></div>;
                    }
                },
                width: "60px",
                sortable: false,
            },
            {
                name: "Image",
                selector: (row) => <img src={row.avatarImageUrl} style={{ width: "75px", height: "75px", objectFit: "scale-down" }} />,
                sortable: true,
                reorder: true,
            },
            {
                name: "Avatar Name",
                selector: (row) => row.avatarName,
                sortable: true,
                reorder: true,
            },
            {
                name: "Actions",
                cell: (row) => (
                    <div className="dropdown">
                        <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                            <KeyboardArrowDownOutlinedIcon />
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                            <li onClick={() => handleEditRow(row.recordId)}>
                                <button className="dropdown-item" type="button">
                                    Edit
                                </button>
                            </li>
                            <li
                                onClick={() =>
                                    setConfirmDialog({
                                        isOpen: true,
                                        title: "Are Your Sure? Your want to delete this record",
                                        onConfirm: () => handleDeleteRow(row.recordId),
                                    })
                                }
                            >
                                <button className="dropdown-item" type="button">
                                    Delete
                                </button>
                            </li>
                            <li>
                                <button className="dropdown-item" type="button">
                                    Something else here
                                </button>
                            </li>
                            {row.active ? (
                                <li
                                    onClick={() =>
                                        setConfirmDialog({
                                            isOpen: true,
                                            title: "Are Your Sure? Your want to Deactivate this player",
                                            onConfirm: () => handleDeactivateRow(row.recordId),
                                        })
                                    }
                                >
                                    <button className="dropdown-item" type="button">
                                        Deactive
                                    </button>
                                </li>
                            ) : (
                                <li
                                    onClick={() =>
                                        setConfirmDialog({
                                            isOpen: true,
                                            title: "Are Your Sure? Your want to Activate this player",
                                            onConfirm: () => handleActivateRow(row.recordId),
                                        })
                                    }
                                >
                                    <button className="dropdown-item" type="button">
                                        Activate
                                    </button>
                                </li>
                            )}
                        </ul>
                    </div>
                ),
                sortable: true,
                right: true,
                reorder: true,
            },
        ],
        [drawCount]
    );

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <PageHeading heading={"Avatar Master"} />
                    <div className="row">
                        <div className="col-xl-5">
                            <div className="card">
                                <div className="card-body">
                                    <form id="configform">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="mb-3">
                                                    <label htmlFor="formrow-inputZip" className="form-label">
                                                        Upload Image
                                                    </label>
                                                    <input type="hidden" id="imageId" />
                                                    <input type="hidden" id="assetsImageInput" />
                                                    <img
                                                        src={selectedImage !== "" ? selectedImage : "https://th.bing.com/th/id/OIP.vx0QOVHGpMq7Furxtzu6KgHaHa?pid=ImgDet&amp;rs=1"}
                                                        id="assetImageImg"
                                                        style={{
                                                            height: "130px",
                                                            width: "100%",
                                                            objectFit: "contain",
                                                            border: "1px solid #7c7979",
                                                            padding: "10px",
                                                        }}
                                                    />
                                                    <input type="file" className="form-control" accept="image/*" id="formrow-inputZip" onChange={(e) => handleImageUpload(e)} />
                                                    <label htmlFor="formrow-inputZip" className="form-label mt-4">
                                                        Avatar Name
                                                    </label>
                                                    <input type="text" className="form-control" value={avatarName} onChange={(e) => setAvatarName(e.target.value)} />
                                                </div>
                                                {/* {edit ? <div><img src={selectedImage.ImageUrl} style={{height: '60px',paddingTop: '7px'}}/></div> : ""} */}
                                            </div>
                                        </div>
                                        <div>
                                            <button type="submit" className="btn btn-primary w-md" onClick={(e) => handleSave(e)}>
                                                {id ? <>Update</> : <>Save</>}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-7">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Avatar List</h4>
                                    <PaginationDataTable drawCount={drawCount} paginationUrl={All_Avatar_List_POST} serverSidePagination={true} search={true} columns={columns} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
            <ConfirmationDialoge confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
        </>
    );
};

export default AvatarMaster;
