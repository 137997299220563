import React, { useState } from "react";
import WalletTransactions from "./WalletTransactions";
import { useEffect } from "react";
import Select from "react-select";
import { callGetApi } from "../../api/ApiCaller";
import { Default_Wallet_List_GET } from "../../api/ApiConst";
import { toast } from "react-hot-toast";
import PageHeading from "./PageHeading";

const WalletDetails = () => {
    const [selectedWallet, setSelectedWallet] = useState({
        recordId: "",
        walletType: "",
        attachedWalletId: "",
    });
    const [defaultWalletList, setDefaultWalletList] = useState([]);

    useEffect(() => {
        getDefaultWalletList();
    }, []);

    const getDefaultWalletList = () => {
        callGetApi(
            Default_Wallet_List_GET,
            {},
            (response) => {
                if (response.success) {
                    const arr = [];
                    response.responsePacket.map((data) => {
                        arr.push({
                            label: data.value,
                            value: data.key,
                        });
                    });
                    setDefaultWalletList(arr);
                    setSelectedWallet(arr[0]);
                } else {
                    toast.error(response.message);
                }
            },
            (error) => {
                toast.error(error.response.data.message);
            }
        );
    };

    const handleWalletSelection = (e) => {
        setSelectedWallet(e);
    };

    return (
        <div className="page-content">
            <div className="container-fluid">
                <PageHeading heading={"Wallet Details"} />
                <div className="row">
                    <div className="col-12">
                        <div className="card mb-0" style={{ boxShadow: "none" }}>
                            <div className="card-body">
                                <div className="row align-items-center mb-4">
                                    <div className="col-lg-8">
                                        <h5 className="mb-0">Transaction History of {selectedWallet.label} A/C</h5>
                                    </div>
                                    <div className="col-lg-4 ms-auto">
                                        <Select
                                            className="basic-single"
                                            value={selectedWallet}
                                            isSearchable={true}
                                            name="wallets"
                                            isRtl={false}
                                            options={defaultWalletList}
                                            onChange={handleWalletSelection}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-4">{selectedWallet.value && selectedWallet.value !== "" && <WalletTransactions playerOrWallet={"WALLET"} ID={selectedWallet.value} />}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WalletDetails;
