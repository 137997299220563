import React, { useCallback, useMemo, useState } from "react";
import PaginationDataTable from "../../Common/PaginationDataTable";
import { Approve_Withdraw_Money_Request_PUT, Player_Withdraw_Money_Request_List_POST, Reject_Withdraw_Money_Request_PUT } from "../../../api/ApiConst";
import { Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { ALREADY_PROCESSED } from "../../../api/constants";
import { callPutApi } from "../../../api/ApiCaller";
import dateUtilities from "../../../utilities/DateUtilities";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import Select from "react-select";

const filterOptions = [
    {
        value: "",
        label: "All",
    },
    {
        value: "Rejected",
        label: "Rejected",
    },
    {
        value: "Approved",
        label: "Approved",
    },
    {
        value: "Completed",
        label: "Completed",
    },
    {
        value: "Failed",
        label: "Failed",
    },
]

const PlayerWithdrawalTransactionTab = ({ playerID }) => {
    const [withdrawalTransactionRefreshFlag, setWithdrawalTransactionRefreshFlag] = useState(0);
    const [showWithdrawalApprovalModal, setShowWithdrawalApprovalModal] = useState(false);
    const [showWithdrawalRejectionModal, setShowWithdrawalRejectionModal] = useState(false);
    const [remark, setRemark] = useState("");
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [withdrawalApproveObj, setWithdrawalApproveObj] = useState({
        referenceId: "",
        transactionId: "",
    });

    const [filter, setFilter] = useState(filterOptions[0]);


    const withdrawalTransactionColumns = useMemo(
        () => [
            {
                id: "status",
                name: "",
                selector: (row) => {
                    if (row.withdrawTransactionStatus === "Pending") {
                        return <div className="led-yellow" title={row.withdrawTransactionStatus}></div>;
                    } else if (row.withdrawTransactionStatus === "Approved") {
                        return <div className="led-green" title={row.withdrawTransactionStatus}></div>;
                    } else {
                        return <div className="led-red" title={row.withdrawTransactionStatus}></div>;
                    }
                },
                width: "60px",
                sortable: false,
            },
            {
                id: "id",
                name: "Transaction ID",
                selector: (row) => <div className="my-3">{row.id}</div>,
                sortable: false,
            },
            {
                id: "createdAtTimeStamp",
                name: "Transaction Date",
                selector: (row) => dateUtilities.formattedDate(row.createdAtTimeStamp, "dd, MM yyyy hh:mm aa"),
                sortable: false,
            },
            {
                id: "remark",
                name: "Remark",
                selector: (row) => (
                    <div data-bs-toggle="tooltip" data-bs-placement="top" title={row.remark}>
                        {row.remark}
                    </div>
                ),
                sortable: false,
            },
            {
                id: "amount",
                name: "Amount",
                selector: (row) => <div>{row.amount.toFixed(2)}</div>,
                sortable: false,
                right: true,
            },
            {
                id: "action",
                name: "",
                selector: (row) => row.withdrawTransactionStatus === "Pending" && getWalletTransactionActionButtons(row),
                width: "80px",
                sortable: false,
            },
        ],
        [withdrawalTransactionRefreshFlag, filter]
    );

    const refreshWithdrawalTable = () => {
        setWithdrawalTransactionRefreshFlag((pre) => pre + 1);
    };

    //Approve Withdrawal
    const handleApproveWithdrawalTransaction = () => {
        if (withdrawalApproveObj.referenceId === "") {
            toast("Please Enter Reference ID");
            return;
        } else if (withdrawalApproveObj.transactionId === "") {
            toast("Please Enter Transaction ID");
            return;
        }
        const url = Approve_Withdraw_Money_Request_PUT.replaceAll("{withdrawTransactionID}", selectedDocument.withdrawRequestId);
        callPutApi(
            url,
            withdrawalApproveObj,
            {},
            (response) => {
                if (response.success) {
                    toast.success(response.message);
                    refreshWithdrawalTable();
                    setShowWithdrawalApprovalModal(false);
                    setSelectedDocument(null);
                    setWithdrawalApproveObj({
                        referenceId: "",
                        transactionId: "",
                    });
                } else if (response.errorCode === ALREADY_PROCESSED) {
                    toast(response.message);
                    setShowWithdrawalApprovalModal(false);
                    setSelectedDocument(null);
                    setWithdrawalApproveObj({
                        referenceId: "",
                        transactionId: "",
                    });
                }
            },
            (error) => {
                toast.error(error.response.data.message);
            }
        );
    };
    // Reject Withdrawal
    const handleRejectWithdrawalTransaction = () => {
        if (remark === "") {
            toast("Please provide Remark");
            return;
        }
        const rObj = {
            remark: remark,
        };
        const url = Reject_Withdraw_Money_Request_PUT.replaceAll("{withdrawTransactionID}", selectedDocument.withdrawRequestId);
        callPutApi(
            url,
            rObj,
            {},
            (response) => {
                if (response.success) {
                    toast.success(response.message);
                    refreshWithdrawalTable();
                    setShowWithdrawalRejectionModal(false);
                    setSelectedDocument(null);
                    setRemark("");
                    setWithdrawalTransactionRefreshFlag((pre) => pre + 1);
                } else if (response.errorCode === ALREADY_PROCESSED) {
                    toast(response.message);
                    setShowWithdrawalRejectionModal(false);
                    setSelectedDocument(null);
                    setRemark("");
                    setWithdrawalTransactionRefreshFlag((pre) => pre + 1);
                } else {
                    toast.error(response.message);
                }
            },
            (error) => {
                toast.error(error.response.data.message);
            }
        );
    };

    const getWalletTransactionActionButtons = useCallback((row) => {
        return (
            <div className="dropdown">
                <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                    <KeyboardArrowDownOutlinedIcon />
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                    <li
                        onClick={() => {
                            setSelectedDocument(row);
                            setShowWithdrawalApprovalModal(true);
                        }}
                    >
                        <button className="dropdown-item" type="button">
                            <i className="bx bx-check-circle"></i> Approve
                        </button>
                    </li>
                    <li
                        onClick={() => {
                            setSelectedDocument(row);
                            setShowWithdrawalRejectionModal(true);
                        }}
                    >
                        <button className="dropdown-item" type="button">
                            <i className="bx bx-x-circle"></i> Reject
                        </button>
                    </li>
                </ul>
            </div>
        );
    }, []);

    // Modals

    const withdrawalApprovalModal = () => {
        return (
            showWithdrawalApprovalModal && (
                <Modal className="tablerowmodal" show={showWithdrawalApprovalModal} onHide={() => setShowWithdrawalApprovalModal(false)} size="md" aria-labelledby="contained-modal-title-vcenter">
                    <Modal.Body>
                        <div className="modal-header border-bottom-0">
                            <h4>Remark</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowWithdrawalApprovalModal(false)}></button>
                        </div>
                        <div className="card-body">
                            <form>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label htmlFor="formrow-email-input" className="form-label">
                                                Third Party Reference ID
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={withdrawalApproveObj.referenceId}
                                                onChange={(e) => setWithdrawalApproveObj({ ...withdrawalApproveObj, referenceId: e.target.value })}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="formrow-email-input" className="form-label">
                                                Third Party Transaction ID
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={withdrawalApproveObj.transactionId}
                                                onChange={(e) => setWithdrawalApproveObj({ ...withdrawalApproveObj, transactionId: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <a onClick={() => handleApproveWithdrawalTransaction()} className="btn btn-danger w-md">
                                        Submit
                                    </a>
                                </div>
                            </form>
                        </div>
                    </Modal.Body>
                </Modal>
            )
        );
    };

    const withdrawalRejectionModal = () => {
        return (
            showWithdrawalRejectionModal && (
                <Modal className="tablerowmodal" show={showWithdrawalRejectionModal} onHide={() => setShowWithdrawalRejectionModal(false)} size="md" aria-labelledby="contained-modal-title-vcenter">
                    <Modal.Body>
                        <div className="modal-header border-bottom-0">
                            <h4>Remark</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowWithdrawalRejectionModal(false)}></button>
                        </div>
                        <div className="card-body">
                            <form>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label htmlFor="formrow-email-input" className="form-label">
                                                Enter Remark
                                            </label>
                                            <textarea type="text" className="form-control" id="formrow-email-input" value={remark} onChange={(e) => setRemark(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <a onClick={() => handleRejectWithdrawalTransaction()} className="btn btn-danger w-md">
                                        Submit
                                    </a>
                                </div>
                            </form>
                        </div>
                    </Modal.Body>
                </Modal>
            )
        );
    };

    const getTableView = useCallback(() => {
        return (
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="custom_dropdown">
                                <Select
                                    options={filterOptions}
                                    value={filter}
                                    onChange={(e) => {
                                        setFilter(e);
                                    }}
                                />
                            </div>
                            <PaginationDataTable
                                paginationUrl={Player_Withdraw_Money_Request_List_POST.replaceAll("{playerID}", playerID)}
                                serverSidePagination={true}
                                search={true}
                                pagination={false}
                                columns={withdrawalTransactionColumns}
                                drawCount={withdrawalTransactionRefreshFlag}
                                filter={{ txnStatus: filter.value }}
                            />
                        </div>
                    </div>
                </div>
            </div>

        );
    }, [withdrawalTransactionRefreshFlag, filter]);

    return (
        <>
            {getTableView()}
            {showWithdrawalApprovalModal && withdrawalApprovalModal()}
            {showWithdrawalRejectionModal && withdrawalRejectionModal()}
        </>
    );
};

export default PlayerWithdrawalTransactionTab;
