import { WITHDRAW_PAGES_URL } from '../CONSTANT.js'
import { SYSTEM_ERROR } from '../Config/CONSTANT.js';
import axios from 'axios'
import Cookies from 'universal-cookie';

const cook = new Cookies().get('loginData')


const headersdata = {
    'content-type': 'application/json',
    'Accept': 'application/json',
    'Authorization': cook != undefined ? cook.accessToken : ''
}

export const getWithdrawRequestList = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(WITHDRAW_PAGES_URL() + "live", data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in addNewSnackLadderAssets axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const getWithdrawRequestHistoryList = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(WITHDRAW_PAGES_URL() + "history", data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getWithdrawRequestHistoryList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const requestApproved = (id, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
            .put(WITHDRAW_PAGES_URL() +"approveRequest/" + id, data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in requestApproved axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
}
export const requestReject = (id, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
            .put(WITHDRAW_PAGES_URL() +"rejectRequest/" + id, data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in requestReject axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
}

