import React, { useEffect, useMemo, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import ConfirmationDialoge from "./Common/ConfirmationDialoge";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { Admin_Store_Product_List_POST, All_Deleted_Admin_Store_Product_List_POST } from "../api/ApiConst";
import PaginationDataTable from "./Common/PaginationDataTable";
import PageHeading from "./Common/PageHeading";
import dateUtilities from "../utilities/DateUtilities";
import CustomButton from "./Common/CustomButton";
import CheckboxButton from "./Common/CheckboxButton";
import { activateStore, addStore, deactivateStore, deleteStore, getStoreDetails, reviveStore, updateStoreDetails } from "../Services/MasterServices/StoreProductService";

const AdminStoreProduct = () => {
    const [show, setShow] = useState({
        addStore: false,
    });
    const [drawCount, setDrawCount] = useState(0);
    const [edit, setEdit] = useState(false);
    const [id, setId] = useState(null);

    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        isAuth: true,
        title: "",
        subTitle: "",
        severity: "",
    });


    const [viewDeletedList, setViewDeletedList] = useState(false);
    const handleToggleDeletedView = () => setViewDeletedList(!viewDeletedList);
    const handleRefresh = () => setDrawCount((prev) => prev + 1);

    const [record, setRecord] = useState({
        productTitle: "",
        appleProductId: "",
        chips: 0,
        diamond: 0,
        goldCard: 0,
        googleProductId: "",
        productDescription: "",
        productPrice: 0,
        productTitle: "",
        purpleCard: 0
    });

    const handleAddStoreModal = () => {
        setShow({ ...show, addStore: false });
        handleClearForm();
    };

    const handleNewStore = () => {
        setEdit(false);
        setShow({ ...show, addStore: true });
        setId(null);
    };

    const handleInputChange = (e) => {
        const { name, value, id } = e.target;
        if (name === 'productTitle' && value.length > 20 || name === 'appleProductId' && value.length > 8) {
            return;
        }
        setRecord({ ...record, [id]: value });
    }


    const handleDeleteStore = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        deleteStore(id).then((res) => {
            if (res.errorCode === 0) {
                handleSuccessToast(res?.message);
                handleRefresh();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            } else {
                handleFailedToast(res.message);
            }
        });
    };

    const handleReviveData = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        reviveStore(id).then((res) => {
            if (res.errorCode === 0) {
                handleSuccessToast(res?.message);
                handleRefresh();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            } else {
                handleFailedToast(res.message);
            }
        });
    }
    const handleStoreDeactivate = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        deactivateStore(id).then((res) => {
            if (res.errorCode === 0) {
                handleSuccessToast(res?.message);
                handleRefresh();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            } else {
                handleFailedToast(res?.message);
            }
        });
    };
    const handleStoreActivate = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        activateStore(id).then((res) => {
            if (res.errorCode === 0) {
                handleSuccessToast(res?.message);
                handleRefresh();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            } else {
                handleFailedToast(res?.message);
            }
        });
    };
    const handleForceLogout = (data) => {
        toast("🙁" + data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setConfirmDialog({
            isOpen: true,
            isAuth: true,
            title: "You Are LoggedIn on Another Screen! to Countinue Logout and Login Again!!",
            onConfirm: () => logout(),
        });
    };

    const logout = () => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        const clearCookies = document.cookie.split(";").forEach((cookie) => (document.cookie = cookie.replace(/^ +/, "").replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`)));
        window.location.reload(1);
    };

    const columns = useMemo(
        () => [{
            id: "status",
            name: "",
            selector: (row) => {
                if (row.active) {
                    return <div className="led-green" productTitle="Active"></div>;
                } else {
                    return <div className="led-red" productTitle="Not Active"></div>;
                }
            },
            width: "60px",
            sortable: false,
        },
        {
            name: "Title",
            selector: (row) => (row.productTitle && row.productTitle !== "" ? row.productTitle : row.productTitle),
            sortable: true,
            wrap: true,
        },
        {
            name: "Price",
            selector: (row) => row?.productPrice.toFixed(2),
            sortable: true,
            width: "100px",
            right: true,
            wrap: true
        },
        {
            name: "Chips",
            selector: (row) => row?.chips,
            sortable: true,
            width: "120px",
            right: true,
            wrap: true
        },
        {
            name: "Diamond",
            selector: (row) => row?.diamond,
            sortable: true,
            width: "120px",
            right: true,
            wrap: true
        },
        {
            name: "Purple Card",
            selector: (row) => row?.purpleCard,
            sortable: true,
            right: true,
            width: "140px",
            wrap: true
        },
        {
            name: "Gold Card",
            selector: (row) => row?.goldCard,
            sortable: true,
            right: true,
            width: "140px",
            wrap: true
        },
        {
            name: "Apple Id",
            selector: (row) => row?.appleProductId,
            sortable: true,
            wrap: true,
            width: "150px",
        },
        {
            name: "Google Id",
            selector: (row) => row?.googleProductId,
            sortable: true,
            width: "150px",
            wrap: true
        },
        {
            name: "Actions",
            cell: (row) => (
                <div className="dropdown">
                    <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                        <KeyboardArrowDownOutlinedIcon />
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                        {!viewDeletedList && (
                            <li onClick={() => handleEditStore(row.uuid)}>
                                <button className="dropdown-item" type="button">
                                    Edit
                                </button>
                            </li>
                        )}
                        {!viewDeletedList && (
                            <li
                                onClick={() =>
                                    setConfirmDialog({
                                        isOpen: true,
                                        title: "Are You Sure? You want to Delete this record",
                                        onConfirm: () => handleDeleteStore(row.uuid),
                                    })
                                }
                            >
                                <button className="dropdown-item" type="button">
                                    Delete
                                </button>
                            </li>
                        )}
                        {viewDeletedList ? (
                            <li
                                onClick={() =>
                                    setConfirmDialog({
                                        isOpen: true,
                                        title: "Are You Sure? You want to Revive this record",
                                        onConfirm: () => handleReviveData(row.uuid),
                                    })
                                }
                            >
                                <button className="dropdown-item" type="button">
                                    Revive
                                </button>
                            </li>
                        ) : (
                            row.active ? (
                                <li
                                    onClick={() =>
                                        setConfirmDialog({
                                            isOpen: true,
                                            title: "Are You Sure? You want to Deactivate this store",
                                            onConfirm: () => handleStoreDeactivate(row.uuid),
                                        })
                                    }
                                >
                                    <button className="dropdown-item" type="button">
                                        Deactivate
                                    </button>
                                </li>
                            ) : (
                                <li
                                    onClick={() =>
                                        setConfirmDialog({
                                            isOpen: true,
                                            title: "Are You Sure? You want to Activate this store",
                                            onConfirm: () => handleStoreActivate(row.uuid),
                                        })
                                    }
                                >
                                    <button className="dropdown-item" type="button">
                                        Activate
                                    </button>
                                </li>
                            )
                        )}
                    </ul>
                </div>
            ),
            sortable: true,
            right: true,
        }
        ],
        [drawCount, viewDeletedList]
    );


    const handleAdd = (e) => {
        e.preventDefault();
        submitForm();
    };

    const submitForm = () => {
        const timestamp = new Date().getTime();
        let request = {
            appleProductId: record?.appleProductId,
            productDescription: record?.productDescription,
            chips: parseInt(record?.chips),
            diamond: parseInt(record?.diamond),
            productPrice: parseInt(record?.productPrice),
            purpleCard: parseInt(record?.purpleCard),
            googleProductId: (record?.googleProductId),
            goldCard: parseInt(record?.goldCard),
            productTitle: record?.productTitle
        };

        if (request.chips === 0 || request.chips === null) {
            handleWarningToast("Please provide chips.");
            return;
        } else if (request.productPrice === 0 || request.productPrice === null) {
            handleWarningToast("Please provide productPrice .");
            return;
        }

        if (id) {
            updateStoreDetails(id, request).then((res) => {
                if (res.errorCode === 0) {
                    handleSuccessToast(res?.message);
                    handleRefresh();
                    handleAddStoreModal();
                    handleClearForm();
                } else if (res.message === "Unauthorised Access") {
                    handleForceLogout(res.message);
                } else {
                    handleFailedToast(res?.message);
                }
            })
        } else {
            addStore(request).then((res) => {
                if (res.errorCode === 0) {
                    handleSuccessToast(res?.message);
                    handleRefresh();
                    handleAddStoreModal();

                    handleClearForm();
                } else if (res.message === "Unauthorised Access") {
                    handleForceLogout(res.message);
                } else {
                    handleFailedToast(res?.message);
                }
            });
        }
    };

    const handleEditStore = (id) => {
        setId(id);
        setShow({ ...show, addStore: true });
        setEdit(true);

        getStoreDetails(id).then((res) => {
            if (res.errorCode === 0) {
                let storeDetails = res.responsePacket;
                let dict = {
                    productTitle: storeDetails?.productTitle,
                    appleProductId: storeDetails?.appleProductId,
                    productDescription: storeDetails?.productDescription,
                    goldCard: storeDetails?.goldCard,
                    diamond: storeDetails?.diamond,
                    googleProductId: storeDetails?.googleProductId,
                    productPrice: storeDetails?.productPrice,
                    purpleCard: storeDetails?.purpleCard,
                    chips: storeDetails?.chips,
                };

                setRecord(dict);
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            }
        });
    };

    const handleWarningToast = (data) => {
        toast.warn(data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    const handleSuccessToast = (data) => {
        toast("👏" + data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    const handleFailedToast = (data) => {
        toast("🙁" + data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    const handleClearForm = () => {
        setRecord({
            productTitle: "",
            appleProductId: "",
            chips: 0,
            diamond: 0,
            goldCard: 0,
            googleProductId: "",
            productDescription: "",
            productPrice: 0,
            productTitle: "",
            purpleCard: 0
        });
    };

    const addStoreModal = () => {
        return (
            <Modal className="tablerowmodal" show={show.addStore} onHide={() => handleAddStoreModal()} size="lg" aria-labelledby="contained-modal-title-vcenter">
                <Modal.Body className="">
                    <div className="modal-header border-bottom-0">
                        <h4>{edit ? "Edit Store" : "Add Store"}</h4>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleAddStoreModal()}></button>
                    </div>
                    <div className="card-body">
                        <form>
                            <div className="row mb-3">
                                <div className="col-6">
                                    <label htmlFor="formrow-password-input" className="form-label">
                                        Title
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="productTitle"
                                        name="productTitle"
                                        value={record.productTitle}
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </div>
                                <div className="col-3">
                                    <label>Apple Product Id</label>
                                    <input type='text'
                                        className="form-control input-default"
                                        id='appleProductId'
                                        name="appleProductId"
                                        value={record.appleProductId}
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </div>
                                <div className="col-3">
                                    <div >
                                        <label htmlFor="formrow-email-input" className="form-label">
                                            Google Product Id
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="googleProductId"
                                            min={0}
                                            value={record.googleProductId}
                                            name="commissionPercentage"
                                            onChange={(e) => handleInputChange(e)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-6">
                                    <label htmlFor="formrow-email-input" className="form-label">
                                        Chips
                                    </label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="chips"
                                        min={0}
                                        value={record.chips}
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </div>
                                <div className="col-6">
                                    <div className="">
                                        <label htmlFor="formrow-email-input" className="form-label">
                                            Product Price
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="productPrice"
                                            min={0}
                                            value={record.productPrice}
                                            onChange={(e) => handleInputChange(e)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-4">
                                    <label htmlFor="formrow-password-input" className="form-label">
                                        Gold Card
                                    </label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="goldCard"
                                        value={record.goldCard}
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </div>
                                <div className="col-4">
                                    <label htmlFor="formrow-password-input" className="form-label">
                                        Diamond
                                    </label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="diamond"
                                        value={record.diamond}
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </div>
                                <div className="col-4">
                                    <label htmlFor="formrow-email-input" className="form-label">
                                        Purple Card
                                    </label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="purpleCard"
                                        min={0}
                                        value={record.purpleCard}
                                        name="commissionPercentage"
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-12">
                                    <label htmlFor="formrow-email-input" className="form-label">
                                        Description
                                    </label>
                                    <textarea
                                        type="text"
                                        rows="3"
                                        className="form-control"
                                        id="productDescription"
                                        value={record.productDescription}
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </div>
                            </div>
                            <div>
                                <a className="btn btn-primary w-md" onClick={handleAdd}>
                                    {edit ? "Update Store" : "Add Store"}
                                </a>
                                &nbsp; &nbsp;
                                <a onClick={() => handleAddStoreModal()} className="btn btn-primary w-md">
                                    Cancel
                                </a>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal >
        );
    };

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <PageHeading heading="Store-List" />
                    <div className="card">
                        <div className="d-flex justify-content-end" style={{ position: "absolute", top: "22px", left: "35px", zIndex: 100 }}>
                            <div style={{ width: "200px" }}>
                                <CustomButton handleClick={handleNewStore} title="Add Store" iconClass="bx bx-plus" buttonClass="createButton" />
                            </div>
                            <div style={{ width: "200px" }}>

                            </div>
                        </div>
                        <div className="card-body">
                            <PaginationDataTable
                                paginationUrl={viewDeletedList ? All_Deleted_Admin_Store_Product_List_POST : Admin_Store_Product_List_POST}
                                serverSidePagination={true}
                                search={true}
                                columns={columns}
                                additionalClassName={"agentSearchInput"}
                            />
                            <CheckboxButton title="Deleted List" handleClick={handleToggleDeletedView} additionalClass="deletedBtn ml-auto" />
                        </div>
                    </div>
                </div>
            </div>
            {show.addStore && addStoreModal()}
            <ToastContainer />
            <ConfirmationDialoge confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
        </>
    );
};

export default AdminStoreProduct;
