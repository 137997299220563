import { Component } from "react";

class FormatMoneyAmountUtils extends Component {
    formatAmount = (amount, decimals = 2) => {
        if (amount) {
            return amount?.toFixed(decimals);
        }
        return null;
    }
}

const formatMoneyAmountUtils = new FormatMoneyAmountUtils();

export default formatMoneyAmountUtils;