import { Component } from "react";
import { format } from "date-fns";

class DateUtilities extends Component {
    formattedDate = (timeStamp, resultFormat) => (timeStamp ? format(new Date(timeStamp), resultFormat) : "");

    timestampToIst = (timestamp) => {
        const istOffset = 330;
        const date = new Date(timestamp);
        const utcDate = new Date(date.toUTCString());
        const istDate = new Date(utcDate.getTime() + (istOffset * 60 * 1000));
        return istDate.getTime();
    }
}

const dateUtilities = new DateUtilities();

export default dateUtilities;
