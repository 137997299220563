import { COUNTRY_URL } from '../CONSTANT.js'
import { SYSTEM_ERROR } from '../Config/CONSTANT.js';
import axios from 'axios'
import Cookies from 'universal-cookie';

const cook = new Cookies().get('loginData')


const headersdata = {
    'content-type': 'application/json',
    'Accept': 'application/json',
    'Authorization': cook != undefined ? cook.accessToken : ''
}


export const getCountryList = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(COUNTRY_URL() + 'list', { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getCountryList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};