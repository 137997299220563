import React, { useEffect, useMemo, useState } from "react";
import {
    getAllPlayerList,
    uploadFile,
    updatePlayerDetails,

    activatePlayer,
    deactivatePlayer,
    deletePlayer,
    walletSendOut,
    walletClaimBack,
    RewardSend,
} from "../../../Services/index";
import { toast, ToastContainer } from "react-toastify";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import $ from "jquery";
import { activateAgentPlayer, addAgentPlayer, agentPlayerClaimBack, agentPlayerSendOut, deactivateAgentPlayer, deleteAgentPlayer, getAgentPlayerDetails, reviveAgentPlayer, updateAgentDetails, updateAgentPlayerDetails, walletWithdrawableBalance } from "../../../Services/MasterServices/PlayerService";
import { All_Agent_PlayerTemplate_List_POST, All_Deleted_Agent_PlayerTemplate_List_POST } from "../../../api/ApiConst";
import ConfirmationDialoge from "../../Common/ConfirmationDialoge";
import CheckboxButton from "../../Common/CheckboxButton";
import PaginationDataTable from "../../Common/PaginationDataTable";
import CustomButton from "../../Common/CustomButton";
import PageHeading from "../../Common/PageHeading";
import dateUtilities from "../../../utilities/DateUtilities";
import { countryCodeList } from "../../../constants/countryCode";

const genderOptions = [
    { value: "MALE", label: "Male", },
    { value: "FEMALE", label: "Female", },
    { value: "OTHER", label: "Other", },
];

const AgentPlayerListMaster = () => {
    const _countryCodeList = countryCodeList.map((value) => ({
        label: value.code,
        value: value.code
    }))
    const [show, setShow] = useState({
        addAgentPlayer: false,
        sendClaim: false,
        reward: false,
        withdrawableBalance: false,
    });
    const [amount, setAmount] = useState(0);
    const [remark, setRemark] = useState(null);
    const [rewardAmount, setRewardAmount] = useState(1);
    const [rewardRemark, setRewardRemark] = useState(null);
    const handleSendClaimModal = () => setShow({ ...show, sendClaim: false });
    const handleWithdrawableBalanceModal = () => setShow({ ...show, withdrawableBalance: false });
    const [drawCount, setDrawCount] = useState(0);
    const [gender, setGender] = useState(null);
    const [DOB, setDOB] = useState(null);
    const [edit, setEdit] = useState(false);
    const [id, setId] = useState(null);
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        isAuth: true,
        title: "",
        subTitle: "",
        severity: "",
    });
    const [bodyFormFileData, setbodyFormFileData] = useState("");
    const [assetsImageFormData, setAssetsImageFormData] = useState(null);
    const [viewDeletedList, setViewDeletedList] = useState(false);
    const [countryKeyValueList, setCountryKeyValueList] = useState([..._countryCodeList]);
    const [country, setCountry] = useState({label: "+91", value: "+91"});


    const handleToggleDeletedView = () => setViewDeletedList(!viewDeletedList);

    const handleRefresh = () => setDrawCount((prev) => prev + 1);

    const handleAddPlayerModal = () => {
        setShow({ ...show, addAgentPlayer: false });
        handleClearForm();
    };

    const handleRewardModal = () => setShow({ ...show, reward: false });

    useEffect(() => {
        if (!show.reward) {
            setRewardRemark("");
        }
        if (!show.sendClaim) {
            setRemark("");
        }
    }, [show]);

    const handleNewPlayer = () => {
        setEdit(false);
        setShow({ ...show, addAgentPlayer: true });
    };

    const handleUserBasic = (e) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value,
        });
    };
    const handleGender = (e) => {
        setGender(e);
    };
    const [user, setUser] = useState({
        fullName: "",
        mobileNumber: "",
        profileImageUrl: "",
    });



    const handleDeletePlayer = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        deleteAgentPlayer(id).then((res) => {
            if (res.errorCode === 0) {
                handleSuccessToast(res?.message);
                handleRefresh();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            } else {
                handleFailerToast(res.message);
            }
        });
    };

    const handleAgentDeactivate = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        deactivateAgentPlayer(id).then((res) => {
            if (res.errorCode === 0) {
                handleSuccessToast(res?.message);
                handleRefresh();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            } else {
                handleFailerToast(res?.message);
            }
        });
    };

    const handleAgentActivate = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        activateAgentPlayer(id).then((res) => {
            if (res.errorCode === 0) {
                handleSuccessToast(res?.message);
                handleRefresh();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            } else {
                handleFailerToast(res?.message);
            }
        });
    };

    const handleForceLogout = (data) => {
        toast("🙁" + data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setConfirmDialog({
            isOpen: true,
            isAuth: true,
            title: "You Are LoggedIn on Another Screen! to Countinue Logout and Login Again!!",
            onConfirm: () => logout(),
        });
    };

    const handleUpdateWallet = (id) => {
        setId(id);
        setShow({
            ...show,
            sendClaim: true,
        });
    };
    const handleClaimBack = () => {
        let temp = {
            amount: amount,
            remark: remark,
        };
        if (remark === "") {
            toast.warn("🙁" + "Remarks Can`t be Empty", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        agentPlayerClaimBack(id, temp).then((res) => {
            if (res.errorCode === 0) {
                toast("👏" + amount + " Claimed Successfully", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                handleRefresh();
                handleSendClaimModal();
                setAmount(0);
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            } else {
                handleFailerToast(res.message);
            }
        });
    };
    const handleSendOut = () => {
        let temp = {
            amount: amount,
            remark: remark,
        };
        if (remark === null) {
            toast.warn("🙁" + "Remarks Can`t be Empty", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        agentPlayerSendOut(id, temp).then((res) => {
            if (res.errorCode === 0) {
                toast("👏" + amount + " Send Successfully", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                handleRefresh();
                handleSendClaimModal();
                setAmount(0);
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            } else {
                handleFailerToast(res.message);
            }
        });
    };

    const logout = () => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        const clearCookies = document.cookie.split(";").forEach((cookie) => (document.cookie = cookie.replace(/^ +/, "").replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`)));
        window.location.reload(1);
    };

    const columns = useMemo(
        () => [{
            id: "status",
            name: "",
            selector: (row) => {
                if (row.active) {
                    return <div className="led-green" title="Active"></div>;
                } else {
                    return <div className="led-red" title="Not Active"></div>;
                }
            },
            width: "60px",
            sortable: false,
        }, {
            id: "image",
            selector: (row, index) => {
                return <span className="profile-img">{row.profileImageUrl ? <img src={row.profileImageUrl} alt={"User"} /> : <img src="/assets/images/user.png" alt={"User"} />}</span>;
            },
            width: "100px",
            sortable: false,
        }, {
            name: "Name",
            selector: (row) => (row.fullName && row.fullName !== "" ? row.fullName : row.nickName),
            sortable: true,
            wrap: true,
            width: "150px",
        }, {
            name: "Mobile",
            selector: (row) => (
                <Link to={"/agent/playerDetail?rId=" + row.recordId} key="t-adminUserDetail">
                    {row.countryCode + " " + row.mobileNumber}
                </Link>
            ),
            sortable: true,
        }, {
            name: "Wallet Balance",
            selector: (row) => <>{process.env.REACT_APP_CURRENCY_SYMBOL} {row.walletBalance.toFixed(2)}</>,
            sortable: true,
            right: true,
            width: "140px",
            wrap: true
        }, {
            name: "Withdraw Balance",
            selector: (row) => <>{process.env.REACT_APP_CURRENCY_SYMBOL} {row.withdrawableBalance.toFixed(2)}</>,
            sortable: true,
            right: true,
            width: "180px",
            wrap: true
        }, {
            name: "Registered At",
            selector: (row) => dateUtilities.formattedDate(row.createdAt, "dd-MM-yyyy hh:mm aa"),
            sortable: true,
            // right: true,
            width: "160px",
            wrap: true
        }, {
            name: "Actions",

            cell: (row) => (
                <div className="dropdown">
                    <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                        <KeyboardArrowDownOutlinedIcon />
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                        {row.active ? (
                            <li
                                onClick={() =>
                                    setConfirmDialog({
                                        isOpen: true,
                                        title: "Are Your Sure? Your want to Deactivate this player",
                                        onConfirm: () => handleAgentDeactivate(row.recordId),
                                    })
                                }
                            >
                                <button className="dropdown-item" type="button">
                                    Deactivate
                                </button>
                            </li>
                        ) : (
                            <li
                                onClick={() =>
                                    setConfirmDialog({
                                        isOpen: true,
                                        title: "Are Your Sure? Your want to Activate this player",
                                        onConfirm: () => handleAgentActivate(row.recordId),
                                    })
                                }
                            >
                                <button className="dropdown-item" type="button">
                                    Activate
                                </button>
                            </li>
                        )}
                        <li onClick={() => handleUpdateWallet(row.recordId)}>
                            <button className="dropdown-item" type="button">
                                Send/Claim
                            </button>
                        </li>
                    </ul>
                </div>
            ),
            sortable: true,
            right: true,
        }],
        [drawCount]
    );
    const handleReviveData = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        reviveAgentPlayer(id).then((res) => {
            if (res.errorCode === 0) {
                handleSuccessToast(res?.message);
                handleRefresh();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            } else {
                handleFailerToast(res.message);
            }
        });
    }
    const handleImageUpload = (e) => {
        var input = e.target;
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = function (ev) {
                $("#assetImageImg").attr("src", ev.target.result);
                setAssetsImageFormData(ev.target.result);
            };
            reader.readAsDataURL(input.files[0]);
        }
        let image = e.target.files[0];
        let bodyFormData = new FormData();
        bodyFormData.append("file", image);
        setbodyFormFileData(bodyFormData);
    };

    const validateAddUpdateAssets = () => {
        let inputTag = $("#formrow-inputZip").val();
        if (!(inputTag != "" || ($("#imageId").val() != "" && $("#assetsImageInput").val() != ""))) {
            toast.warn("Please select image first.", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return false;
        }
        return true;
    };
    const handleCountry = (e) => {
        setCountry(e);
    };
    const handleAddPlayer = (e) => {
        e.preventDefault();
        if (validateAddUpdateAssets(e)) {
            let inputTag = $("#formrow-inputZip").val();
            if (inputTag != "") {
                uploadFile(bodyFormFileData).then((res) => {
                    if (res.errorCode === 0) {
                        $("#assetsImageInput").val(res.responsePacket);
                        submitForm();
                    }
                });
            } else {
                submitForm();
            }
        }
    };

    const submitForm = () => {
        let request = {
            fullName: user?.fullName,
            countryCode: country?.value,
            mobileNumber: user?.mobileNumber,
            gender: gender?.value,
            dateOfBirth: DOB,
            profileImageUrl: $("#assetsImageInput").val(),
        };
        if (request.fullName === "" || request.fullName === null) {
            handleWarningToast("Please provide Full name.");
            return;
        } else if (request.mobileNumber.length === 0 || request?.mobileNumber === null) {
            handleWarningToast("Mobile Number is Required.");
            return;
        } else if (request.mobileNumber.length != 10 || request?.mobileNumber.length === null) {
            handleWarningToast("Please provide valid mobile number.");
            return;
        } else if (request.gender === undefined || request.gender === null) {
            handleWarningToast("Please Select Player Gender !");
            return;
        } else if (request.dateOfBirth === undefined || request.dateOfBirth === null) {
            handleWarningToast("Please Select Date Of Birth !");
            return;
        }
        if ($("#imageId").val() != "") {
            // updateAgentPlayerDetails(id, request).then((res) => {
            //     if (res.errorCode === 0) {
            //         handleSuccessToast(res?.message);
            //         handleRefresh();
            //         handleAddPlayerModal();
            //         handleClearForm();
            //     } else {
            //         handleFailerToast(res?.message);
            //     }
            // });
        } else {
            addAgentPlayer(request).then((res) => {
                if (res.errorCode === 0) {
                    handleSuccessToast(res?.message);
                    handleRefresh();
                    handleAddPlayerModal();
                    handleClearForm();
                } else if (res.message === "Unauthorised Access") {
                    handleForceLogout(res.message);
                } else {
                    handleFailerToast(res?.message);
                }
            });
        }
    };

    const handleEditPlayer = (id) => {
        setId(id);
        setShow({ ...show, addAgentPlayer: true });
        setEdit(true);
        getAgentPlayerDetails(id).then((res) => {
            if (res.errorCode === 0) {
                let userDetails = res.responsePacket;
                // const getDate = dateUtilities.formattedDate(userDetails?.dateOfBirth, "yyyy-MM-dd");
                let dict = {
                    fullName: userDetails?.fullName,
                    mobileNumber: userDetails?.mobileNumber,
                    profileImageUrl: userDetails?.profileImageUrl
                };
                setUser(dict);
                setDOB(userDetails?.dateOfBirth);
                let d = {
                    value: userDetails?.gender,
                    label: userDetails?.gender,
                };
                setGender(d);
                $("#imageId").val(res.responsePacket.recordId);

                $("#assetsImageInput").val(userDetails.profileImageUrl);
                // setSelectedImage(userDetails.profileImageUrl)

            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            }
        });
    };

    const handleWarningToast = (data) => {
        toast.warn(data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const handleSuccessToast = (data) => {
        toast("👏" + data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const handleFailerToast = (data) => {
        toast("🙁" + data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const handleClearForm = () => {
        setUser({
            fullName: "",
            mobileNumber: "",
            profileImageURL: "",
        });
        setGender("");
        setDOB(new Date());
        // setSelectedImage(null)
    };

    const sendClaimModal = () => {
        return (
            <Modal className="tablerowmodal" show={show.sendClaim} onHide={() => handleSendClaimModal()} size="md" aria-labelledby="contained-modal-title-vcenter">
                <Modal.Body className="">
                    <div className="modal-header border-bottom-0">
                        <h4>Send And Claim</h4>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleSendClaimModal()}></button>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <label htmlFor="formrow-email-input" className="form-label">
                                        Enter Amount
                                    </label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="formrow-email-input"
                                        placeholder="Enter Amount"
                                        min={1}
                                        value={amount}
                                        step={0.01}
                                        onChange={(e) => setAmount(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <label htmlFor="formrow-email-input" className="form-label">
                                        Enter Remarks
                                    </label>
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        id="formrow-email-input"
                                        placeholder="Enter Remarks"
                                        value={remark}
                                        onChange={(e) => setRemark(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <button className="btn btn-primary w-md" onClick={handleSendOut}>
                                Send Out
                            </button>
                            &nbsp; &nbsp;
                            <button onClick={handleClaimBack} className="btn btn-danger w-md">
                                Claim Back
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    };

    const addPlayerModal = () => {
        return (
            <Modal className="tablerowmodal" show={show.addAgentPlayer} onHide={() => handleAddPlayerModal()} size="lg" aria-labelledby="contained-modal-title-vcenter">
                <Modal.Body className="">
                    <div className="modal-header border-bottom-0">
                        <h4>Add Player</h4>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleAddPlayerModal()}></button>
                    </div>
                    <div className="card-body">
                        <form>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="formrow-email-input" className="form-label">
                                            Enter Full Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="formrow-email-input"
                                            placeholder="Enter Full Name"
                                            name="fullName"
                                            value={user.fullName}
                                            onChange={(e) => handleUserBasic(e)}
                                        />
                                    </div>
                                    <div className="d-flex justify-content-between ">
                                        <div className="col-4">
                                            <div className="mb-3">
                                                <label htmlFor="formrow-password-input" className="form-label">
                                                    Select Country
                                                </label>
                                                <Select options={countryKeyValueList} value={country} onChange={(e) => handleCountry(e)} />
                                            </div>
                                        </div>
                                        <div className="col-7">
                                            <div className="mb-3">
                                                <label htmlFor="formrow-email-input" className="form-label">
                                                    Enter Mobile Number
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="formrow-email-input"
                                                    placeholder="Enter Mobile Number"
                                                    name="mobileNumber"
                                                    value={user.mobileNumber}
                                                    onChange={(e) => handleUserBasic(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between ">
                                        <div className="col-4">
                                            <div className="mb-3">
                                                <label htmlFor="formrow-password-input" className="form-label">
                                                    Select Gender
                                                </label>
                                                <Select options={genderOptions} value={gender} onChange={(e) => handleGender(e)} />
                                            </div>
                                        </div>
                                        <div className="col-7">
                                            <div className="mb-3">
                                                <label htmlFor="formrow-password-input" className="form-label">
                                                    Select DOB
                                                </label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    id="formrow-password-input"
                                                    placeholder="Enter Your Password"
                                                    onChange={(e) => setDOB(e.target.value)}
                                                    value={DOB}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="formrow-password-input" className="form-label">
                                            Upload Image
                                        </label>
                                        {/* <input
                              type="file"
                              className="form-control"
                              accept="image/*"
                              id="formrow-password-input"
                              placeholder="Enter Your Password"
                              name="profileImageUrl"
                              onChange={(e) => handleImageUpload(e)}
                            />
                            {edit ? <div><img src={user.profileImageUrl} style={{height: '60px',paddingTop: '7px'}}/></div> : ""} */}

                                        <input type="hidden" id="imageId" />
                                        <input type="hidden" id="assetsImageInput" />
                                        <img
                                            src={user.profileImageUrl != "" ? user.profileImageUrl : "https://th.bing.com/th/id/OIP.vx0QOVHGpMq7Furxtzu6KgHaHa?pid=ImgDet&amp;rs=1"}
                                            id="assetImageImg"
                                            style={{
                                                height: "130px",
                                                width: "100%",
                                                objectFit: "contain",
                                                border: "1px solid #7c7979",
                                                padding: "10px",
                                            }}
                                        />
                                        <input type="file" className="form-control" accept="image/*" id="formrow-inputZip" onChange={(e) => handleImageUpload(e)} />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <a className="btn btn-primary w-md" onClick={handleAddPlayer}>
                                    {edit ? "Update Player" : "Add Player"}
                                </a>
                                &nbsp; &nbsp;
                                <a onClick={() => handleAddPlayerModal()} className="btn btn-primary w-md">
                                    Cancel
                                </a>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        );
    };

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <PageHeading heading="Player List" />
                    <div className="card">
                        <div className="d-flex justify-content-end" style={{ position: "absolute", top: "22px", left: "35px", zIndex: 100 }}>
                            <div style={{ width: "200px" }}>
                                <CustomButton handleClick={handleNewPlayer} title="Add Player" iconClass="bx bx-plus" buttonClass="createButton" />
                            </div>
                            <div style={{ width: "200px" }}>

                            </div>
                        </div>
                        <div className="card-body">
                            <PaginationDataTable
                                paginationUrl={viewDeletedList ? All_Deleted_Agent_PlayerTemplate_List_POST : All_Agent_PlayerTemplate_List_POST}
                                serverSidePagination={true}
                                search={true}
                                columns={columns}
                            // additionalClassName={"agentSearchInput"}
                            />
                            {/* <CheckboxButton title="Deleted List" handleClick={handleToggleDeletedView} additionalClass="deletedBtn ml-auto" /> */}
                        </div>
                    </div>
                </div>
            </div>
            {show.sendClaim && sendClaimModal()}
            {/* {show.withdrawableBalance && withdrawableBalanceModal()} */}
            {/* {show.reward && rewardModal()} */}
            {show.addAgentPlayer && addPlayerModal()}
            <ToastContainer />
            <ConfirmationDialoge confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
        </>
    );
};

export default AgentPlayerListMaster;
