import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import ConfirmationDialoge from "./Common/ConfirmationDialoge";
import { updatePassword } from "../Services/index.js";
import { toast, ToastContainer } from "react-toastify";
import { Modal } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { callGetApi } from "../api/ApiCaller";
import { Get_Header_Count_GET, Pre_Launch_Data_GET } from "../api/ApiConst";
import useWidowResize from "../customHooks/useWidowResize";
import { changeAgentPassword } from "../Services/MasterServices/ProfileService.js";
const cook = new Cookies().get("loginData");
const pathName = new Cookies().get("currentUser")
const HeaderBar = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [open, setOpen] = useState(false);
    const [headerCount, setHeaderCount] = useState(null);
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, isAuth: true, title: "", subTitle: "", severity: "" });
    const [user, setUser] = useState({
        oldPassword: "",
        newPassword: "",
    });
    const [windowWidth, windowHeight] = useWidowResize()
    // TODO    : ADD NEW Image Folder 
    const imageFilePathNames = ["Benno Poker", "Bunzzi Ludo", "Kpis", "GamzCorner", "Poker99", "Revolution",
        "SpinItUpLudo"];
    const handleUpdatePassword = () => {
        let request = {
            oldPassword: user?.oldPassword,
            newPassword: user?.newPassword,
        };
        if (cook?.userType === "ROLE_ADMIN" || cook?.userType === "ROLE_SUPER_ADMIN") {
            updatePassword(request).then((res) => {
                if (res.errorCode === 0) {
                    toast("👏" + res.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setOpen(false);
                    setUser({
                        oldPassword: "",
                        newPassword: "",
                    });
                } else {
                    toast("🙁" + res.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            });
        } else {
            changeAgentPassword(request).then((res) => {
                if (res.errorCode === 0) {
                    toast("👏" + res.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setOpen(false);
                    setUser({
                        oldPassword: "",
                        newPassword: "",
                    });
                } else {
                    toast("🙁" + res.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            });

        }
    };
    const handleLogout = () => {
        confirmAlert({
            title: "Logout",
            message: "Are you sure to logout ?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        const cookies = new Cookies();
                        cookies.remove("loginData");
                        cookies.remove("loginAuth");
                        // window.location.href = pathName;
                        window.location.href = "/";
                    },
                },
                {
                    label: "No",
                    // onClick: () => alert('Click No')
                },
            ],
        });
    };

    const cookies = new Cookies();
    const getSmLogo = () => {
        const profile = cookies.get("profile");
        if (profile && profile !== undefined && imageFilePathNames.includes(profile)) {
            return `/assets/images/profile/${profile}/logo-sm.png`;
        }
        return `/assets/images/profile/Development/logo-sm.png`;
    };

    const getLgLogo = () => {
        const profile = cookies.get("profile");

        if (profile && profile !== undefined && imageFilePathNames.includes(profile)) {
            return `/assets/images/profile/${profile}/logo-lg.png`;
        }
        return `/assets/images/profile/Development/logo-lg.png`;
    };


    useEffect(() => {
        if (cook?.userType === "ROLE_ADMIN" || cook?.userType === "ROLE_SUPER_ADMIN") {
            callGetApi(Get_Header_Count_GET, {}, (response) => {
                setHeaderCount(response.responsePacket);
            });
        }
    }, [pathname]);

    const handleAddCustomClass = () => {
        if (document.body.classList.contains('body-overlay') || document.body.classList.contains('sidebar-enable') || document.body.classList.contains('vertical-collpsed')) {
            document.body.classList.remove("body-overlay")
            document.body.classList.remove("sidebar-enable")
            document.body.classList.remove("vertical-collpsed")

        } else {
            document.body.classList.add("sidebar-enable")
            if (windowWidth >= 992) {
                document.body.classList.add("vertical-collpsed")
            }

            if (windowWidth < 999) {
                // document.body.classList.add("body-overlay")
            }
        }
    }

    return (
        <>
            <header id="page-topbar">
                <div className="navbar-header">
                    <div className="d-flex">
                        {/* LOGO */}
                        <div className="navbar-brand-box">
                            <a href="#" className="logo logo-dark">
                                <span className="logo-sm">
                                    <img src={getSmLogo()} alt="" height={30} />
                                </span>
                                <span className="logo-lg">
                                    <img src={getLgLogo()} alt="" height={50} />
                                </span>
                            </a>
                            <a href="#" className="logo logo-light">
                                <span className="logo-sm">
                                    <img src={getSmLogo()} alt="" height={30} />
                                </span>
                                <span className="logo-lg">
                                    <img src={getLgLogo()} alt="" height={50} />
                                </span>
                            </a>
                        </div>
                        <button type="button" className="btn btn-sm px-3 font-size-16 header-item waves-effect" id="vertical-menu-btn" onClick={handleAddCustomClass}>
                            <i className="fa fa-fw fa-bars" />
                        </button>
                    </div>
                    <div className="d-flex align-items-center">
                        {headerCount ? (
                            <>
                                {headerCount.kycPendingCount ? (
                                    <button type="button" className="btn btn-primary me-3 position-relative" onClick={() => navigate("/players/Pending")}>
                                        Pending KYC
                                        <span className="badge badge-light text-white ml-2 position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle badge-nu">
                                            {headerCount.kycPendingCount}
                                        </span>
                                    </button>
                                ) : (
                                    <></>
                                )}
                                {headerCount.pendingWithdrawRequestCount ? (
                                    <button type="button" className="btn btn-primary me-3 position-relative" onClick={() => navigate("/withdraw-request")}>
                                        Pending Withdrawal
                                        <span className="badge badge-light text-white ml-2 position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle badge-nu">
                                            {headerCount.pendingWithdrawRequestCount}
                                        </span>
                                    </button>
                                ) : (
                                    <></>
                                )}
                                {headerCount.runningTableCountOfLudo ? (
                                    <button type="button" className="btn btn-primary me-3 position-relative" onClick={() => navigate("/ludo-running-game")}>
                                        Ludo
                                        <span className="badge badge-light text-white ml-2 position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle badge-nu">
                                            {headerCount.runningTableCountOfLudo}
                                        </span>
                                    </button>
                                ) : (
                                    <></>
                                )}
                                {headerCount.runningTableCountOfSnakeLadder ? (
                                    <button type="button" className="btn btn-primary me-3 position-relative" onClick={() => navigate("/snake-ladder-running-game")}>
                                        Snake & Ladder
                                        <span className="badge badge-light text-white ml-2 position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle badge-nu">
                                            {headerCount.runningTableCountOfSnakeLadder}
                                        </span>
                                    </button>
                                ) : (
                                    <></>
                                )}
                                {headerCount.runningTableCountOfPoker ? (
                                    <button type="button" className="btn btn-primary me-3 position-relative" onClick={() => navigate("/poker-running-game")}>
                                        Poker
                                        <span className="badge badge-light text-white ml-2 position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle badge-nu">
                                            {headerCount.runningTableCountOfPoker}
                                        </span>
                                    </button>
                                ) : (
                                    <></>
                                )}
                            </>
                        ) : (
                            <> </>
                        )}
                        <div className="dropdown d-inline-block">
                            <button type="button" className="btn header-item waves-effect" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <img className="rounded-circle header-profile-user" src={getSmLogo()} alt="Header Avatar" />
                                <span className="d-none d-xl-inline-block ms-1" key="t-henry">
                                    {cook.fullName}
                                </span>
                                <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
                            </button>
                            <div className="dropdown-menu dropdown-menu-end">
                                <a onClick={() => setOpen(true)} style={{ cursor: "pointer" }} className="dropdown-item">
                                    <i className="bx bx-key font-size-16 align-middle me-1" />
                                    <span key="t-profile">Change Password</span>
                                </a>
                                <div className="dropdown-divider" />
                                <a className="dropdown-item text-danger" href="#" onClick={() => handleLogout()}>
                                    <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                                    <span key="t-logout">Logout</span>
                                </a>
                            </div>
                        </div>
                        <div className="dropdown d-inline-block">
                            <button type="button" className="btn header-item noti-icon right-bar-toggle waves-effect">
                                <i className="bx bx-cog bx-spin" />
                            </button>
                        </div>
                    </div>
                </div>
            </header>
            <Modal className="tablerowmodal" size="lg" show={open} onClose={() => setOpen(false)} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Body className="">
                    <div className="modal-header border-bottom-0">
                        <h4>Change Password</h4>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => setOpen(false)} aria-label="Close"></button>
                    </div>
                    <div className="card-body">
                        <form>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="formrow-email-input" className="form-label">
                                            Enter Old Password
                                        </label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="formrow-email-input"
                                            placeholder="Enter Old Password"
                                            value={user.oldPassword}
                                            onChange={(e) => setUser({ ...user, oldPassword: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="formrow-email-input" className="form-label">
                                            Enter New Password
                                        </label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="formrow-email-input"
                                            placeholder="Enter New Password"
                                            value={user.newPassword}
                                            onChange={(e) => setUser({ ...user, newPassword: e.target.value })}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <a className="btn btn-primary w-md" onClick={() => handleUpdatePassword()}>
                                    Change Password
                                </a>
                                &nbsp; &nbsp;
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
            <ToastContainer />
            <ConfirmationDialoge confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
        </>
    );
};

export default HeaderBar;
