import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import React from "react";

const ConfirmationDialoge = (props) => {
    const { confirmDialog, setConfirmDialog } = props;

    const hideBodyClass = () => {
        document.body.style.overflow = "auto";
    };

    return (
        <>
            <Dialog open={confirmDialog.isOpen}>
                <DialogTitle></DialogTitle>

                <DialogContent>
                    <Typography varient="h6">{confirmDialog.title}</Typography>
                    <Typography varient="subtitle2">{confirmDialog.subTitle}</Typography>
                </DialogContent>

                <DialogActions>
                    {confirmDialog?.isAuth ? (
                        <></>
                    ) : (
                        <Button
                            type="text"
                            onClick={() => {
                                setConfirmDialog({ ...confirmDialog, isOpen: false });
                                hideBodyClass();
                            }}
                        >
                            No
                        </Button>
                    )}
                    <Button
                        type="text"
                        onClick={() => {
                            confirmDialog.onConfirm();
                            hideBodyClass();
                        }}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ConfirmationDialoge;
