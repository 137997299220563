import { SNACK_LADDER_URL } from '../CONSTANT.js'
import { SYSTEM_ERROR } from '../Config/CONSTANT.js';
import axios from 'axios'
import Cookies from 'universal-cookie';

const cook = new Cookies().get('loginData')


const headersdata = {
    'content-type': 'application/json',
    'Accept': 'application/json',
    'Authorization': cook != undefined ? cook.accessToken : ''
}


export const addNewSnackLadderAssets = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(SNACK_LADDER_URL(), data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in addNewSnackLadderAssets axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const getSnackLadderList = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(SNACK_LADDER_URL() + "list", data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in addNewSnackLadderAssets axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const updateSnakeLadderDetails = (id,data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(SNACK_LADDER_URL() + id, data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in updateSnakeLadderDetails axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const getSnakeLadderDetails = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(SNACK_LADDER_URL() + id, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getSnakeLadderDetails axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const deleteSnakeLadderRecord = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .delete(SNACK_LADDER_URL() + id, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in deleteSnakeLadderRecord axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const activateSnakeLadderRecord = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(SNACK_LADDER_URL() +"activate/"+ id, {}, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in activateSnakeLadderRecord axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const deactivateSnakeLadderRecord = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(SNACK_LADDER_URL() +"deactivate/"+ id, {}, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in deactivateSnakeLadderRecord axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};