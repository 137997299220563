import React from 'react';
import GameSession from './Common/GameSession';
import PageHeading from './Common/PageHeading';

const AdminGameSession = () => {
    return (
        <div className='page-content'>
            <div className='container-fluid'>
                <PageHeading heading='Game Session' />
                <div className='card'>
                    <div className='card-body'>
                        <GameSession />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminGameSession;
