import React from 'react';

const PageHeading = ({heading, maintenanceFlag}) => {
    return (
        <div className='row'>
            <div className='col-12'>
                <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
                    <h4 className='mb-sm-0 font-size-18'>{heading}<span className='text-warning'>{maintenanceFlag === "true" ? " (Under maintenance)" : ""}</span></h4>
                </div>
            </div>
        </div>
    );
};

export default PageHeading;
