import React, { useEffect, useState } from "react";
import {
    Approve_Player_KYC_Document_PUT,
    Player_KYC_Document_List_POST,
    Player_Update_KYC_Verification_Status_PUT,
    Reject_Player_KYC_Document_PUT,
} from "../../../api/ApiConst";
import { Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { ALREADY_PROCESSED } from "../../../api/constants";
import { callPostApi, callPutApi } from "../../../api/ApiCaller";

const PlayerDocumentTab = ({
    playerID,
    playerDetails,
    refreshPlayerDetails,
}) => {
    const [showRejectionModal, setShowRejectionModal] = useState(false);
    const [remark, setRemark] = useState("");
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [documentList, setDocumentList] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [showExpandImageModal, setShowExpandImageModal] = useState(false);

    useEffect(() => {
        if (!documentList) {
            getAllDocuments();
        }
    }, []);

    const getAllDocuments = () => {
        var jsonRequest = {
            pageNumber: 0,
            pageSize: 10,
            search: "",
        };
        let url = Player_KYC_Document_List_POST.replaceAll(
            "{playerID}",
            playerID
        );
        callPostApi(
            url,
            jsonRequest,
            {},
            (response) => {
                setDocumentList(response.responsePacket);
            },
            (error) => {
                console.log(error);
            }
        );
    };

    //Approve Document
    const handleApproveDocument = (document) => {
        const url = Approve_Player_KYC_Document_PUT.replaceAll(
            "{documentID}",
            document.recordId
        );
        callPutApi(
            url,
            {},
            {},
            (response) => {
                if (response.success) {
                    toast.success(response.message);
                    getAllDocuments();
                } else {
                    toast.error(response.message);
                }
            },
            (error) => {
                toast.error(error.response.data.message);
            }
        );
    };

    // Reject Document
    const handleRejectDocument = () => {
        if (remark === "") {
            toast("Please provide Rejection Note");
            return;
        }
        const rObj = {
            rejectionNote: remark,
        };
        const url = Reject_Player_KYC_Document_PUT.replaceAll(
            "{documentID}",
            selectedDocument.recordId
        );
        callPutApi(
            url,
            rObj,
            {},
            (response) => {
                if (response.success) {
                    toast.success(response.message);
                    setShowRejectionModal(false);
                    setSelectedDocument(null);
                    setRemark("");
                    getAllDocuments();
                } else if (response.errorCode === ALREADY_PROCESSED) {
                    toast(response.message);
                    setShowRejectionModal(false);
                    setSelectedDocument(null);
                    setRemark("");
                    getAllDocuments();
                } else {
                    toast.error(response.message);
                }
            },
            (error) => {
                toast.error(error.response.data.message);
            }
        );
    };
    // Modals
    const documentRejectionModal = () => {
        return (
            showRejectionModal && (
                <Modal
                    className="tablerowmodal"
                    show={showRejectionModal}
                    onHide={() => setShowRejectionModal(false)}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <Modal.Body>
                        <div className="modal-header border-bottom-0">
                            <h4>Reject Note</h4>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => setShowRejectionModal(false)}
                            ></button>
                        </div>
                        <div className="card-body">
                            <form>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label
                                                htmlFor="formrow-email-input"
                                                className="form-label"
                                            >
                                                Enter Rejection Note
                                            </label>
                                            <textarea
                                                type="text"
                                                className="form-control"
                                                id="formrow-email-input"
                                                value={remark}
                                                onChange={(e) =>
                                                    setRemark(e.target.value)
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <a
                                        onClick={() => handleRejectDocument()}
                                        className="btn btn-danger w-md"
                                    >
                                        Submit
                                    </a>
                                </div>
                            </form>
                        </div>
                    </Modal.Body>
                </Modal>
            )
        );
    };

    const updatePlayerKYCStatus = (status) => {
        const rObj = {
            kycVerificationStatus: status,
        };
        const url = Player_Update_KYC_Verification_Status_PUT.replaceAll(
            "{playerID}",
            playerID
        );
        callPutApi(
            url,
            rObj,
            {},
            (response) => {
                if (response.success) {
                    toast.success(response.message);
                    refreshPlayerDetails();
                }
            },
            (error) => {
                toast.error(error.response.data.message);
            }
        );
    };

    useEffect(() => {
        if (!showExpandImageModal) {
            setSelectedImage(null);
        }
    }, [showExpandImageModal]);

    const handleExpandImage = (img) => {
        setSelectedImage(img);
        setShowExpandImageModal(true);
    };

    const expandableImageModal = () => {
        return (
            showExpandImageModal && (
                <Modal
                    className="tablerowmodal"
                    show={showExpandImageModal}
                    onHide={() => setShowExpandImageModal(false)}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <Modal.Body>
                        <div className="border-bottom d-flex justify-content-end align-items-center pb-3">
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => setShowExpandImageModal(false)}
                            ></button>
                        </div>
                        <div className="text-center">
                            <img
                                src={selectedImage}
                                alt=""
                                className="img-fluid mx-auto"
                                style={{
                                    height: "100vh",
                                    width: "auto",
                                    objectFit: "contain",
                                }}
                            />
                        </div>
                    </Modal.Body>
                </Modal>
            )
        );
    };

    const viewPancardDetailTable = (data) => {
        return (
            <div className="card h-100">
                <div className="card-body">
                    <fieldset>
                        <table className="table table-striped mb-0">
                            <tbody>
                                <tr>
                                    <th className=" fw-medium text-secondary fs-6 w-25 ">
                                        Owner Type
                                    </th>
                                    <td className="w-50 text-secondary">
                                        {data.panCardOwnerType}
                                    </td>
                                </tr>
                                <tr>
                                    <th className=" fw-medium text-secondary fs-6">
                                        Name
                                    </th>
                                    <td className="w-50 text-secondary">
                                        {data.panCardOwnerName}
                                    </td>
                                </tr>
                                <tr>
                                    <th className=" fw-medium text-secondary fs-6">
                                        Age
                                    </th>
                                    <td className="w-50 text-secondary">
                                        {data.panCardOwnerAge}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </fieldset>
                </div>
            </div>
        );
    };

    const viewAadharDetailTable = (data) => {
        return (
            <div className="card h-100 ">
                <div className="card-body">
                    <fieldset>
                        <table className="table table-striped mb-0">
                            <tbody>
                                <tr>
                                    <th className=" fw-medium text-secondary fs-6 w-25 ">
                                        Name
                                    </th>
                                    <td className="w-50 text-secondary">
                                        {data.aadharCardOwnerName}
                                    </td>
                                </tr>
                                <tr>
                                    <th className=" fw-medium text-secondary fs-6">
                                        Gender
                                    </th>
                                    <td className="w-50 text-secondary">
                                        {data.aadharCardOwnerGender}
                                    </td>
                                </tr>
                                <tr>
                                    <th className=" fw-medium text-secondary fs-6">
                                        Year of birth
                                    </th>
                                    <td className="w-50 text-secondary">
                                        {data.aadharCardOwnerYob}
                                    </td>
                                </tr>
                                <tr>
                                    <th className=" fw-medium text-secondary fs-6">
                                        Address
                                    </th>
                                    <td className="w-50 text-secondary">
                                        {data.aadharCardOwnerAddress}
                                    </td>
                                </tr>
                                <tr>
                                    <th className=" fw-medium text-secondary fs-6">
                                        Pin code
                                    </th>
                                    <td className="w-50 text-secondary">
                                        {data.aadharCardOwnerPinCode}
                                    </td>
                                </tr>
                                <tr>
                                    <th className=" fw-medium text-secondary fs-6">
                                        State
                                    </th>
                                    <td className="w-50 text-secondary">
                                        {data.aadharCardOwnerState}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </fieldset>
                </div>
            </div>
        );
    };

    return (
        <div>
            <div className="card shadow-none my-0">
                <div className="card-body">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                            <h5 className="m-0 fw-bold font-size-15">
                                KYC Status
                            </h5>
                            <span
                                className={`font-size-12 fw-bold ps-2
                                        ${playerDetails?.kycVerificationStatus?.toLowerCase() ===
                                        "pending"
                                        ? "text-warning"
                                        : playerDetails?.kycVerificationStatus?.toLowerCase() ===
                                            "approved"
                                            ? "text-success"
                                            : "text-danger"
                                    }
                    `}
                            >
                                ({playerDetails?.kycVerificationStatus})
                            </span>
                        </div>
                        <div className="d-flex justify-content-end">
                            {playerDetails?.kycVerificationStatus?.toLowerCase() !==
                                "pending" && (
                                    <button
                                        className="btn btn-warning mx-2"
                                        onClick={() =>
                                            updatePlayerKYCStatus("Pending")
                                        }
                                    >
                                        Pending
                                    </button>
                                )}
                            {playerDetails?.kycVerificationStatus?.toLowerCase() !==
                                "approved" && (
                                    <button
                                        className="btn btn-success mx-2"
                                        onClick={() =>
                                            updatePlayerKYCStatus("Approved")
                                        }
                                    >
                                        Approved
                                    </button>
                                )}
                            {playerDetails?.kycVerificationStatus?.toLowerCase() !==
                                "rejected" && (
                                    <button
                                        className="btn btn-danger mx-2"
                                        onClick={() =>
                                            updatePlayerKYCStatus("Rejected")
                                        }
                                    >
                                        Reject
                                    </button>
                                )}
                        </div>
                    </div>
                </div>
            </div>
            <hr className="mt-0 mb-3" />
            {documentList &&
                documentList.map((data) => (
                    <div className="card border" key={data.recordId}>
                        <div className="card-body">
                            <div className="d-flex justify-content-between align-items-start">
                                <div className="d-flex align-items-center">
                                    <h6 className="m-0 fw-bold font-size-15">
                                        {data.documentType}
                                    </h6>
                                    <span
                                        className={`font-size-12 fw-bold ps-2
                                        ${data.verificationStatus.toLowerCase() ===
                                                "pending"
                                                ? "text-warning"
                                                : data.verificationStatus.toLowerCase() ===
                                                    "approved"
                                                    ? "text-success"
                                                    : "text-danger"
                                            }
                                    `}
                                    >
                                        ({data.verificationStatus})
                                    </span>
                                    <h5 className="m-0 ps-4">
                                        {data.documentNumber}
                                    </h5>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <button className="btn btn-success me-2" onClick={() => handleApproveDocument(data)}> Accept </button>
                                    <button className="btn btn-danger" onClick={() => { setSelectedDocument(data); setShowRejectionModal(true); }}> Reject </button>
                                </div>
                            </div>
                            <div className="mt-2">
                                <div className="row">
                                    <div className="col-8">
                                        {data.documentType === "PanCard"
                                            ? viewPancardDetailTable(data)
                                            : viewAadharDetailTable(data)}
                                    </div>
                                    {data.frontImageUrl && (
                                        <div className="col-4">
                                            <div className="card h-100 d-flex align-items-center justify-content-center ">
                                                <div
                                                    className="documentImgSection mt-0"
                                                    style={{
                                                        flexDirection: "column",
                                                        gap: "10px",
                                                    }}
                                                >
                                                    {data.frontImageUrl &&
                                                        data.frontImageUrl !==
                                                        "" && (
                                                            <div className="docImg">
                                                                {/* <img src='/assets/images/emptyDocument.png' alt='Front image' /> */}
                                                                <img
                                                                    onClick={() =>
                                                                        handleExpandImage(
                                                                            data.frontImageUrl
                                                                        )
                                                                    }
                                                                    src={
                                                                        data.frontImageUrl &&
                                                                            data.frontImageUrl !==
                                                                            ""
                                                                            ? data.frontImageUrl
                                                                            : "/assets/images/emptyDocument.png"
                                                                    }
                                                                    alt="Front image"
                                                                />
                                                            </div>
                                                        )}
                                                    {data.backImageUrl &&
                                                        data.documentType ===
                                                        "AadharCard" &&
                                                        data.backImageUrl !==
                                                        "" && (
                                                            <div className="docImg">
                                                                {/* <img src='/assets/images/emptyDocument.png' alt='Front image' /> */}
                                                                <img
                                                                    onClick={() =>
                                                                        handleExpandImage(
                                                                            data.backImageUrl
                                                                        )
                                                                    }
                                                                    src={
                                                                        data.backImageUrl &&
                                                                            data.backImageUrl !==
                                                                            ""
                                                                            ? data.backImageUrl
                                                                            : "/assets/images/emptyDocument.png"
                                                                    }
                                                                    alt="Back image"
                                                                />
                                                            </div>
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {data.rejectionNote && (
                                <div className="mt-3 ms-3 text-danger fs-5">
                                    Note :- {data.rejectionNote}
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            {showRejectionModal && documentRejectionModal()}
            {showExpandImageModal && expandableImageModal()}
        </div>
    );
};

export default PlayerDocumentTab;
