import { BANNER_PAGES_URL, FILE_UPLOAD_URL } from '../CONSTANT.js'
import { SYSTEM_ERROR } from '../Config/CONSTANT.js';
import axios from 'axios'
import Cookies from 'universal-cookie';
import { Banner_DeActive_PUT, Banner_Active_PUT, Delete_Banner_Record_DELETE } from "../../api/ApiConst.js"
const cook = new Cookies().get('loginData')


const headersdata = {
    'content-type': 'application/json',
    'Accept': 'application/json',
    'Authorization': cook != undefined ? cook.accessToken : ''
}

export const getBannerList = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(BANNER_PAGES_URL() + "list", data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getSnackLadderList axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const uploadBannerImage = (data) => {
    console.log(data);
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(FILE_UPLOAD_URL(), data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in uploadFile axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const updateBannerDetails = (id, data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(BANNER_PAGES_URL() + id, data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in updateBannerDetails axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const addNewBanner = (data) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(BANNER_PAGES_URL(), data, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in addNewBanner axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const getBannerDetails = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(BANNER_PAGES_URL() + id, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getBannerDetails axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const deleteBannerRecord = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .delete(Delete_Banner_Record_DELETE.replaceAll("{recordId}", id), { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in deleteBannerRecord axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const activateBannerRecord = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(Banner_Active_PUT.replaceAll("{recordId}", id), {}, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in activateSnakeLadderRecord axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const deactivateBannerRecord = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(Banner_DeActive_PUT.replaceAll("{recordId}", id), {}, { headers: headersdata })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in deactivateSnakeLadderRecord axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

