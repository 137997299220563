import React, { useEffect, useMemo, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import Select from "react-select";
import $ from "jquery";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

import { callGetApi } from "../api/ApiCaller";
import { All_Ad_Banner_List_POST, Banner_List_Keys, All_Deleted_Banner_List } from "../api/ApiConst.js";
import { getBannerList, uploadBannerImage, updateBannerDetails, addNewBanner, getBannerDetails, deleteBannerRecord, activateBannerRecord, deactivateBannerRecord } from "../Services/index.js";

import PageHeading from "./Common/PageHeading.jsx";
import CheckboxButton from "./Common/CheckboxButton.jsx";
import ConfirmationDialoge from "./Common/ConfirmationDialoge";
import PaginationDataTable from "./Common/PaginationDataTable.jsx";

const BannerMaster = () => {
    const [type, setType] = useState(null);
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, isAuth: true, title: "", subTitle: "", severity: "" });
    const [edit, setEdit] = useState(false);
    const [selectedImage, setSelectedImage] = useState("");
    const [assetsImageFormData, setAssetsImageFormData] = useState(null);
    const [id, setId] = useState(null);
    const [bodyFormFileData, setbodyFormFileData] = useState("");
    const [drawCount, setDrawCount] = useState(0);
    const [bannerOptions, setBannerOptions] = useState("");
    const [viewDeletedList, setViewDeletedList] = useState(false);

    const handleRefresh = () => setDrawCount((prev) => prev + 1);
    const handleToggleDeletedView = () => setViewDeletedList(!viewDeletedList);

    const handleSelectOptions = (e) => {
        setType(e);
    };

    useEffect(() => {
        callGetApi(Banner_List_Keys, {}, (response) => {
            const res = response.responsePacket
            const _res = res.map(positionOptions => (
                {
                    value: positionOptions,
                    label: positionOptions,
                }
            ))
            setBannerOptions(_res)
        },
            (error) => {
            }
        );
    }, [])

    const validateAddUpdateAssets = () => {
        let bannerType = type?.label;

        if (bannerType === null || bannerType === undefined) {
            toast.warn("Please Select Bannner Position ", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return false;
        }

        let inputTag = $("#formrow-inputZip").val();
        if (!(inputTag != "" || ($("#imageId").val() != "" && $("#assetsImageInput").val() != ""))) {
            toast.warn("Please select image first.", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return false;
        }
        return true;
    };

    const handleImageUpload = (e) => {
        var input = e.target;
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = function (ev) {
                setSelectedImage(ev.target.result);
                //$('#assetImageImg').attr('src', ev.target.result);
                setAssetsImageFormData(ev.target.result);
            };
            reader.readAsDataURL(input.files[0]);
        }
        let image = e.target.files[0];
        let bodyFormData = new FormData();
        bodyFormData.append("file", image);
        setbodyFormFileData(bodyFormData);
    };

    const handleSave = (e) => {
        e.preventDefault();
        if (validateAddUpdateAssets(e)) {
            let inputTag = $("#formrow-inputZip").val();
            if (inputTag != "") {
                uploadBannerImage(bodyFormFileData).then((res) => {
                    if (res.errorCode === 0) {
                        $("#assetsImageInput").val(res.responsePacket);
                        submitForm();
                    }
                });
            } else {
                submitForm();
            }
        }
    };

    const submitForm = () => {
        let request = {
            adBannerPosition: type?.label,
            bannerImageUrl: $("#assetsImageInput").val(),
        };

        if ($("#imageId").val() != "") {
            updateBannerDetails(id, request).then((res) => {
                if (res.errorCode === 0) {
                    toast("👏" + res.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    handleRefresh();
                    handleClearForm();
                } else if (res.message === "Unauthorised Access") {
                    handleForceLogout(res.message);
                }
            });
        } else {
            addNewBanner(request).then((res) => {
                if (res.errorCode === 0) {
                    handleSuccessToast(res?.message);
                    handleRefresh();
                    handleClearForm();
                } else if (res.message === "Unauthorised Access") {
                    handleForceLogout(res.message);
                }
            });
        }
    };

    const handleForceLogout = (data) => {
        toast("🙁" + data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setConfirmDialog({
            isOpen: true,
            isAuth: true,
            title: "You Are LoggedIn on Another Screen! to Countinue Logout and Login Again!!",
            onConfirm: () => logout(),
        });
    };

    const logout = () => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        const clearCookies = document.cookie.split(";").forEach((cookie) => (document.cookie = cookie.replace(/^ +/, "").replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`)));
        window.location.reload(1);
    };

    const handleClearForm = () => {
        setType(null);
        setSelectedImage("");
        $("#formrow-inputZip").val("");
        $("#assetsImageInput").val("");
        $("#configform")[0].reset();
    };

    const handleSuccessToast = (data) => {
        toast("👏" + data, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const handleEditRow = (id) => {
        setEdit(true);
        setId(id);
        getBannerDetails(id).then((res) => {
            if (res.errorCode === 0) {
                let details = res.responsePacket;
                let dict = {
                    value: details.adBannerPosition,
                    label: details.adBannerPosition,
                };
                setType(dict);

                $("#imageId").val(res.responsePacket.recordId);

                $("#assetsImageInput").val(details.bannerImageUrl);

                setSelectedImage(details.bannerImageUrl);
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            }
        });
    };

    const handleDeleteRow = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        deleteBannerRecord(id).then((res) => {
            if (res.errorCode === 0) {
                toast("👏" + res.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                handleRefresh();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            }
        });
    };

    const handleDeactivateRow = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        deactivateBannerRecord(id).then((res) => {
            if (res.errorCode === 0) {
                toast("👏" + res.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                handleRefresh();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            }
        });
    };

    const handleActivateRow = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        activateBannerRecord(id).then((res) => {
            if (res.errorCode === 0) {
                toast("👏" + res.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                handleRefresh();
            } else if (res.message === "Unauthorised Access") {
                handleForceLogout(res.message);
            }
        });
    };

    const columns = useMemo(
        () => [{
            name: "Image",
            selector: (row) => <img src={row.bannerImageUrl} style={{ width: "75px", height: "75px", objectFit: "scale-down" }} />,
            sortable: true,
            reorder: true,
        }, {
            name: "Banner Type",
            selector: (row) => row.adBannerPosition,
            sortable: true,
            right: true,
            reorder: true,
        }, {
            name: "Status",
            cell: (row) => (
                <>{row.active ? <span className="btn py-0 rounded-pill badge-success badge-success">Active</span> : <span className="btn py-0 rounded-pill badge-danger">InActive</span>}</>
            ),
            sortable: true,
            right: true,
            reorder: true,
        }, {
            name: "Actions",
            cell: (row) => (
                <div>
                    <div className="dropdown">
                        <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                            <KeyboardArrowDownOutlinedIcon />
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                            <li
                                onClick={() =>
                                    setConfirmDialog({
                                        isOpen: true,
                                        title: "Are Your Sure? Your want to delete this record",
                                        onConfirm: () => handleDeleteRow(row.recordId),
                                    })
                                }
                            >
                                <button className="dropdown-item" type="button">
                                    Delete
                                </button>
                            </li>
                            <li onClick={() => handleEditRow(row.recordId)}>
                                <button className="dropdown-item" type="button">
                                    Edit
                                </button>
                            </li>
                            {row.active ? (
                                <li
                                    onClick={() =>
                                        setConfirmDialog({
                                            isOpen: true,
                                            title: "Are Your Sure? Your want to Deactivate this player",
                                            onConfirm: () => handleDeactivateRow(row.recordId),
                                        })
                                    }
                                >
                                    <button className="dropdown-item" type="button">
                                        Deactivate
                                    </button>
                                </li>
                            ) : (
                                <li
                                    onClick={() =>
                                        setConfirmDialog({
                                            isOpen: true,
                                            title: "Are Your Sure? Your want to Activate this player",
                                            onConfirm: () => handleActivateRow(row.recordId),
                                        })
                                    }
                                >
                                    <button className="dropdown-item" type="button">
                                        Activate
                                    </button>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            ),
            sortable: true,
            right: true,
            reorder: true,
        },],
        [drawCount]
    );

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <PageHeading heading={"Banner Master"} />
                    <div className="row">
                        <div className="col-xl-5">
                            <div className="card">
                                <div className="card-body">
                                    <form id="configform">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="mb-3">
                                                    <label htmlFor="formrow-inputState" className="form-label">
                                                        Select Banner Position
                                                    </label>

                                                    <Select options={bannerOptions} value={type} onChange={(e) => handleSelectOptions(e)} />
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="mb-3">
                                                    <label htmlFor="formrow-inputZip" className="form-label">
                                                        Banner Image
                                                    </label>
                                                    <input type="hidden" id="imageId" />
                                                    <input type="hidden" id="assetsImageInput" />
                                                    <img
                                                        src={selectedImage != "" ? selectedImage : "https://th.bing.com/th/id/OIP.vx0QOVHGpMq7Furxtzu6KgHaHa?pid=ImgDet&amp;rs=1"}
                                                        id="assetImageImg"
                                                        style={{
                                                            height: "130px",
                                                            width: "100%",
                                                            objectFit: "contain",
                                                            border: "1px solid #7c7979",
                                                            padding: "10px",
                                                        }}
                                                    />
                                                    <input type="file" className="form-control" accept="image/*" id="formrow-inputZip" onChange={(e) => handleImageUpload(e)} />
                                                </div>
                                                {/* {edit ? <div><img src={selectedImage.ImageUrl} style={{height: '60px',paddingTop: '7px'}}/></div> : ""} */}
                                            </div>
                                        </div>
                                        <div>
                                            <button type="submit" className="btn btn-primary w-md" onClick={(e) => handleSave(e)}>
                                                Save
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-7">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Banner List</h4>
                                    <PaginationDataTable
                                        drawCount={drawCount}
                                        paginationUrl={viewDeletedList ? All_Deleted_Banner_List : All_Ad_Banner_List_POST}
                                        serverSidePagination={true}
                                        search={false}
                                        columns={columns}
                                    />
                                    <CheckboxButton title="Deleted List" handleClick={handleToggleDeletedView} additionalClass="deletedBtn ml-auto " />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
            <ConfirmationDialoge confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
        </>
    );
};

export default BannerMaster;
